import React from "react";
import styled from "styled-components";
import CustomInputText from "../../../UtilComponents/CustomInputText";
import CustomButton from "../../../UtilComponents/CustomButton";
import CustomDropdown from "../../../UtilComponents/CustomDropdown";
import { Checkbox } from "primereact/checkbox";

const StyledExecutableLinkForm = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .form-element {
    margin-right: 10px;
    display: flex;
    align-items: center;
    .link-type-dropdown {
      width: 120px;
    }
    label {
      margin-left: 5px;
    }
  }
`;

/**
 * Form used to add/remove/edit executable links for a tool or a pipeline branch.
 * Used in ToolPurposeForm and PipelineInfoForm.
 * @param {*} props
 * @returns
 */
const ExecutableLinkForm = (props) => {
  const {
    executableLinks,
    executableLinkObj,
    linkIndex,
    updateLinks,
    updateOneLink,
  } = props;

  const dropdownOptions = [
    { label: "HPC", value: "HPC" },
    { label: "CodeOcean", value: "CodeOcean" },
    { label: "CodeOceanVPC", value: "CodeOceanVPC" },
    { label: "WebApp", value: "WebApp" },
  ];

  const addExecutableLink = (e) => {
    e.preventDefault();
    let links = [...executableLinks];
    links.push({
      executableLink: "",
      linkType: "",
      default: links.length === 0 ? true : false,
    });
    updateLinks(links);
  };

  const removeExecutableLink = (e) => {
    e.preventDefault();
    let links = [...executableLinks];
    links.splice(linkIndex, 1);
    if (links.length === 1) {
      links[0].default = true;
    }
    updateLinks(links);
  };

  const updateExecutableLink = (field, value) => {
    let linkObj = { ...executableLinkObj };
    linkObj[field] = value;
    updateOneLink(linkIndex, linkObj);
  };

  const updateExecutableLinkCheckbox = (linkIndex, checked) => {
    let links = [...executableLinks];
    if (checked) {
      links = links.map((linkObj, i) => ({
        ...linkObj,
        default: linkIndex === i ? checked : false,
      }));
    }
    updateLinks(links);
  };

  return (
    <StyledExecutableLinkForm>
      <div className="form-element">
        <CustomInputText
          type="text"
          value={executableLinkObj.executableLink}
          onChange={(e) => {
            updateExecutableLink("executableLink", e.target.value);
          }}
          placeholder="Executable link"
          width="200px"
        />
      </div>
      <div className="form-element">
        <CustomDropdown
          className="link-type-dropdown"
          options={dropdownOptions}
          value={executableLinkObj.linkType}
          onChange={(e) => {
            updateExecutableLink("linkType", e.value);
          }}
          placeholder="Select link type"
        />
      </div>
      <div className="form-element">
        <Checkbox
          inputId="defaultCheckbox"
          checked={executableLinkObj.default}
          onChange={(e) => {
            updateExecutableLinkCheckbox(linkIndex, e.checked);
          }}
        ></Checkbox>
        <label htmlFor="defaultCheckbox">Default</label>
      </div>
      <div className="buttonGroup">
        {linkIndex === executableLinks.length - 1 && (
          <CustomButton
            className="p-button-primary p-button-rounded p-button-text"
            icon="pi pi-plus"
            disabled={
              executableLinks[linkIndex].executableLink.length === 0 ||
              executableLinks[linkIndex].linkType.length === 0
            }
            onClick={addExecutableLink}
            tooltip="Add another executable link."
          />
        )}
        <CustomButton
          className="p-button-danger p-button-rounded p-button-text"
          icon="pi pi-times"
          onClick={removeExecutableLink}
          tooltip="Remove this executable link"
        />
      </div>
    </StyledExecutableLinkForm>
  );
};

export default ExecutableLinkForm;
