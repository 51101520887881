import React, { useState, useContext, useEffect } from 'react';
import { PipelineBuilderContext } from '../../../../hooks/Contexts';
import { StyledBuilderForm } from '../BuildPipelineStyles';
import CustomDropdown from '../../../UtilComponents/CustomDropdown';
import CustomButton from '../../../UtilComponents/CustomButton';
import CustomMessages from '../../../UtilComponents/CustomMessages';
import { addLinkWarning, removeLinkWarning } from '../../../../utils/messages';
import { getNodeIdLabel } from '../../../../utils/helper';

const AddRemoveLink = () => {
    const { graphData, setGraphData } = useContext(PipelineBuilderContext);
    const [edge, setEdge] = useState({source: '', target: ''});
    const [targetOptions, setTargetOptions] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState(null);

    const connect = (e) => {
        e.preventDefault();
        let links = [...graphData.data.links];
        if(!links.find(link => link.source === edge.source && link.target === edge.target)){
            links.push(edge);
            setGraphData({
                ...graphData, 
                data: {...graphData.data, links: links}
            });
            setEdge({source: '', target: ''});
            setTargetOptions([]);
        }else{
            setMessage(addLinkWarning);
            setShowMessage(Math.random());
        }
    }

    const disconnect = (e) => {
        e.preventDefault();
        let index = graphData.data.links.findIndex(link => link.source === edge.source && link.target === edge.target)
        if(index > -1){
            let links = [...graphData.data.links];
            links.splice(index, 1);
            setGraphData(prev => ({
                ...prev,
                data: {...prev.data, links: links}
            }));
            setEdge({source: '', target: ''});
            setTargetOptions([]);
        }else{
            setMessage(removeLinkWarning);
            setShowMessage(Math.random());
        }
    }

    const resetForm = (e) => {
        e.preventDefault();
        setEdge({source: '', target: ''});
        setTargetOptions([]);
    }

    const disableConnect = () => {
        if(edge.source.length === 0 || edge.target.length === 0){
            return true;
        }
        let found = graphData.data.links.find(link => link.source === edge.source && link.target === edge.target);
        if(found){
            return true;
        }
        return false;
    }

    const disableDisconnect = () => {
        if(edge.source.length === 0 || edge.target.length === 0){
            return true;
        }
        let targetNodes = graphData.data.links
                .filter(link => link.source === edge.source)
                .map(link => link.target);
        if(!targetNodes.includes(edge.target)){
            return true;
        }
        return false;
    }

    useEffect(() => {
        if(edge.source.length > 0){
            let options = graphData.data.nodes
                .filter(node => node.name !== 'Genomics Data' && node.id !== edge.source)
                .map(node => ({label: `${node.name} - ${getNodeIdLabel(node.id)}`, value: node.id}))
            setTargetOptions(options);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edge.source]);

    return(
        <StyledBuilderForm>
            <CustomMessages trigger={showMessage} message={message} />
            <div className='formRow'>
                <span className='label'>Source Node: </span>
                <CustomDropdown 
                    className='input dropdown'
                    value={edge.source}
                    options={
                        graphData.data.nodes
                            .filter(node => node.name !== 'Genomics Data')
                            .map(node => ({label: `${node.name} - ${getNodeIdLabel(node.id)}`, value: node.id}))
                    }  
                    onChange={(e) => {setEdge({...edge, source: e.value})}} 
                    placeholder={'Select...'}
                    filter={true}
                />
            </div>
            <div className='formRow'>
                <span className='label'>Target Node: </span>
                <CustomDropdown 
                    className='input dropdown'
                    value={edge.target}
                    options={targetOptions}  
                    onChange={(e) => {setEdge({...edge, target: e.value})}} 
                    placeholder={'Select...'}
                    filter={true}
                    disabled={targetOptions.length === 0}
                />
            </div>
            <div className='buttonGroup'>
                <CustomButton 
                    label='Reset' 
                    onClick={resetForm} 
                    className='p-button-sm p-button-secondary left'
                />
                <CustomButton 
                    label='Connect'
                    onClick={connect} 
                    className='p-button-sm p-button-primary left'
                    disabled={disableConnect()}
                />
                <CustomButton 
                    label='Disconnect' 
                    onClick={disconnect} 
                    className='p-button-sm p-button-danger'
                    disabled={disableDisconnect()}
                />
            </div>
        </StyledBuilderForm>
    );
}

export default AddRemoveLink;