import React, {useEffect, useState} from 'react';
import axios from "axios";
import {GrUserExpert} from 'react-icons/gr';
import {HiOutlineMail} from 'react-icons/hi';
import colors from "../../styles/colors";
import {Checkbox} from 'primereact/checkbox';
import styled from "styled-components";
import {usePagination, useTable} from "react-table";
import CustomButton from "./CustomButton";
import {Title} from "../Views/IndividualTools/StyledToolPage";
import {useKeycloak} from "@react-keycloak/web";

const Text = styled.span`
  font-size: 12px;
  color: ${colors.gray_text};
`;


const StyledReactTable = styled.div`
  width: 100%;
  color: ${colors.gray_text};
  table{
    border-collapse: collapse;
    width: 100%;
  }
  thead {
    font-size: 12px;
  }

  tbody {
    font-size: 12px;
  }

  tbody tr:nth-child(even) {

  }

  th[class='header'] {
    vertical-align: center;
    .headerContent {
      display: flex;
      align-items: center;
      height: 20px;
      margin: 0.25rem 1.0rem;
    }
  }

  th {
    text-align: center;
    letter-spacing: 0.5px;
    width: 200px;
  }

  tbody td {
    padding: 0.25rem 1.0rem;
    text-align: left;
    @media only screen and (max-width: 900px) {
      // word-break: break-word;
    }
  }
`;

const checkboxStyle= () =>{
    return(
        {
            margin: '5px 5px 0px 0px',
            fontSize: '14px'
        }
    )
}

const CustomTable = (props) => {
    const { data, columns, style, pageSize } = props;
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        setPageSize,
    } = useTable(
        {
            columns: columns,
            data: data,
        },
        usePagination
    );

    useEffect(() => {
        setPageSize(pageSize ? pageSize : 5);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <StyledReactTable style={style}>
            <table {...getTableProps()}>
                <thead>
                {
                    headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map(column => (
                                    <th className='header' {...column.getHeaderProps()}>
                                        <div className='headerContent'>
                                            { column.render('Header') }
                                        </div>
                                    </th>
                                ))
                            }
                        </tr>
                    ))
                }
                </thead>
                <tbody {...getTableBodyProps()}>
                {
                    page.map(row => {
                        prepareRow(row);
                        return(
                            <tr {...row.getRowProps()}>
                                {
                                    row.cells.map(cell => {
                                        return(
                                            <td key={Math.random()} {...cell.getCellProps}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>
        </StyledReactTable>
    );
}

const ExpertList = (props) => {
    const {id, name, type, user, userName} = props;
    const { keycloak } = useKeycloak();
    const [experts, setExperts] = useState({data: {}, ready: false});
    const [level, setLevel] = useState("");
    const [checked, setChecked] = useState(false);

    const columns = [
        {
            Header: 'User',
            accessor: 'name',
            Cell: (item) => (
                <span>
                <GrUserExpert style={{ color: colors.datatypeNode, fontSize: '14px', fontWeight: 'bold', maxWidth: '80px'}}/> {item.cell.row.original.name}
            </span>
            )
        },
        {
            Header: 'Contact',
            accessor: 'email',
            Cell: (item) => {
                return(
                <a href={`mailto:${item.cell.value}`}>
                    <CustomButton
                        className="p-button-text p-button-sm p-button-info"
                        label={<HiOutlineMail style ={{fontSize: '16px'}}/>}
                        tooltip={"Contact the expert user"}
                    />
                </a>
            )
            }

        }
    ]

    useEffect(() => {
        const getData = async () => {
            let result = [];
            try {
                const res = await axios.get(`/api/data/expert/experts/${id}`, { params: { email: keycloak.idTokenParsed.email } });
                result = {data: res.data, ready: true};
                let submitter = {};
                if (type === "tool") {
                    submitter= (await axios.get(`/api/data/tool/submittedBy?toolId=${id}`)).data; // if not available returns null
                } else {
                    submitter= (await axios.get(`/api/data/pipeline/${id}/nofilter`)).data.pipeline.submittedBy;
                }
                if (res && res.data) result = (res.data.sort(( a, b) => new Date(b.date) - new Date(a.date)));
                if (submitter.email && submitter.email !== "admin@cobe.ca") result= [{ name: "Submitter", email: submitter.email}, ...result];
            }finally{
                return(result);
            }
        }

        getData().then(res => setExperts({ready: true, data:res}));
    }, [id, level, keycloak.idTokenParsed.email, type]);

    useEffect(()=> {
        const getExpertStatus = async ()=> {
            let result = [];
            try {
                const expert = await axios.get(`/api/data/expert/is-expert/${id}`, { params: { email: user ? user : null }});
                result = expert.data;
            }finally{
                return(result);
            }
        }
        getExpertStatus().then(res => {
            setLevel(res.level);
            if(level === "expert") setChecked(true);
        });
    }, [user, level, id]);

    const updateLevel = () => {
        try {
            axios.post(`/api/data/expert/reverse-expert/${id}`, { params: { name: userName, email: user ? user : null, type:type }});
            setLevel(level === "expert"? "" : "expert");
            setChecked(!checked);
        } catch (err) {}
    };

    return(
        <React.Fragment>
            <Title>Expert users of {name}:</Title>
            {
                experts.ready && experts.data.length ?
                    <CustomTable
                        columns={columns}
                        data={experts.data}
                        pageSize={5}
                        showSearch={false}
                        disablePagination={true}
                    /> : ''
            }
            <div>
                <Text>How do you assess your expertise level with {name}?</Text>
                <Text>
                    <span style={{paddingLeft: '10px'}}>
                        <Checkbox
                            style={checkboxStyle()}
                            inputId="expert"
                            value="expert"
                            tooltip={checked && level==="expert"? "Remove your name from the list of experts.":"Add your name to the list of experts."}
                            onChange={(e) => updateLevel()}
                            checked={level=== 'expert'}>
                        </Checkbox>
                        <label>Expert</label>
                    </span>
                </Text>
            </div>
        </React.Fragment>
    );
}

export default ExpertList;
