const styles = {
    origin: {
        type: "origin",
        label: "Origin",
        shape: "circle",
        colors: {
            default: "#666666",
            hovered: "#d1d1d1",
            clicked: "#d1d1d1"
        }
    },
    datatype: {
        type: "datatype",
        label: "Data Type",
        shape: "circle",
        colors: {
            default: "#0f2080",
            hovered: "#cfd2e5",
            clicked: "#cfd2e5"
        }
    },
    executableHPC: {
        type: "executable-hpc",
        shape: "circle",
        label: "Executable Pipeline",
        colors: {
            default: "#a95aa1",
            hovered: "#ba7ab3",
            clicked: "#ba7ab3"
        }
    },
    servicePipeline: {
        type: "service-pipeline",
        shape: "circle",
        label: "Service Pipeline",
        colors: {
            default: "#f5793a",
            hovered: "#f79361",
            clicked: "#f79361"
        }
    },
    executableCodeOcean: {
        type: "executable-codeocean",
        shape: "circle",
        label: "Executable Analysis",
        colors: {
            default: "#3c9af5",
            hovered: "#9dccfa",
            clicked: "#9dccfa"
        }
    },
    downstreamNode: {
        type: "downstream-node",
        shape: "circle",
        label: "Downstream Analysis",
        colors: {
            default: "#ff6666",
            hovered: "#ff6666",
            clicked: "#ff6666"
        }
    },
    database: {
        type: "database",
        shape: "rect",
        label: "Database",
        colors: {
            default: "#666666",
            hovered: "#d1d1d1",
            clicked: "#d1d1d1"
        }
    },
    website: {
        type: "website",
        shape: "circle",
        label: "Webapp",
        colors: {
            default: "#3c9af5",
            hovered: "#9dccfa",
            clicked: "#9dccfa"
        }
    },
    default: {
        type: "default",
        shape: "circle",
        label: "Tool",
        colors: {
            default: "#cccccc",
            hovered: "#d6d6d6",
            clicked: "#d6d6d6"
        }
    }
};

export default styles;
