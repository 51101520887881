import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../UtilComponents/Layout";
import CustomButton from "../../UtilComponents/CustomButton";
import Loading from "../../UtilComponents/Loading";
import ChipGroup from "../../UtilComponents/ChipGroup";
import PropTypes from "prop-types";
import axios from "axios";
import Disclaimer from "../../UtilComponents/Disclaimer";
import LikeButton from "../../UtilComponents/LikeButton";
import ShareButton from "../../UtilComponents/ShareButton";
import CustomTable from "../../UtilComponents/CustomTable";
import { useKeycloak } from "@react-keycloak/web";
import ExpertList from "../../UtilComponents/ExpertList";
import {
  Title,
  Text,
  Section,
  StyledLink,
  Description,
  Content,
  Container,
  Header,
} from "./StyledToolPage";

const value = (data) => {
  if (data && data !== []) return data;
  else return "N/A";
};

const columns = [
  {
    Header: "Tool",
    accessor: "name",
    Cell: (item) => (
      <StyledLink href={`/tools/${String(item.cell.row.original._id)}`}>
        {item.cell.row.original.name}
      </StyledLink>
    ),
  },
  {
    Header: "Purposes",
    accessor: "purposes",
    Cell: (item) => (
      <ul>
        {item.cell.row.original.purposes.map((purpose, i) => (
          <li key={i}>{purpose.text}</li>
        ))}
      </ul>
    ),
  },
];

const attributes = (title, text) => {
  return (
    <>
      <Title>{title}: </Title>
      <Text>{value(text)}</Text>
      <br />
    </>
  );
};

const IndividualTools = (props) => {
  const {
    match: { params },
  } = props;
  const { keycloak } = useKeycloak();
  const [userTools, setUserTools] = useState([]);
  const [user, setUser] = useState(undefined);
  const [ready, setReady] = useState(false);
  const [tool, setTool] = useState({
    data: {},
    ready: false,
  });
  const history = useHistory();
  const initialize = async () => {
    if (keycloak.authenticated) {
      const res = await axios.post("/api/user/profile", {
        email: keycloak.idTokenParsed.email,
      });
      if (res) setUserTools(res.data.tools);
      setUser({ email: keycloak.idTokenParsed.email });
    }
    setReady(true);
  };

  useEffect(() => {
    const getTool = async () => {
      const res = await axios.get("/api/data/tool", {
        params: { id: params.id, getAssociatedTools: true },
      });
      setTool({ data: res.data, ready: true });
    };
    getTool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
    return () => {
      if (history.action === "POP" && history.location.pathname === "/") {
        console.log("history");
        history.replace({
          pathname: "/",
          state: {
            search: props.location.state.search,
          },
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, keycloak.authenticated]);

  const {
    name,
    versions,
    purposes,
    keywords,
    link,
    doi,
    input,
    output,
    score,
    type,
  } = tool.data;
  return tool.ready && ready ? (
    <Layout>
      <Container>
        <Disclaimer />
        <Header>
          <span className="title">{name}</span>
          {userTools && userTools.map((x) => x.name).includes(name) ? (
            <CustomButton
              className="left-button p-button-info p-button-rounded p-button-text"
              icon="pi pi-pencil"
              tooltip="Edit this tool"
              disabled={false}
              onClick={(e) => history.push(`/edit/tool/${params.id}`)}
            />
          ) : (
            ""
          )}
          <LikeButton
            id={params.id}
            type="tool"
            user={user}
            disabled={keycloak.authenticated ? false : true}
          />
          <ShareButton type="tool" name={name} id={params.id} />
          {typeof tool.data.executableLink !== "undefined" && (
            <React.Fragment>
              <span>
                <Title>Usage: </Title>{" "}
                <StyledLink href={tool.data.executableLink} target="_blank">
                  {tool.data.executableLink}
                </StyledLink>
              </span>
              <br />
            </React.Fragment>
          )}
        </Header>
        <Content>
          <Section>
            <Description>
              {purposes.map((purpose, i) => (
                <div className="tool-purpose" key={i}>
                  <span className="purpose-text">{purpose.text}</span>
                  <ChipGroup
                    executableLinks={purpose.executableLinks}
                    disabled={false}
                    executableType="tool usage sample"
                    executableInfo={{
                      name: name,
                      id: tool.data._id,
                      type: "tool",
                    }}
                  />
                </div>
              ))}
            </Description>
            <Description>
              {attributes("Versions", value(versions.join(", ")))}
              {attributes("Score", value(score))}
              {attributes("Keywords", value(keywords.join(", ")))}
              {attributes("Type", value(keywords.join(", ")))}
              {attributes(
                "Link",
                link ? (
                  <StyledLink href={link} target="_blank">
                    {link}
                  </StyledLink>
                ) : (
                  "N/A"
                )
              )}
              {attributes(
                "DOI",
                doi ? (
                  <StyledLink href={doi} target="_blank">
                    {doi}
                  </StyledLink>
                ) : (
                  "N/A"
                )
              )}
              {attributes("Input", value(input.join(", ")))}
              {attributes("Output", value(output.join(", ")))}
            </Description>
            {keycloak.authenticated ? (
              <Description>
                <ExpertList
                  id={params.id}
                  name={name}
                  type={"tool"}
                  user={keycloak.idTokenParsed.email}
                  userName={
                    keycloak.idTokenParsed.given_name +
                    " " +
                    keycloak.idTokenParsed.family_name
                  }
                />
              </Description>
            ) : (
              ""
            )}
          </Section>
          <Section>
            <Description>
              <h4>You might also be interested in:</h4>
              {purposes.map((purpose, i) => (
                <div className="tool-purpose" key={i}>
                  <span className="purpose-text">{purpose.text}</span>
                  <ChipGroup
                    executableLinks={purpose.executableLinks}
                    disabled={false}
                    executableType="tool usage sample"
                    executableInfo={{
                      name: name,
                      id: tool.data._id,
                      type: "tool",
                    }}
                  />
                </div>
              ))}
              <CustomTable
                columns={columns}
                data={tool.data.associatedTools}
                datatype=""
                showSearch={true}
                pageSize={5}
              />
            </Description>
          </Section>
        </Content>
      </Container>
    </Layout>
  ) : (
    <Loading top="100px" />
  );
};

IndividualTools.propTypes = {
  /**
   * IndividualTools' param id
   */
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default IndividualTools;
