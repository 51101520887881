import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { StyledBuilderForm } from '../BuildPipelineStyles';
import RequiredField from '../../../UtilComponents/RequiredField';
import CustomButton from '../../../UtilComponents/CustomButton';
import CustomInputText from '../../../UtilComponents/CustomInputText';
import CustomCreatableMultiSelect from '../../../UtilComponents/CustomCreatableMultiSelect';
import { Tooltip } from 'primereact/tooltip';
import UserForm from './UserForm';
import ExecutableLinkForm from './ExecutableLinkForm';
import { invalidExecutableLink } from '../../../../utils/helper';

const PipelineInfoForm = (props) => {
    
    const { pipeline, setPipeline, initializePipeline, isEdit, initialized } = props;
    const [datatypeOptions, setDatatypeOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const res = await axios.get('/api/view/pipeline-builder/initialize-form');
            setDatatypeOptions(res.data.datatypes);
            setUserOptions(res.data.toolUsers);
        }
        getData();
    }, []);

    const disableInitialize = () => {
        return pipeline.name.length === 0 || 
        pipeline.datatype.length === 0 || 
        invalidExecutableLink(pipeline.main.executableLinks) || 
        pipeline.link.length === 0 ||
        pipeline.user.name.length === 0;
    };

    const disableAddUser = () => {
        const last = pipeline.user[pipeline.user.length - 1];
        return last.name.length === 0;
    };

    const addExecutableLink = (e) => {
        e.preventDefault();
        let mainLinks = [...pipeline.main.executableLinks];
        mainLinks.push({
            executableLink: '',
            linkType: '',
            default: mainLinks.length === 0 ? true : false
        });
        updateLinks(mainLinks);
    };

    const updateLinks = (links) => {
        setPipeline(prev => ({
            ...prev,
            main: {
                ...prev.main,
                executableLinks: links
            }
        }));
    };

    const updateOneLink = (linkIndex, linkObj) => {
        let mainLinks = [...pipeline.main.executableLinks];
        mainLinks[linkIndex] = linkObj;
        updateLinks(mainLinks);
    };
     
    return(
        <StyledBuilderForm>
            <div className='formRow'>
                <span className='label'>Name<RequiredField />: </span>
                <CustomInputText
                    id='pipeline_name' 
                    className='input'
                    type='text'
                    value={pipeline.name}
                    onChange={(e) => {setPipeline({...pipeline, name: e.target.value})}}
                    width='80%'
                    placeholder='ex. ATAC-seq_ML'
                    tooltip='Recommended format for the pipeline name: "Processed Data Type" or "Major Tool" separated by "_" followed by "Lab Initial".'
                />
            </div>
            {
                isEdit || initialized ?
                <div className='formRow'>
                    <span className='label'>Processed Data Type<RequiredField />: </span>
                    <span className='value'>{pipeline.datatype.join(', ')}</span>
                </div>
                :
                <div className='formRow'>
                    <span className='label'>Processed Data Type<RequiredField />: </span>
                    <CustomCreatableMultiSelect 
                        className='input'
                        options={datatypeOptions}
                        value={pipeline.datatype}
                        onChange={(newValue) => {setPipeline({...pipeline, datatype: newValue})}}
                        placeholder='Select or enter data type(s)'
                    />
                </div>
            }
            <div className='formRow'>
                <span className='label'>Version: </span>
                <CustomInputText 
                    className='input'
                    type='text'
                    value={pipeline.version ? pipeline.version : ''}
                    onChange={(e) => {setPipeline({...pipeline, version: e.target.value})}}
                    width='80%'
                />
            </div>
            <div className='formRow'>
                <span className='label'>Link to pipeline info<RequiredField />: </span>
                <CustomInputText 
                    className='input'
                    type='text'
                    value={pipeline.link ? pipeline.link : ''}
                    onChange={(e) => {setPipeline({...pipeline, link: e.target.value})}}
                    width='80%'
                />
            </div>
            <div className='formRow'>
                <span className='label label-with-icon'>
                    Link to executable(s)<RequiredField />: 
                    <Tooltip target='.custom-target-icon' />
                    <i 
                        className='pi pi-question-circle custom-target-icon' 
                        data-pr-tooltip="Accepted links: GitHub page or CodeOcean capsule"
                    ></i>
                </span>
                {
                    pipeline.main.executableLinks.length === 0 ?
                    <div className='input'>
                        <CustomButton 
                            className='p-button-primary p-button-rounded p-button-text' 
                            label='Add executable link'
                            onClick={addExecutableLink}
                            tooltip='Add executable link'
                        />
                    </div>
                    :
                    <div className='input'>
                    {
                        pipeline.main.executableLinks.map((linkObj, linkIndex) => (
                            <ExecutableLinkForm 
                                key={linkIndex}
                                executableLinks={pipeline.main.executableLinks}
                                executableLinkObj={linkObj}
                                linkIndex={linkIndex}
                                updateLinks={updateLinks}
                                updateOneLink={updateOneLink}
                            />
                        ))
                    }
                    </div>
                }
            </div>
            {
                isEdit ?
                pipeline.user.map((item, i) => (
                    <UserForm 
                        key={i}
                        user={item} 
                        index={i}
                        userOptions={userOptions} 
                        pipelineUsers={pipeline.user}
                        setPipeline={setPipeline} 
                        isEdit={isEdit}
                    />
                ))
                :
                <UserForm 
                    user={pipeline.user} 
                    userOptions={userOptions} 
                    setPipeline={setPipeline} 
                />
            }
            {
                isEdit &&
                <div className='buttonGroup'>
                    <CustomButton 
                        label='Remove User' 
                        onClick={(e) => {
                            e.preventDefault();
                            let users = JSON.parse(JSON.stringify(pipeline.user));
                            users.pop();
                            setPipeline({
                                ...pipeline, 
                                user: users,
                                changeGraph: false
                            });
                        }} 
                        disabled={pipeline.user.length < 2}
                        className='left p-button-sm p-button-danger'
                    />
                    <CustomButton 
                        label='Add User' 
                        onClick={(e) => {
                            e.preventDefault();
                            let users = JSON.parse(JSON.stringify(pipeline.user));
                            users.push({name: '', link: ''});
                            setPipeline({
                                ...pipeline, 
                                user: users,
                                changeGraph: false
                            });
                        }} 
                        disabled={disableAddUser()}
                        className='p-button-sm p-button-primary'
                    />
                </div>
            }
            {
                (!initialized && !isEdit) &&
                <div className='buttonGroup'>
                    <CustomButton 
                        label='Initialize Pipeline' 
                        onClick={(e) => {
                            e.preventDefault();
                            initializePipeline(pipeline);
                        }} 
                        className='p-button-sm p-button-primary'
                        disabled={disableInitialize()}
                    />
                </div>
            }
        </StyledBuilderForm>
    );
}

export default PipelineInfoForm;