import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomButton from '../../../UtilComponents/CustomButton';
import CustomMessages from '../../../UtilComponents/CustomMessages';
import { editSuccess, editError } from '../../../../utils/messages';
import { PipelineBuilderContext } from '../../../../hooks/Contexts';
import PipelineBuilder from '../../BuildPipeline/SubComponents/PipelineBuilder';
import { Container } from '../EditStyle';
import Loading from '../../../UtilComponents/Loading';
import { useKeycloak } from "@react-keycloak/web";

const EditPipeline = (props) => {
    const { data } = props;
    const { keycloak } = useKeycloak();
    const [ready, setReady] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState(null);
    const [pipeline, setPipeline] = useState();
    const [graphData, setGraphData] = useState({
        ready: false
    });
    
    useEffect(() => {
        if(typeof data.pipeline !== 'undefined'){
            setPipeline({
                ...data.pipeline
            });
            setGraphData({
                data: data.graph,
                tools: data.graph.tools,
                filtered: false,
                ready: true
            });
            setReady(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const saveDraft = async () => {
            await axios.post('/api/view/pipeline-builder/save-draft', {
                pipeline: pipeline,
                graph: {
                    nodes: graphData.data.nodes,
                    links: graphData.data.links,
                    tools: graphData.tools
                },
                email: keycloak.idTokenParsed.email
            });
        }
        if(graphData.ready){
            saveDraft();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphData, pipeline]);

    const onSubmit = async (e) => {
        e.preventDefault();
        const res = await axios.post('/api/data/pipeline/edit', {
            pipeline: {
                ...pipeline, graphLinks: graphData.data.links
            },
            email: keycloak.idTokenParsed.email
        });
        if(res.status === 200){
            setMessage(editSuccess);
        }else{
            setMessage(editError);
        }
        setShowMessage(Math.random());
    }

    return(
        <Container>
            <CustomMessages trigger={showMessage} message={message} />
            {
                ready ?
                <React.Fragment>
                    <div className='header'>
                        <div className='title'>Edit Pipeline: {data.pipeline.name}</div>
                        <CustomButton 
                            label='Submit Changes' 
                            onClick={onSubmit} 
                            className='p-button-sm p-button-success'
                            disabled={false}
                        />
                    </div>
                    <PipelineBuilderContext.Provider value={{ pipeline, setPipeline, graphData, setGraphData, isEdit: true }}>
                        <PipelineBuilder />
                    </PipelineBuilderContext.Provider>
                </React.Fragment>
                :
                <Loading top={'200px'} />
            }
        </Container>
    );
}

export default EditPipeline;