import React from 'react';
import { SubContainer } from '../TutorialStyles';
import YoutubeEmbed from './YoutubeEmbed';
import HyperLink from '../../../UtilComponents/HyperLink';

const PipelineExamples = () => {

    const videos = [
        {name: 'scMultiome', pipeline: '618285d473c8c4003964393b', embedId: 'Goka2A-pdvk'},
        {name: 'ATAC seq Mathieu Lupien Lab', pipeline: '60a4336aaf7a3251ac7e152d', embedId: '5eBu5kVsu-I'},
        {name: 'scATAC seq', pipeline: '60a4336aaf7a3251ac7e1772', embedId: 'j1wMjVK4YhM'},
        {name: 'scRNA seq', pipeline: '60a4336aaf7a3251ac7e1790', embedId: 'NTOPIF8pjU0'},
        {name: 'RNA-seq Benjamin Haibe-Kains Lab', pipeline: '60a4336aaf7a3251ac7e15dd', embedId: 'yHtbZ-6j8J0'},
    ]

    const renderVideo = () => {
        videos.sort((a, b) => a.name.localeCompare(b.name));
        return(
            videos.map((video, i) => (
                <React.Fragment key={i}>
                    <h4>How to run the <HyperLink link={`/pipeline/${video.pipeline}`} text={`${video.name} pipeline`} />:</h4>
                    <div className='section video'>
                        <YoutubeEmbed embedId={video.embedId} />
                    </div>
                </React.Fragment>
            ))
        );
    }

    return(
        <SubContainer>
            <h1>Pipeline Examples</h1>
            <h3>This page provides a series of videos outlining example of how to execute CoBE pipelines.</h3>
            {
                renderVideo()
            }
        </SubContainer>
    );
}

export default PipelineExamples;