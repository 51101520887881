import React from 'react';
import { SubContainer } from '../TutorialStyles';
import YoutubeEmbed from './YoutubeEmbed';

const Overview = () => {
    return(
        <SubContainer>
            <h1>About CoBE</h1>
            <div className='section'>
                Welcome to CoBE: The <b>Co</b>mputational & <b>B</b>ench Scientist <b>E</b>cosystem. CoBE is a web portal recommendation engine allowing scientists to rapidly find or contribute software tools & bioinformatic pipelines that address the most pressing analytical needs from omics data. 
            </div>
            <h3>For Bench Scientists: CoBE recommends vetted software tools & bioinformatic pipelines</h3>
            <div className='section'>
                Bench scientists need assistance finding the best software or bioinformatics pipeline to analyse their data. Making sense of the sheer number of software tools & bioinformatics pipelines is a daunting task for non-experts. You may not be aware of a particularly useful software pipeline, or may be unsure of which software is best suited for your research. CoBE automatically identifies analysis pipelines from local experts to address your most pressing analytical needs. 
            </div>
            <div className='section'>
                <img className='embed-img' alt='flowchart' src='/images/tutorial/COBE-data-flowchart.jpeg' />
            </div>
            <div className='section'>
                Use the search function to ask a specific question such as “How do I find chromatin loops?”, or use the step-by-step wizard to let CoBE know your desired data-type input and desired output results, and CoBE will provide a list of software tools and pipelines to fit your data processing needs. You can also filter your results to see: 
                <ul>
                    <li>Descriptions and purposes for each software pipeline and tool</li>
                    <li>Which pipelines are most popular</li>
                    <li>Pipelines that are supported by commercial and/or core services</li>
                    <li>Local computational experts for your selected pipeline</li>
                </ul>
            </div>
            <h3>For Computational Scientists: Increase the visibility and usage of your software tools</h3>
            <div className='section'>
                Computational biologists constantly design and improve software tools, with the intent to attract the largest possible user groups. This implies making software tools readily available and known to the right audiences. It also implies designing new software tools that address unmet needs from those audiences. As a computational biologist you can log in to CoBE’s Pipeline Builder interface to add your software tools and bioinformatic pipelines to the CoBE library. Adding your own pipelines serves to:
                <ul>
                    <li>Make CoBE users aware that a new software tool or pipeline may suit their needs</li>
                    <li>Facilitate new collaborations with other researchers</li>
                    <li>Create convenient, interactive visual representations of your software pipelines</li>
                </ul>
            </div>
            <div className='section video'>
                <YoutubeEmbed embedId='4qDZya4qT28' />
            </div>
        </SubContainer>
    );
}

export default Overview;