import React, { useState, useEffect} from 'react';
import axios from 'axios';
import styled from "styled-components";
import {Button} from "primereact/button";
import { useKeycloak } from "@react-keycloak/web";

const LinkText = styled.span`
    i {
        font-size: 12px;
    }
`;

const formatExecutableLinks = (executableLinks) => {
    let str = "";
    if (executableLinks === null)
        return "N/A";
    for (const link of executableLinks)
        str += "\n" + JSON.stringify(link);
    return str;
}

const formatNode = (nodes) => {
    let str = "\n___";
    for (const node of nodes) {
        for (const field of Object.keys(node)) {
            if (field === "purposes") {
                str += "\n%purposes:@";
                for (const purpose of node[field])
                    str += "\n@{text:" + JSON.stringify(purpose["text"])+
                        formatExecutableLinks(purpose["executableLinks"]) + "}";
            } else {
                str += "\n%" + field + ":" + JSON.stringify(node[field]);
            }
        }
        str += "\n___"
    }
    return  str;
}

const isNestedObject = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
        if (key === "nodes" || key === "executableLinks" || (value.length >1 && typeof value !== "string")) return true;
    }
    return false;
}

const formatObject = (blockObj, tag) => {
    let res = "";
    for (const [key, value] of Object.entries(blockObj)) {
        if (value) {
            if (key === "nodes") res += "\n#nodes:%" +formatNode(value); //nodes on main branch
            else if ( key === "executableLinks") res += "\n#executableLinks:" + formatExecutableLinks(); // executables on main branch
            else {
                if (typeof value=== 'object' && isNestedObject(value)) {// value is a list of objects
                    for (const item in value) {
                        if (item === "nodes") { res += "\n#nodes:%" +formatNode(value[item]) } // nodes on subbranch or purposes
                        else if (item === "executableLinks") res += "\n#executableLinks:" + value[item] !== null ?
                            formatExecutableLinks(value[item]) : "N/A"; // executables on subbranch or purposes
                        else if(typeof value[item])res += "\n#%" + item + ":"+ JSON.stringify(value[item]);
                    }
                } else {
                    res += !isNaN(key) ? "\n#" + String(Number(key) + 1) + ": " + JSON.stringify(value) : "\n#" + key + ": " + JSON.stringify(value);
                }
            }
        }
        else {
            res += "\n#" + key + ": N/A"; // null values
        }
    }
    res = `<<${tag}>>`+ res +`\n>>${tag}<<`
    return res;
}

const formatMarkdown= (pipeline) => {
    let content = "";
    const block = {
        pipelineInfo: "Pipeline",
        main: "Main",
        subBranches: "SubBranches",
        purposes: "DownstreamAnalysis",
        links: "Link",

    }
    for (let key of Object.keys(block)) {
        content += formatObject(pipeline[`${key}`], block[key]) + "\n\n\n";
    }
    return content;
}

const getExecutableLinks = (executableLinks) => {
    const list = [];
    executableLinks.map(x => {
        return list.push({ executableLink: x.executableLink, linkType: x.linkType, default: x.default})
    });
    return list;
}

const getNodeTool = (node, tools, graph) => {
    if (["origin", "datatype"].includes(node.type)) {
        node["name"] += " (" + node["id"].substring(5,9) + ")"; // take 4 digits of node_id to distinguish different usage of same tools
        delete node["_id"];
        delete node["id"];
        return (node);
    } else {
        let tool = tools.filter(tool => tool._id === (graph.nodes.filter(x => x.id === node.id)[0].toolId))[0];
        const nodePurposes = [];
        tool.purposes.map( purpose => nodePurposes.push({
            text : purpose.text ,
            executableLinks: purpose.executableLinks ? getExecutableLinks(purpose.executableLinks): null
        }))
        tool.purposes = nodePurposes;
        const name = tool.name + " (" + node.id.substring(5,9) + ")"; // take 4 digits of node_id to distinguish different usage of same tools
        const { doi, keywords, link, purposes, score, type} = tool;
        const { input, output, index, versions} = node;
        return { name, versions, keywords, input, output, link, doi, type, score, index, purposes};
    }
}

const getPipelineInfo = (pipeline) => {
    const userList = [];
    const {name, datatype, version, link, user} = pipeline;
    user.map(item => userList.push({name: item.name, link:item.link}));
    return {
        name: name,
        datatype: datatype,
        version: version,
        link: link,
        user: userList
    };
}

const getGraphLinks = (graph) => {
    const {links, nodes} = graph;
    const linkList = [];
    const nodeIndex = [];
    links.map(x => {
        const sourceId= nodes.filter(node => node.id === x.source)[0].id;
        const targetId= nodes.filter(node => node.id === x.target)[0].id;
        if (!nodeIndex.includes(sourceId)) nodeIndex.push(sourceId);
        if (!nodeIndex.includes(targetId)) nodeIndex.push(targetId);
        linkList.push({
            source: nodes.filter(node => node.id === x.source)[0].name + " ("
                + x.source.substring(5,9) + ")",
            target: nodes.filter(node => node.id === x.target)[0].name + " ("
                + x.target.substring(5,9) + ")"
        }) // take 4 digits of node_id to distinguish different usage of same tools
        return linkList;
    });
    return linkList;
}

const getBranches = (branches, tools, graph) => {
    const branchList = [];
    branches.forEach( item => branchList.push({
            text: item.text,
            executableLinks: item.executableLink ? getExecutableLinks(item.executableLink) : null,
            nodes: item.nodes.map(node => getNodeTool(node, tools, graph))
        }
    ));
    return branchList;
}

const getMain = (main, tools, graph) => {
    return {
        executableLinks: main.executableLink ? getExecutableLinks(main.executableLink) : null,
        nodes: main.nodes.map(node => getNodeTool(node, tools, graph))
    };
}

const DownloadPipeline = (props) => {
    const { selectedPipeline, disabled } = props;
    const { keycloak } = useKeycloak();
    const [isSubmitter, setIsSubmitter] = useState(false);
    const [userPipelines, setUserPipelines] = useState([]);
    const id = selectedPipeline._id;

    let result = {
        pipelineInfo: null,
        main: {text: null, executableLinks: [], nodes:[]},
        subBranches: [],
        purposes: [],
        links: [],
    };

    const getData = async () => {
        const res = await axios.get(`/api/data/pipeline/${id}/nofilter`); // fetch pipeline info from db
        const { graph, pipeline, tools} = res.data ;
        const { main, subBranches, purposes} = pipeline;

        result.pipelineInfo = getPipelineInfo(pipeline); // get pipeline's general attributes
        result.links = getGraphLinks(graph); // get pipeline's link pairs
        result.purposes = getBranches(purposes, tools, graph); // get purposes and corresponded nodes
        result.subBranches = getBranches(subBranches, tools, graph); // get subBranches nodes
        result.main = getMain(main, tools, graph); // get main pipeline nodes
        return result;
    };

    const download = (content, fileName, contentType) => {
        const a = document.createElement("a");
        const file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }

    const onDownload = (pipeline) => {
        getData().then(res => download(formatMarkdown(res), `${pipeline.name}.rmd`, "text/plain"));
    }

    useEffect(() => {
        const getUserPipelines = async () => {
            if(keycloak.authenticated) {
                const res = await axios.post('/api/user/profile', {
                    email: keycloak.idTokenParsed.email
                });
                setUserPipelines(res.data.pipelines);
                setIsSubmitter(userPipelines.filter( item => String(item._id)=== String(id)).length>0);
            }
        }
        getUserPipelines();

    }, []);

    return(
        !disabled && isSubmitter && keycloak.authenticated ?
            <Button
                className="p-button-sm"
                label={``}
                onClick={() => {onDownload(selectedPipeline)}}
            >
                <LinkText><i className="pi pi-download"></i> Markdown</LinkText>
            </Button>
            :
            ""
    );
}

export default DownloadPipeline;
