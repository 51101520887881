import React from "react";

import { getNodes } from "./helper";
import HyperLink from "../../UtilComponents/HyperLink";
import { toolType } from "../../../utils/constants";
import ToolInfoView from "../../UtilComponents/ToolInfoView";
import {
  StyledReport,
  StyledReportSection,
  ReportList,
  StyledReportNode,
} from "../../../styles/ReportStyle";
import ChipGroup from "../../UtilComponents/ChipGroup";

const DialogContent = (props) => {
  const { data, selectedPipeline } = props;
  const pipeline = data.pipelines.find(
    (pipeline) => pipeline.name === selectedPipeline
  );

  const getReportOutline = () => {
    // get nodes that make up the selected pipeline
    const { pipelineNodes, forks } = getNodes(pipeline, data.graph);

    console.log(pipeline);
    console.log(forks);

    return (
      <React.Fragment>
        <StyledReportSection>
          <span className="section-title">CORE: {selectedPipeline}</span>
          <ReportList>
            {pipelineNodes.map((node, i) => (
              <li key={i}>{getFormattedNode(node)}</li>
            ))}
          </ReportList>
        </StyledReportSection>
        {forks.map((fork, i) => (
          <StyledReportSection key={i}>
            <span className="section-title">
              FORK {i + 1}: {fork.title}
              {
                fork.executableLinks && 
                <ChipGroup
                  executableLinks={fork.executableLinks}
                  disabled={false}
                  executableType="analysis"
                />
              }
            </span>
            <ReportList start={pipelineNodes.length}>
              {fork.nodes.map((node, i) => (
                <li key={i}>{getFormattedNode(node)}</li>
              ))}
            </ReportList>
          </StyledReportSection>
        ))}
      </React.Fragment>
    );
  };

  const getFormattedNode = (node) => {
    if (toolType.includes(node.type)) {
      return ToolNode(node);
    } else {
      return FileNode(node);
    }
  };

  const FileNode = (node) => (
    <StyledReportNode>
      <div>
        [Data] <b>{node.name}</b>
      </div>
    </StyledReportNode>
  );

  const ToolNode = (node, i) => (
    <StyledReportNode>
      <div className="header">
        <b>
          {node.name}
          {node.info.version ? ` (${node.info.version})` : ""}
        </b>
        <ChipGroup
          executableLinks={node.info.executableLinks}
          disabled={false}
          executableType="tool"
          executableInfo={{
            name: node.name,
            id: node.toolId,
            type: "tool",
          }}
        />
      </div>
      <div className="node-content">
        <ToolInfoView toolInfo={node.info} />
      </div>
    </StyledReportNode>
  );

  return (
    <StyledReport>
      <div className="title">
        <h3>{selectedPipeline}</h3>
        <ChipGroup
          executableLinks={pipeline.main.executableLinks}
          core={pipeline.core ? pipeline.link : null}
          disabled={false}
          executableType="pipeline"
          executableInfo={{
            name: `${pipeline.name}(${pipeline.datatype.join(",")})`,
            id: pipeline._id,
            type: "pipeline",
            isService: pipeline.core,
          }}
          showHPC
        />
      </div>
      <div className="report-content">
        <div className="header-line">
          <b>Version:</b>{" "}
          {pipeline.version ? pipeline.version : "Not Available"}
        </div>
        <div className="header-line">
          <b>Users: </b>
          {pipeline.user.length > 0
            ? pipeline.user.map((u, i) => [
                i > 0 && ", ",
                u.link.length > 0 ? (
                  <HyperLink key={i} link={u.link} text={u.name} />
                ) : (
                  u.name
                ),
              ])
            : "Not Available"}
        </div>
        <div className="header-line">
          <b>Link: </b>
          {pipeline.link ? (
            <HyperLink link={pipeline.link} text={pipeline.link} />
          ) : (
            "Not Available"
          )}
        </div>
        {getReportOutline()}
      </div>
    </StyledReport>
  );
};

export default DialogContent;
