import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { useKeycloak } from "@react-keycloak/web";

import Layout from "../../UtilComponents/Layout";
import MatchedAnalysis from "./MatchedAnalysis";
import DecisionTree from "../../Diagrams/DecisionTree";
import CustomButton from "../../UtilComponents/CustomButton";
import CustomMessages from "../../UtilComponents/CustomMessages";
import PipelineDialog from "../PipelineDialog/PipelineDialog";
import ChipGroup from "../../UtilComponents/ChipGroup";
import { formatNodeObjects } from "../../../utils/format-node";
import Loading from "../../UtilComponents/Loading";
import colors from "../../../styles/colors";
import { pipelineVerifySuccess, genericError } from "../../../utils/messages";
import Disclaimer from "../../UtilComponents/Disclaimer";
import LikeButton from "../../UtilComponents/LikeButton";
import ExpertList from "../../UtilComponents/ExpertList";
import ShareButton from "../../UtilComponents/ShareButton";

const Container = styled.div`
  width: 90%;
  min-width: 1000px;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  width: 100%;
  height: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .admin-buttons {
    display: flex;
    align-items: center;
    margin-left: 20px;
    .submitted-by {
      margin-left: 10px;
      font-size: 12px;
    }
  }
  .notvisible {
    color: ${colors.red};
    padding: 3px;
    font-size: 12px;
    margin-left: 10px;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  jusitfy-content: space-between;
  .section {
    flex-direction: column;
  }
`;

const PageMessage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      i {
        font-size: 200px;
        font-weight: bold;
      }
      margin-bottom: 30px;
    }
  }
`;

const IndividualPipeline = () => {
  const { keycloak } = useKeycloak();
  let { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState({
    graph: {},
    pipelines: [],
    pipeline: null,
    user: null,
    routes: [],
    filtered: false,
    initialized: false,
    ready: false,
  });
  const [showDialog, setShowDialog] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    let endpoint = `/api/data/pipeline/${id}/nofilter`;
    if (keycloak.authenticated) {
      endpoint = `/api/data/restricted_pipeline/${id}/nofilter`;
    }
    getData(endpoint);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak.authenticated]);

  useEffect(() => {
    return () => {
      if (history.action === "POP" && history.location.pathname === "/") {
        history.replace({
          pathname: "/",
          state: {
            search: location.state.search,
          },
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const getData = async (endpoint) => {
    const res = await axios.get(endpoint, {
      params: {
        purpose: location.state
          ? location.state.purposes.map((purpose) => purpose.text)
          : null,
        email: keycloak.authenticated ? keycloak.idTokenParsed.email : null,
      },
    });
    setData({
      ...res.data,
      graph: {
        ...res.data.graph,
        nodes: formatNodeObjects(
          res.data.pipeline ? [res.data.pipeline] : [],
          res.data.tools,
          res.data.graph.nodes
        ),
      },
      pipelines: res.data.pipeline ? [res.data.pipeline] : [],
      filtered: true,
      ready: true,
    });
  };

  const verifyPipeline = async (e) => {
    e.preventDefault();
    try {
      setData((prev) => ({ ...prev, ready: false }));
      await axios.post(
        "/api/data/pipeline/verify",
        { pipelineId: data.pipeline._id },
        { params: { email: keycloak.idTokenParsed.email } }
      );
      setMessage(pipelineVerifySuccess);
    } catch (error) {
      console.log(error);
      setMessage(genericError);
    } finally {
      getData(`/api/data/restricted_pipeline/${id}/nofilter`);
      setShowMessage(Math.random());
    }
  };

  const renderVerificationContent = () => {
    if (keycloak.authenticated && !data.pipeline.verified) {
      if (data.user && data.user.admin) {
        return (
          <div className="admin-buttons">
            <CustomButton
              className="p-button-text p-button-sm p-button-success"
              icon="pi pi-check"
              label="Verify"
              onClick={verifyPipeline}
            />
            <span className="submitted-by">
              Submitted by: <b>{data.pipeline.submittedBy.email}</b>
            </span>
          </div>
        );
      } else {
        return (
          <div className="notvisible">
            This pipeline is not publicly visible. Pending for approval
          </div>
        );
      }
    } else {
      return "";
    }
  };

  const render = () => {
    if (data.pipeline) {
      return (
        <React.Fragment>
          <Disclaimer />
          <Header>
            <span className="title">{data.pipeline.name}</span>
            <CustomButton
              className="p-button-text p-button-sm p-button-info"
              icon="pi pi-search"
              label={`View details`}
              onClick={() => {
                setShowDialog(true);
              }}
            />
            <ChipGroup
              executableLinks={data.pipeline.main.executableLinks}
              core={data.pipeline.core ? data.pipeline.link : null}
              disabled={false}
              executableType="pipeline"
              executableInfo={{
                name: `${data.pipeline.name}(${data.pipeline.datatype.join(
                  ","
                )})`,
                id: data.pipeline._id,
                type: "pipeline",
                isService: data.pipeline.core,
              }}
            />
            <div>{renderVerificationContent()}</div>
            <LikeButton
              id={data.pipeline._id}
              type="pipeline"
              user={data.user}
              disabled={true}
            />
            <ShareButton
              id={data.pipeline._id}
              type="pipeline"
              name={data.pipeline.name}
            />
          </Header>
          <Content>
            <div className="section">
              {location.state && (
                <MatchedAnalysis
                  steps={location.state.pipelineSteps}
                  purposes={location.state.purposes}
                />
              )}
              {keycloak.authenticated ? (
                <ExpertList
                  id={data.pipeline._id}
                  name={data.pipeline.name}
                  type={"pipeline"}
                  user={keycloak.idTokenParsed.email}
                  userName={
                    keycloak.idTokenParsed.given_name +
                    " " +
                    keycloak.idTokenParsed.family_name
                  }
                />
              ) : (
                ""
              )}
            </div>
            <DecisionTree
              graph={data.graph}
              pipelines={data.pipelines}
              highlightedTools={location.state ? location.state.tools : []}
              filtered={data.filtered}
              selectedPipeline={"None"}
              corePipelines={[]}
              width={1000}
              height={500}
            />
          </Content>
          <PipelineDialog
            visible={showDialog}
            onHide={() => {
              setShowDialog(false);
            }}
            data={data}
            selectedPipeline={data.pipeline.name}
          />
        </React.Fragment>
      );
    } else {
      return (
        <PageMessage>
          <div className="wrapper">
            <div className="icon">
              <i className="pi pi-ban"></i>
            </div>
            <h1>Pipeline Not Found</h1>
          </div>
        </PageMessage>
      );
    }
  };

  return (
    <Layout>
      <Container>
        <CustomMessages trigger={showMessage} message={message} />
        {data.ready ? render() : <Loading />}
      </Container>
    </Layout>
  );
};

export default IndividualPipeline;
