import styled from 'styled-components';

const StyledRadioButtonGroup = styled.div`
    display: flex;
    justify-content: left;
    font-size: 12px;
    // margin-bottom: 20px;
    .radiobutton {
        display: flex;
        align-items: center;
        margin-right: 20px;
        label {
            margin-left: 5px;
        }
    }
`;

export default StyledRadioButtonGroup;