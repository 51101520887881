import React, { useState, useEffect, Fragment } from 'react';
import axios from "axios";
import styled from 'styled-components';
import Loading from '../../../UtilComponents/Loading';
import { Chart } from 'primereact/chart';
import { Calendar } from 'primereact/calendar';
import colors from "../../../../styles/colors";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const StyledDiagram= styled.div`
  margin: 10px 20px;
  font-size: 12px;
  font-weight: bold;
`;

const StyledStats = styled.div`
  display: flex;
  flex-direction: column;
	.header {
		align-self: flex-start;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		margin: 0px 20px 10px 20px;
		.date-picker {
			margin-right: 20px;
		}
	}
  .card {
    margin-bottom: 2rem;
  }
`;

const getLightTheme = () => {
    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: .8,
        categoryPercentage: 0.8,
        indexAxis: 'y',
        plugins: {
            legend: {
                labels: {
                    color: '#554957'
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let item = context.dataset.metadata.filter(item => item.name ===context.label)[0];
                        let label =[]
                        label.push(context.dataset.label+ ': ' + context.formattedValue) ;
                        label.push('Clicks: ' + item.num_clicks);
                        label.push('Likes: ' + item.num_likes);
                        return label;
                    }
                }
            }
        },
        legend: {
            position: 'bottom',
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef',
                }
            },
            y: {
                ticks: {
                    color: '#495057',
                    beginAtZero: true,
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };
    return {
        basicOptions,
    }
}

const standardize = (x, mean, sd, min) => {
    return ((x - mean)/ sd) + Math.abs(min);
}

const getValues = (array) => {
    const n = array.length;
    const min = Math.min(...array);
    const max = Math.max(...array);
    const mean = array.reduce((a, b) => a + b) / n;
    const sd = Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n);
    return ({min:(min-mean)/sd, max:max, mean:mean, sd:sd});
}

const topData = (data) => {
    const topNum = 10;
    let newData = [];

    const {mean:ws_mean, sd:ws_sd, min:ws_min, max:ws_max} = getValues(data.map(item=> {return (item.weight_score)}));

    data.forEach(item => newData.push({...item,
        weight_score: standardize(item.weight_score, ws_mean, ws_sd, ws_min ,ws_max)}
    ))

    newData = newData.slice(0,topNum);

    return {
        labels: newData.map(item=> `${item.name}`),
        datasets: [
            {
                label: 'Popularity',
                data: newData.map(item => item.weight_score),
                metadata: newData,
                backgroundColor : colors.medium_blue
            },
        ]
    }
}


const TopStats = () => {
    const minDate = new Date(2022, 0, 1);
    const maxDate = new Date();
    const { basicOptions} = getLightTheme();
    const [top, setTop] = useState({data : {}, ready: false});
    const [startDate, setStartDate] = useState(minDate);
    const [endDate, setEndDate] = useState(maxDate);

		useEffect(() => {
			const getData = async () => {
				setTop({...top, ready: false});
				const res = await axios.get('/api/data/stats/top', {
					params:{
						startDate: startDate.toISOString(),
						endDate: endDate.toISOString()
					}
				});
        setTop({data: res.data, ready: true});
			}
			getData();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [startDate, endDate])

    return(
        <StyledStats>
            <div className='header'>
                <div className='date-picker'>
                    <span>Start: </span>
                    <Calendar
                        dateFormat="yy-mm-dd"
                        value={startDate}
                        minDate={minDate}
                        maxDate={endDate}
                        onChange={(e) => setStartDate(e.value)}
                        disabled={!top.ready}
                    />
                </div>
                <div className='date-picker'>
                    <span>End: </span>
                    <Calendar
                        dateFormat="yy-mm-dd"
                        value={endDate}
                        minDate={startDate}
                        maxDate={maxDate}
                        onChange={(e) => setEndDate(e.value)}
                        disabled={!top.ready}
                    />
                </div>
            </div>
            {
                top.ready ?
                <Fragment>
                    <StyledDiagram>
                        <div className="label">{`Top Pipelines`}</div>
                        <Chart type="bar" data={topData(top.data.pipelines)} options={basicOptions}/>
                    </StyledDiagram>
                    <StyledDiagram>
                        <div className="label">{`Top Tools`}</div>
                        <Chart type="bar" data={topData(top.data.tools)} options={basicOptions}/>
                    </StyledDiagram>
                </Fragment>
                :
                <Loading />
            }
        </StyledStats>
    );
}

export default TopStats;
