import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";

const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

const ToolTip = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: #fff;
`;

const Badge = styled.img`
  width: 40px;
  height: 40px;
  margin: 5px;
`;

const CustomToolTip = styled(ReactTooltip).attrs({
    className: "custom-class"
})`
  &.custom-class.type-dark.__react_component_tooltip {
    color: white;
    background-color: #1c202b !important;
    border-radius: 3px;
    padding: 6px 9px;
  }
`;

const DrawBadge = (props) => {
    const {name, image} = props;
    const key = name.replace(" ", "-");
    return (
        <ToolTip className={"custom-class"}>
            <Badge
                data-tip={name}
                data-for={name}
                key={key}
                src={image}
            />
            <CustomToolTip
                key={name}
                id={name}
                place="right"
                effect="solid"
            />
        </ToolTip>
    );
}

// activity thresholds
const th = {
    likes: { first: 10, second: 25, third: 50},
    pipeline_submission : { first: 3, second: 10, third: 15},
    tool_expert: { first: 5, second: 15, third: 30},
    pipeline_expert:  { first: 3, second: 10, third: 15}
}

const AchievementBadges = (props) => {
    const {userLikes, userExpert, userPipelines} = props;
    const totalLikes= userLikes.tools + userLikes.pipelines;
    return (
        <Container>
            {/*Like Badge*/}
            {(totalLikes>= th.likes.first && totalLikes < th.likes.second) ?
                <DrawBadge name= {`Achieved ${th.likes.first} likes`} image='/images/badges/likes_10.png' />: ''}
            {(totalLikes >= th.likes.second && totalLikes< th.likes.third)?
                <DrawBadge name= {`Achieved ${th.likes.second} likes`}  image='/images/badges/likes_25.png' />: ''}
            {(totalLikes >= th.likes.third)?
                <DrawBadge name= {`Achieved ${th.likes.third} likes`} image='/images/badges/likes_50.png' />: ''}

            {/*/!*Pipeline Submission Badge*!/*/}
            { (userPipelines.length >= th.pipeline_submission.first && userPipelines.length< th.pipeline_submission.second)?
                <DrawBadge name= {`Achieved ${th.pipeline_submission.first} pipeline submission`} image='/images/badges/submission-3.png'/>: ''}
            { (userPipelines.length >= th.pipeline_submission.second && userPipelines.length < th.pipeline_submission.third)?
                <DrawBadge name= {`Achieved ${th.pipeline_submission.second} pipeline submission`} image='/images/badges/submission-10.png'/>: ''}
            { (userPipelines.length >= th.pipeline_submission.third)?
                <DrawBadge name= {`Achieved ${th.pipeline_submission.third} pipeline submission`} image='/images/badges/submission-15.png'/>: ''}

            {/*/!*Tool Expert Badge*!/*/}
            { (userExpert.tools.length >= th.tool_expert.first && userExpert.tools.length < th.tool_expert.second)?
                <DrawBadge name= {`Achieved ${th.tool_expert.first} tools of expertise`} image='/images/badges/toolExpert_5.png'/>: ''}
            { (userExpert.tools.length >= th.tool_expert.second && userExpert.tools.length< th.tool_expert.third)?
                <DrawBadge name= {`Achieved ${th.tool_expert.second} tools of expertise`} image='/images/badges/toolExpert_15.png'/>: ''}
            {(userExpert.tools.length >= th.tool_expert.third)?
                <DrawBadge name= {`Achieved ${th.tool_expert.third} tools of expertise`} image='/images/badges/toolExpert_30.png'/>: ''}

            {/*/!*Pipeline Expert Badge*!/*/}
            { (userExpert.pipelines.length >= th.pipeline_expert.first && userExpert.pipelines.length< th.pipeline_expert.second)?
                <DrawBadge name= {`Achieved ${th.pipeline_expert.first} pipelines of expertise`} image='/images/badges/pipelineExpert_3.png'/>: ''}
            { (userExpert.pipelines.length >= th.pipeline_expert.second && userExpert.pipelines.length< th.pipeline_expert.third)?
                <DrawBadge name= {`Achieved ${th.pipeline_expert.second} pipelines of expertise`} image='/images/badges/pipelineExpert_10.png'/>: ''}
            {(userExpert.pipelines.length >= th.pipeline_expert.third)?
                <DrawBadge name= {`Achieved ${th.pipeline_expert.third} pipelines of expertise`} image='/images/badges/pipelineExpert_20.png'/>: ''}
        </Container>
    )
}

export default AchievementBadges;
