import styled from 'styled-components';

const StyledBuildPipeline = styled.div`
    margin-top: 20px;
    width: 90%;
`;

const StyledWarningDialog = styled.div`
    font-size: 12px;
    line-height: 1.2;
    li {
        padding-bottom: 10px;
    }
`;

const StyledBuilderHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 20px;
`;

const StyledPipelineCanvas = styled.div`
    display: flex;
    justify-content: center;
    width: 49%;
    height: 80%;
    .message {
        font-size: 18px;
    }
`;

const StyledBuilderForm = styled.div`
    font-size: 12px;
    margin-bottom: 20px;
    .formRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .label {
            width: 150px;
            display: flex;
            align-items: center;
            .required {
                color: red;
                font-size: 14px;
            }
        }
        i {
            margin-left: 5px;
        }
        .input, .value {
            width: 80%;
        }
        .multi-line-field {
            width: 80%;
            display: flex;
            flex-direction: column;
        }
    }
    .buttonGroup {
        display: flex;
        align-items: center;
        // margin-bottom: 10px;
        .left {
            margin-right: 10px;
        }
    }
`;

const StyledSubHeader = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
`;

export {
    StyledBuildPipeline,
    StyledWarningDialog,
    StyledBuilderHeader,
    StyledPipelineCanvas,
    StyledBuilderForm,
    StyledSubHeader
}

