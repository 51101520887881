import React, { useState, useEffect, useContext } from 'react';
import { PipelineBuilderContext } from '../../../../hooks/Contexts';
import { StyledBuilderForm } from '../BuildPipelineStyles';
import CustomDropdown from '../../../UtilComponents/CustomDropdown';
import CustomButton from '../../../UtilComponents/CustomButton';
import { toolType } from '../../../../utils/constants';
import { getNodeIdLabel } from '../../../../utils/helper';

const RemoveNode = () => {
    
    const { graphData, setGraphData, pipeline, setPipeline } = useContext(PipelineBuilderContext);
    const [nodeToRemove, setNodeToRemove] = useState({node: null, options: []});

    useEffect(() => {
        let options = graphData.data.nodes
            .filter(node => toolType.includes(node.type))
            .map(node => ({label: `${node.name} - ${getNodeIdLabel(node.id)}`, value: node.id}));
        setNodeToRemove({
            node: null, 
            options: options
        });
    }, [graphData]);

    const removeNode = (e) => {
        e.preventDefault();
        console.log(nodeToRemove.node);
        let tools = [...graphData.tools];
        let removedTool = graphData.data.nodes.find(node => node.id === nodeToRemove.node);
        let index = tools.findIndex(tool => tool._id.toString() === removedTool.toolId);
        tools.splice(index, 1);
        // remove node and links to/from the node from the graphData object.
        let graphNodes = graphData.data.nodes.filter(node => node.id !== nodeToRemove.node);
        let graphLinks = graphData.data.links.filter(link => link.source !== nodeToRemove.node && link.target !== nodeToRemove.node);
        // remove node from the pipeline object
        let pipelineNodes = pipeline.main.nodes.filter(node => node.id !== nodeToRemove.node);
        let purposes = [];
        for(const purpose of pipeline.purposes){
            let purposeNodes = purpose.nodes.filter(node => node.id !== nodeToRemove.node);
            if(purposeNodes.length > 0){
                purposes.push({...purpose, nodes: purposeNodes});
            }
        }
        setGraphData(prev => ({
            ...prev,
            data: {
                nodes: graphNodes,
                links: graphLinks
            },
            tools: tools
        }));
        setPipeline(prev => ({
            ...prev,
            main: {
                ...prev.main,
                nodes: pipelineNodes
            },
            purposes: purposes,
            changeGraph: true
        }));
    }

    return(
        <StyledBuilderForm>
            <div className='formRow'>
                <span className='label'>Node to Remove: </span>
                <CustomDropdown 
                    className='input dropdown'
                    value={nodeToRemove.node}
                    options={nodeToRemove.options}  
                    onChange={(e) => {setNodeToRemove(prev => ({...prev, node: e.value}))}} 
                    placeholder={'Select...'}
                    filter={true}
                    disabled={nodeToRemove.options.length === 0}
                />
            </div>
            <div className='buttonGroup'>
                <CustomButton 
                    label='Remove Node' 
                    onClick={removeNode} 
                    className='p-button-sm p-button-danger'
                    disabled={!nodeToRemove.node}
                />
            </div>
        </StyledBuilderForm>
    );
}

export default RemoveNode;