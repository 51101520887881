import React, { useState, useEffect } from 'react';
import axios from "axios";
import styled from "styled-components";
import colors from "../../../../styles/colors";
import Moment from 'moment';
import { Divider } from 'primereact/divider';
import {ToolTable, PipelineTable} from "./NewsTables";
import {StyledLink} from "../../../../styles/utils";
import { Button } from 'primereact/button';
import Loading from '../../../UtilComponents/Loading';
import ReactPaginate from 'react-paginate';

const StyledPaginatedNews = styled.div`
  .paginationBttns {
    width: 80%;
    height: 20px;
    list-style: none;
    display: flex;
    justify-content: center;
    font-size: 12px;
  }
  
  .paginationBttns a {
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
    color: ${colors.hyperlink};
    cursor: pointer;
  }

  .paginationBttns a:hover {
    color: white;
    background-color: ${colors.light_gray};
  }

  .paginationActive a {
    color: ${colors.origin};
    background-color: white;
  }

  .paginationDisabled a {
    color: white;
    background-color: white;
  }
`

const StyledNews = styled.div`
  width: 100%;
  .subject {
    display: flex;
    flex-direction: row;
    height: 25px;
    font-size: 12px;
    font-weight: bold;
    padding: 10px;
    margin-left: 10px;
  }
  .date {
    color: ${colors.light_gray};
    display: flex;
    align-items: center;
    height: 20px;
    font-size: 12px;
    font-weight: normal;
    padding: 10px;
    margin: 5px 0px 0px 10px;
  }

  .content {
    line-height: 20px;
    font-size: 14px;
    font-weight: normal;
    color: ${colors.gray_text};
    padding: 10px;
    margin-left: 10px;
  }

  .letter {
    line-height: 20px;
    font-size: 14px;
    font-weight: normal;
    color: ${colors.gray_text};
  }

  .divider {
    padding-top: 1px;
  }

  .table {
    margin-top: 20px;
  }
`;


const htmlToText = (text) => {
    let content = text;
    content = content.replace(/<style([\s\S]*?)<\/style>/gi, '');
    content = content.replace(/<script([\s\S]*?)<\/script>/gi, '');
    content = content.replace(/<\/div>/ig, '\n');
    content = content.replace(/<\/li>/ig, '\n');
    content = content.replace(/<li>/ig, '  *  ');
    content = content.replace(/<\/ul>/ig, '\n');
    content = content.replace(/<\/p>/ig, '\n');
    content = content.replace(/<[^>]+>/ig, '');
    content = content.replace('&nbsp;', '');
    return content;
}

const headline = (array) => {
    let headline = '';
    array.forEach(item => {
        if (item.trim() === "") {
            headline += "\n";
        }
        if (headline.length < 120) {
            headline += item;
        }
    })
    return headline;
}

const NewsLetter = (props) => {
    let {content , subject} = props;
    content = htmlToText(content);
    let paragraphs = content.split('\n');
    return (
        <p className='newsletter'>
            {headline(paragraphs)}
            { paragraphs.length > 2?
                <StyledLink to={{pathname: `news/${subject.toLowerCase()
                        .replace(/ /g,'-')
                        .replace(/[^\w-]+/g,'')}`}}
                            target='_blank'>
                <span style={{display:'flex', justifyContent:'flex-end'}}>
                    <Button
                        label="Read more"
                        className="p-button-text"
                        icon="pi pi-chevron-circle-right"
                        iconPos="right">
                    </Button>
                </span>
                </StyledLink>
                : ''
            }
        </p>
    );
}

const customizedContent = ( divider , news, index) => {
    const {subject, content, date, tools, pipelines, type} = news;
    const current = new Date(date);

    return (
        <StyledNews key = {index}>
            <div className='subject'>{subject}</div>
            <div className='date'>{type ==="report"? Moment(new Date(current.getFullYear(), current.getMonth(), 1)).format("MMM Do, YYYY")
                : Moment(new Date(current.getFullYear(), current.getMonth(), current.getDate())).format("MMM Do, YYYY")
            }</div>
            <div className='content'>
                { type ==="letter" ?
                    <NewsLetter content = {content} subject ={subject} />
                    :
                    <div>{content}</div>
                }
                {
                    pipelines.length ?
                        <div className="table">
                            <PipelineTable pipelines={pipelines}/>
                        </div>
                        : ''
                }
                {
                    tools.length?
                        <div className="table">
                            <ToolTable tools={tools}/>
                        </div> : ''
                }
            </div>
            {divider? <Divider className='divider' style={{ backgroundColor : `${colors.border_gray}`}}/> : ''}
        </StyledNews>
    );
}

function Items({ currentItems }) {
    return (
        <>
            {currentItems &&
            currentItems.map((item, index) => (
                <div key={index}>
                    {item}
                </div>
            ))}
        </>
    );
}

function PaginatedNews({ news, itemsPerPage }) {
    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(news.sort((a, b) => new Date(b.date) - new Date(a.date)).map((item, index) => (
            customizedContent(true, item, index))).slice(itemOffset, endOffset));
        setPageCount(Math.ceil(news.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, news]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % news.length;
        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
    };

    return (
        <StyledPaginatedNews>
            <Items currentItems={currentItems} />
            <ReactPaginate
                pageCount={pageCount}
                onPageChange={handlePageClick}
                previousLabel={"previous"}
                nextLabel={"next"}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                breakLabel="..."
                pageRangeDisplayed={5}
                renderOnZeroPageCount={null}
            />
        </StyledPaginatedNews>
    );
}

const NewsFeed = () => {
    const [news, setNews] = useState({data: {}, ready: false});

    useEffect(() => {
        const getData = async () => {
            const res = await axios.get('/api/data/stats/news');
            setNews({data: res.data.news, ready: true});
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <React.Fragment>
            {
                news.ready ? <PaginatedNews news= {news.data} itemsPerPage={4} /> : <Loading />
            }
        </React.Fragment>
    );
}

export default NewsFeed;
