import colors from "../styles/colors";
import ReactGA from "react-ga4";

export const getNodeIdLabel = (nodeId) => {
  let idVal = nodeId.split("_")[1];
  return idVal.length > 4 ? idVal.slice(idVal.length - 4) : idVal;
};

export const getChipColor = (linkType) => {
  switch (linkType) {
    case "HPC":
      return colors.executableHPC;
    case "CodeOcean":
      return colors.executableCodeOcean;
    case "CodeOceanVPC":
      return colors.executableCodeOcean;
    case "WebApp":
      return colors.webApp;
    case "service":
      return colors.servicePipeline;
    default:
      return colors.default;
  }
};

export const onChipClick = (executableInfo, linkObj, user = undefined) => {
  if (executableInfo) {
    ReactGA.event({
      category: "ExecutableLinkClick",
      action: `${executableInfo.isService ? "Service" : linkObj.linkType}:\
            ${executableInfo.name}:\
            ${executableInfo.id}:\
            ${executableInfo.type}:\
            ${user ? user.affiliation : ""}`,
      value: 1,
    });
  }
};

export const invalidExecutableLink = (executables) => {
  if (executables.length === 0) {
    return true;
  }
  for (const executable of executables) {
    if (
      executable.executableLink.length === 0 ||
      executable.linkType.length === 0
    ) {
      return true;
    }
    if (
      executable.linkType === "HPC" &&
      !/github.com/.test(executable.executableLink)
    ) {
      return true;
    }
    if (
      executable.linkType === "CodeOcean" &&
      !/codeocean.com/.test(executable.executableLink)
    ) {
      return true;
    }
  }
  return false;
};
