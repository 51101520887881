import React from 'react';
import styled from 'styled-components';
import CustomInputText from '../../../UtilComponents/CustomInputText';
import CustomButton from '../../../UtilComponents/CustomButton';
import ExecutableLinkForm from './ExecutableLinkForm';

const StyledPurposeForm = styled.div`
    width: 100%;
    margin-bottom: 20px;
    .purpose-form-element {
        display: flex;
        align-items: center;
        .purpose-text {
            width: 60%;
            min-width: 300px;
            margin-right: 10px;
        }
    }
    .purpose-executable-links {
        padding-left: 20px;
    }
`;

const ToolPurposeForm = (props) => {
    const { purpose, index, tool, setTool, isEdit } = props;

    const updatePurpose = (field, value) => {
        let purposes = [...tool.purposes];
        purposes[index][field] = value;
        setTool(prev => ({
            ...prev,
            purposes: purposes
        }));
    }

    const addPurpose = (e) => {
        e.preventDefault();
        let purposes = [...tool.purposes];
        purposes.push({
            text: '',
            executableLinks: []
        });
        setTool(prev => ({
            ...prev,
            purposes: purposes
        }));
    }

    const removePurpose = (e) => {
        e.preventDefault();
        let purposes = [...tool.purposes];
        purposes.splice(index, 1);
        setTool(prev => ({
            ...prev,
            purposes: purposes
        }));
    }

    const addExecutableLink = (e) => {
        e.preventDefault();
        let purposes = [...tool.purposes];
        purposes[index].executableLinks.push({
            executableLink: '',
            linkType: '',
            default: purposes[index].executableLinks.length === 0 ? true : false
        });
        setTool(prev => ({
            ...prev,
            purposes: purposes
        }));
    }

    const updateLinks = (links) => {
        let purposes = [...tool.purposes];
        purposes[index].executableLinks = links;
        setTool(prev => ({
            ...prev,
            purposes: purposes
        }));
    }

    const updateOneLink = (linkIndex, linkObj) => {
        let purposes = [...tool.purposes];
        purposes[index].executableLinks[linkIndex] = linkObj;
        setTool(prev => ({
            ...prev,
            purposes: purposes
        }));
    }

    return(
        <StyledPurposeForm>
            <div className='purpose-form-element'>
                <CustomInputText 
                    className='purpose-text'
                    type='text'
                    value={purpose.text}
                    onChange={(e) => {updatePurpose('text', e.target.value)}}
                    placeholder='Purpose description'
                />
                <React.Fragment>
                {
                    isEdit &&
                    <div className='buttonGroup'>
                        {
                            index === tool.purposes.length - 1 &&
                            <CustomButton 
                                className='p-button-primary p-button-rounded p-button-text' 
                                icon='pi pi-plus'
                                disabled={purpose.text.length === 0}
                                onClick={addPurpose}
                                tooltip='Add another tool purpose.'
                            />
                        }
                        {
                            index !== 0 &&
                            <CustomButton 
                                className='p-button-danger p-button-rounded p-button-text' 
                                icon='pi pi-times'
                                onClick={removePurpose}
                                tooltip='Remove tool purpose.'
                            />
                        }
                        <CustomButton 
                            className='p-button-primary p-button-rounded p-button-text' 
                            label='Add executable link'
                            disabled={purpose.executableLinks.length > 0}
                            onClick={addExecutableLink}
                            tooltip='Add executable link'
                        />
                    </div>
                }
                </React.Fragment>
            </div>
            <div className='purpose-executable-links'>
            {
                purpose.executableLinks.map((linkObj, linkIndex) => (
                    <ExecutableLinkForm 
                        key={linkIndex}
                        executableLinks={purpose.executableLinks}
                        executableLinkObj={linkObj}
                        linkIndex={linkIndex}
                        updateLinks={updateLinks}
                        updateOneLink={updateOneLink}
                    />
                ))
            }
            </div>
        </StyledPurposeForm>
    );
}

export default ToolPurposeForm;