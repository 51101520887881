import React from 'react';
import CustomMessages from './CustomMessages';

const Disclaimer = () => {
    return(
        <CustomMessages 
            trigger={true}
            message={{
                severity: 'info', 
                summary: 'Disclaimer', 
                detail: 'CoBE uses an external cloud platform (Code Ocean) to offer easy execution of the pipelines and data analyses. Your institution may limit the usage of cloud platform for processing patient health information (PHI). Please follow your institution\'s policy on handling of PHI data when using Code Ocean or any other external platform used by CoBE.', 
                sticky: true 
            }}
        />
    )
}

export default Disclaimer;