import React from 'react';
import { SubContainer } from '../Tutorial/TutorialStyles';
import Layout from '../../UtilComponents/Layout';
import DownloadButton from '../../UtilComponents/DownloadButton';

const SubmitPiepline = (props) => {
    return(
        <Layout>
            <SubContainer>
                <h1>Submitting Your Pipeline to CoBE</h1>
                <h3>There are two ways to submit your pipeline:</h3>
                <h4>1. Use Interactive Pipeline Builder (Beta)</h4>
                <div className='section'>
                    CoBE's interactive pipeline builder enables users to build their pipeline using a GUI.<br />
                    <ol>
                        <li>Learn how to use the pipeline builder under the "Pipeline Builder" section of the <a href='/tutorial?section=builder'>Tutorial page</a>.</li>
                        <li>Start building the pipeline using the <a href='/pipeline-builder'>Pipeline Builder</a>.</li>
                        <li>Submit your pipeline for verification.</li>
                    </ol>
                </div>
                <h4>2. Fill out the Pipeline Submission Template</h4>
                <div className='section'>
                    <ol>
                        <li>Download the Pipeline Submission Template Excel file.</li>
                        <li>Fill in the template file.</li>
                        <li>Email the Excel file to <b>ankita.nand@uhnresearch.ca</b></li>
                    </ol>
                </div>
                <div className='section'>
                    <DownloadButton 
                        filename='Pipeline_Submission_Template.xlsx'
                        tooltip='Download the pipeline submission template'
                    />
                </div>
            </SubContainer>
        </Layout>
    );
}

export default SubmitPiepline;