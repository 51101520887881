import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useKeycloak } from "@react-keycloak/web";
import Layout from '../../UtilComponents/Layout';
import styled from 'styled-components';
import CustomMessages from '../../UtilComponents/CustomMessages';
import Loading from '../../UtilComponents/Loading';
import { TabPanel, TabView } from "primereact/tabview";
import SubmissionTab from './ProfileComponents/SubmissionTab';
import FavoritesTab from './ProfileComponents/FavoritesTab';
import AdminTab from './ProfileComponents/AdminTab';
import AchievementTab from './ProfileComponents/AchievementTab';

const Container = styled.div`
    width: 90%;
    min-width: 1000px;
    display: flex;
    flex-direction: column;
    .title {
        width: 100%;
        height: 60px;
        padding-top: 24px;
        padding-bottom: 24px;
        font-size: 20px;
        font-weight: bold;
    }
`;

const Profile = () => {
    const { keycloak } = useKeycloak();
    const [user, setUser] = useState({});
    const [userPipelines, setUserPipelines] = useState([]);
    const [userTools, setUserTools] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [ready, setReady] = useState(false);

    if(!keycloak.authenticated){
        keycloak.login();
    }

    const initialize = async () => {
        const res = await axios.post('/api/user/profile', {
            email: keycloak.idTokenParsed.email
        });
        setUser(res.data.user);
        setUserPipelines(res.data.pipelines);
        setUserTools(res.data.tools);
        setReady(true);
    }

    useEffect(() => {
        initialize();
    }, []);

    const submissionsTab = (
        <TabPanel header='Submissions'>
            <SubmissionTab
                initialize={initialize}
                setMessage={setMessage}
                setShowMessage={setShowMessage}
                user={user}
                userPipelines={userPipelines}
                userTools={userTools}
            />
        </TabPanel>
    );

    const favoritesTab = (
        <TabPanel header='Favourites'>
            <FavoritesTab user={user} />
        </TabPanel>
    );

    const adminTab = (
        <TabPanel header='Admin'>
            <AdminTab setMessage={setMessage} setShowMessage={setShowMessage} />
        </TabPanel>
    );

    const achievementTab = (
        <TabPanel header='Achievements'>
            <AchievementTab
                user={user}
                userPipelines={userPipelines}
                userTools={userTools}/>
        </TabPanel>
    );

    const renderTabs = () => {
        if(user.admin){
            return(
                <TabView activeIndex={activeTab} onTabChange={ e => { setActiveTab(e.index)}}>
                    { submissionsTab }
                    { favoritesTab }
                    { achievementTab }
                    { adminTab }
                </TabView>
            );
        }else{
            return(
                <TabView activeIndex={activeTab} onTabChange={ e => { setActiveTab(e.index)}}>
                    { submissionsTab }
                    { favoritesTab }
                    { achievementTab }
                </TabView>
            );
        }
    }

    return(
        <Layout>
            <Container>
                <div className='title'>Profile: </div>
                <CustomMessages trigger={showMessage} message={message} />
                {
                    ready ? renderTabs() : <Loading top='100px'/>
                }
            </Container>
        </Layout>
    );
}

export default Profile;
