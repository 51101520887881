import React from "react";
import styled from "styled-components";
import colors from "../../styles/colors";
import ReactTooltip from "react-tooltip";

const StyledChip = styled.div`
  min-width: ${(props) => (props.minWidth ? props.minWidth : "50px")};
  background-color: ${(props) => props.color};
  color: #ffffff;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "11px")};
  cursor: default;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding-top: ${(props) => (props.link ? "3px" : "3px")};
  padding-bottom: ${(props) => (props.link ? "3px" : "3px")};
  padding-left: 3px;
  padding-right: 3px;
  a {
    text-decoration: none;
    color: #ffffff;
    text-align: center;
    disabled: ;
  }
  a.disabled {
    pointer-events: none;
    cursor: default;
  }
  i {
    font-size: 11px;
  }
  opacity: ${(props) => (props.disabled ? "0.5" : "1.0")};
`;

const CustomChip = (props) => {
  const {
    id,
    className,
    label,
    tooltipText,
    tooltipPlace,
    link,
    fontSize,
    minWidth,
    onClick,
    color = colors.hyperlink,
    disabled,
  } = props;

  if (link && link.length > 0) {
    return (
      <React.Fragment>
        <ReactTooltip id={id} />
        <StyledChip
          className={className}
          data-tip={
            disabled
              ? "Sign in or register to access this feature."
              : tooltipText
          }
          data-for={id}
          data-place={tooltipPlace}
          data-effect="solid"
          link
          fontSize={fontSize}
          minWidth={minWidth}
          color={color}
          disabled={disabled}
          onClick={onClick}
        >
          <a
            className={disabled ? "disabled" : ""}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{label}</span>
          </a>
        </StyledChip>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <ReactTooltip />
        <StyledChip
          className={className}
          data-tip={
            disabled
              ? "Sign in or register to access this feature."
              : tooltipText
          }
          data-for={id}
          data-place={tooltipPlace}
          data-effect="solid"
          color={color}
          minWidth={minWidth}
          disabled={disabled}
        >
          <span>{label}</span>
        </StyledChip>
      </React.Fragment>
    );
  }
};

export default CustomChip;
