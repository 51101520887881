import React, { useState } from 'react';
import {TabPanel, TabView} from "primereact/tabview";
import SendNewsletterTab from './AdminComponents/SendNewsletterTab';
import NewsletterHistoryTab from './AdminComponents/NewsletterHistoryTab';

const AdminTab = (props) => {
    const { setMessage,  setShowMessage } = props;
    const [activeTab, setActiveTab] = useState(0);

    return(
        <TabView activeIndex={activeTab} onTabChange={ e => { setActiveTab(e.index)}}>
            <TabPanel header='Send Newsletter'>
                <SendNewsletterTab setMessage={setMessage} setShowMessage={setShowMessage} />
            </TabPanel>
            <TabPanel header='Newsletter History'>
                <NewsletterHistoryTab />
            </TabPanel>
        </TabView>
    )
}

export default AdminTab;