const colors = {
    gray_text: '#666666',
    light_gray: '#999999',
    border_gray: '#cccccc',
    hyperlink: '#0288d1',
    navigation_background: '#e5e5e5',
    button_background_default: '#2196F3',
    form_focused: '#a0d2fa',
    green: '#00b300',
    red: '#b30000',

    // graph colors
    default: '#cccccc', // default node, light gray
    edgeDefault: '#666666', // gray
    origin: '#666666', // dark gray
    datatypeNode: '#0f2080', // navy
    executablePipeline: '#a95aa1', // purple
    executableAnalysis: '#3c9af5', // light blue
    executableHPC: '#a95aa1', // purple
    executableCodeOcean: '#3c9af5', // light blue
    servicePipeline: '#f5793a', // orange
    downstreamNode: "#ff6666", // light red
    webApp: '#3c9af5', // light blue

    focused: '#ff9494', // red

    // bar chart colors
    light_blue: '#a0d2fa',
    medium_blue: '#3c9af5',
    dark_blue: '#1f349c',

    // badge colors
    bronze : '#CD7F32',
    silver : '#C0C0C0',
    gold: '#FFD700',
    platinum: '#E5E4E2',
    diamond: '#b9f2ff'
}



export default colors;
