import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useKeycloak } from "@react-keycloak/web";

const PrivateRoute = ({component: Component, location, ...rest}) => {
    const { keycloak, initialized } = useKeycloak();

    if(!initialized){
        return null;
    } 

    return(
        <Route 
            {...rest}
            render={props => (
                keycloak.authenticated ? 
                <Component {...props} /> 
                : 
                <Redirect to={{pathname: "/", state: { from: location }}} />
            )} 
        />
    );
}

export default PrivateRoute;