import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useKeycloak } from "@react-keycloak/web";
import { Dialog } from 'primereact/dialog';
import CustomButton from '../../../UtilComponents/CustomButton';
import CustomMessages from '../../../UtilComponents/CustomMessages';
import { genericError, addNewToolSuccess } from '../../../../utils/messages';
import ToolForm from '../Forms/ToolForm';
import { StyledBuilderForm } from '../BuildPipelineStyles';
import { invalidExecutableLink } from '../../../../utils/helper';

const AddNewToolDialog = (props) => {
    const {visible, setVisible} = props;
    const { keycloak } = useKeycloak();
    const [newTool, setNewTool] = useState({
        name: '', 
        versions: [], 
        purposes: [{text: '', executableLinks: []}], 
        input: null,
        output: null,
        type: '', 
        link: '', 
        doi: '', 
        user: {name: '', link: ''}, 
        creator: {name: '', link: ''},
    });
    const [inOutOptions, setInOutOptions] = useState([]);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState(null);

    const submitTool = async (e) => {
        e.preventDefault();
        try{
            console.log(newTool);
            await axios.post('/api/data/tool/add', {
                tool: {
                    ...newTool,
                    input: newTool.input.map(item => item.value),
                    output: newTool.output.map(item => item.value)
                },
                email: keycloak.idTokenParsed.email
            });
            setMessage(addNewToolSuccess);
        }catch(err){
            console.log(err);
            setMessage(genericError);
        }finally{
            setShowMessage(Math.random());
        }
    }

    const disableSubmit = () => {
        if(keycloak.tokenParsed.email === 'admin@cobe.ca'){
            return false;
        }
        return (
            newTool.name.length === 0 || 
            newTool.purposes[0].text.length === 0 || 
            invalidPurposes() || 
            newTool.link.length === 0 ||
            !newTool.input ||
            !newTool.output ||
            newTool.type.length === 0
        );
    }

    const invalidPurposes = () => {
        for(const purpose of newTool.purposes){
            if(invalidExecutableLink(purpose.executableLinks)){
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        const initialize = async () => {
            const res = await axios.get('/api/data/tool/newtooloptions');
            let inOutData = res.data.inOutData.map(item => ({label: item, value: item}));
            setInOutOptions(inOutData);
        }
        initialize();
    }, []);

    return(
        <Dialog 
            visible={visible} 
            header='Add a New Tool' 
            onHide={() => {setVisible(false)}}
            style={{width: '50vw'}}
        >
            <StyledBuilderForm>
                <CustomMessages trigger={showMessage} message={message} />
                <ToolForm 
                    tool={newTool} 
                    setTool={setNewTool} 
                    inOutOptions={inOutOptions} 
                />
                <div className='buttonGroup'>
                    <CustomButton 
                        className='p-button-sm p-button-secondary left'
                        label='Reset Tool Form' 
                        onClick={(e) => {
                            e.preventDefault();
                            setNewTool({
                                name: '', 
                                versions: [], 
                                purposes: [{text: '', executableLinks: []}], 
                                input: null,
                                output: null,
                                type: '', 
                                link: '', 
                                doi: ''
                            });
                        }} 
                    />
                    <CustomButton 
                        className='p-button-sm p-button-primary'
                        label='Submit New Tool' 
                        onClick={submitTool} 
                        disabled={disableSubmit()}
                    />
                </div>
            </StyledBuilderForm>
        </Dialog>
    );
}

export default AddNewToolDialog;