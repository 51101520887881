import React from 'react';
import { SubContainer } from '../TutorialStyles';
import YoutubeEmbed from './YoutubeEmbed';

const PipelineBuilderGuide = () => {
    return(
        <SubContainer>
            <h1>Using the Pipeline Builder</h1>
            <div className='section'>
                The <a href='/pipeline-builder'>Pipeline Builder</a> feature enables users to build and submit a new pipeline using an interactive web interface.<br />
                While the feature is designed to provide users with easy, click-and-select experience of building a pipeline, 
                this guide provides additional information to help users better navigate the pipeline builder feature.
            </div>
            <h3>Components of a Pipeline</h3>
            <div className='section'>
                A pipeline is composed of two parts as illustrated in the figure below:
                <ol>
                    <li>Pipeline main branch where the raw data processing takes place.</li>
                    <li>Downstream analysis branches where various analyses are performed using the processed data obtained at the end of the main branch.</li>
                </ol>
                The main branch is typically a sequence of tools that are executed as one entity either or both on HPC (H4H) and CodeOcean capsules.<br />
                The downstream analysis branches can be a single or multiple tools.<br />
                <b>A valid pipeline needs to have at least one downstream analysis.</b>
            </div>
            <div className='section'>
                <img className='embed-img img-small' alt='pipeline structure' src='/images/tutorial/pipeline-structure.png' />
            </div>
            <h3>Steps to Build a Pipeline</h3>
            <div className='section'>
                When building a pipeline using the pipeline builder, users typically take the following steps:
                <ol>
                    <li>Enter required information about your pipeline, such as name and processed data type(s).</li>
                    <li>Build the pipeline decision tree by adding tool nodes and connect them.</li>
                    <li>Add new tools if they are not yet in the compendium of tools in the CoBE database.</li>
                    <li>Define down stream analyses by selecting nodes in the decision tree that belong to the particular analysis.</li>
                </ol>
                The following videos explain each step in detail.
            </div>
            <h3>Enter pipeline information</h3>
            <div className='section video'>
                <YoutubeEmbed embedId='TDgdi1zal_c' />
            </div>
            <h3>Build a pipeline</h3>
            <div className='section video'>
                <YoutubeEmbed embedId='x_5wCOEvodg' />
            </div>
            <h3>Add a new tool</h3>
            <div className='section video'>
                <YoutubeEmbed embedId='yGbX_7u6YUQ' />
            </div>
            <h3>Define downstream analyses</h3>
            <div className='section video'>
                <YoutubeEmbed embedId='li4w3L_qbNc' />
            </div>
        </SubContainer>
    );
}

export default PipelineBuilderGuide;