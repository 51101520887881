import React, {useEffect, useState} from 'react';
import { FaHeart, FaRegHeart} from 'react-icons/fa';
import CustomChip from "./CustomChip";
import axios from "axios";
import styled from "styled-components";
import { css } from "@emotion/react";
import Loader from "react-spinners/MoonLoader";

const Span = styled.span`
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
`;

const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

const LikeButton = (props) => {
    let { id, type, user, disabled} = props;
    type = type === 'tool' ? 'tools' : 'pipelines';
    const [likeStatus, setLikeStatus] = useState(!disabled);
    const [likeNumber,setLikeNumber] = useState(0);
    const [numLikeReady, setNumLikeReady] = useState(false);
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const initialLikeStatus = async () => {
            switch(type) {
                case 'tools':
                    const likedTools = await axios.get(`/api/data/like/executable_likes/tool/${id}`)
                    setLikeNumber(likedTools.data.length);
                    break;
                case 'pipelines':
                    const likedPipelines = await axios.get(`/api/data/like/executable_likes/pipeline/${id}`)
                    setLikeNumber(likedPipelines.data.length);
                    break;
                default:
                    return;
            }
            setNumLikeReady(true);
            let result = {};
            try{
                if(user){
                    const res = await axios.get(`/api/data/like/user-likes`, { params: { email: user ? user.email : null }});
                    result = res.data;
                }
            }catch(e){

            }finally{
                return(result);
            }
        }

        initialLikeStatus().then(res => {
            return res['likes'] ? res['likes'][type] && setLikeStatus(res['likes'][type].filter(item=> item._id === id).length > 0) : false;
        }).then(() => {
            setReady(true)
        });
    }, [id, type, user]);

    const changeLikeStatus = async (id) => {
        const userLikes = await axios.get(`/api/data/like/user-likes`, { params: { email: user ? user.email : null }});
        const {tools, pipelines} = userLikes.data.likes;
        switch(type) {
            case 'tools':
                await axios.get(`/api/data/like/change-like/tool/${id}`, { params: { email: user ? user.email : null }});
                const toolLikes = await axios.get(`/api/data/like/executable_likes/tool/${id}`);
                setLikeNumber(toolLikes.data.length);
                setLikeStatus(!(tools && tools.filter(tool=> tool._id === id).length >0));
                return;
            case 'pipelines':
                await axios.get(`/api/data/like/change-like/pipeline/${id}`, { params: { email: user ? user.email : null }});
                const pipelineLikes = await axios.get(`/api/data/like/executable_likes/pipeline/${id}`);
                setLikeNumber(pipelineLikes.data.length);
                setLikeStatus(!(pipelines && pipelines.filter(pipeline=> pipeline._id === id).length >0));
                return;
            default:
                return;
        }
    }

    return (
        numLikeReady ?
            <Span onClick={() => user? changeLikeStatus(id) : ''}>
            {
                likeStatus && user ?
                <CustomChip
                    label={(ready || !disabled) ? <span><FaHeart/> Like {`(${likeNumber})`}</span> : ''}
                    tooltipText='Remove from favourites'
                    tooltipPlace='right'
                /> :
                <CustomChip
                    label={(ready || !disabled) ? <span><FaRegHeart/> Like {`(${likeNumber})`}</span>: ''}
                    tooltipText='Add to favourites'
                    tooltipPlace='right'
                    disabled={!user}
                />
            }
            </Span>
        :
            <Span>
                <CustomChip
                    label={(ready || !disabled) ? <span><Loader color='white' loading={true} css={override} size={12} /></span> : ''}
                    disabled={!user}
                />
            </Span>
    )
}

export default LikeButton;
