import React from 'react';
import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';
import colors from '../../../../../styles/colors';
import CustomButton from '../../../../UtilComponents/CustomButton';

const StyledNewsletterPreview = styled.div`
    .subject-line {
        font-size: 14px;
        margin-bottom: 15px;
        .label {
            font-weight: bold;
        }
    }
    .content {
        font-size: 14px;
        padding: 5px;
        border: 1px solid ${colors.border_gray};
        border-radius: 5px;
    }
`;

const PreviewDialog = (props) => {
    const { show, onHide, newsletter, sendNewsletter } = props;
    const dialogFooter = (
        <div>
            <CustomButton
                className='p-button-secondary'
                label='Cancel'
                onClick={onHide}
            />
            <CustomButton
                className='p-button-success'
                label='Send'
                onClick={sendNewsletter}
            />
        </div>
    );
    return(
        <Dialog
            style={{width: '800px'}}
            visible={show}
            header={sendNewsletter ? 'Send the following newsletter?' : `Newsletter sent on ${newsletter.date.split('T')[0]}`}
            footer={sendNewsletter ? dialogFooter : undefined}
            onHide={onHide}
        >
            <StyledNewsletterPreview>
                <div className='subject-line'>
                    <span className='label'>Subject: </span><span>{newsletter.subject}</span>
                </div>
                <div className='content' dangerouslySetInnerHTML={{ __html: newsletter.content }} />
            </StyledNewsletterPreview>
        </Dialog>
    );
}

export default PreviewDialog;