import React from 'react';
import { Chips } from 'primereact/chips';
import styled from 'styled-components';

const StyledChipsContainer = styled.div`
    .tooltip {
        margin-top: 2px;
        color: red;
    }
`;

const StyledChips = styled(Chips)`
    font-size: 11px;
    .p-chips-token {
        font-size: 11px;
    }
    .p-chips-input-token {
        input { 
            font-size: 12px;
        }
    }
`;

const CustomChips = (props) => {
    const { className, value, onChange, onRemove, tooltip } = props;
    return(
        <StyledChipsContainer className={className}>
            <StyledChips 
                value={value}
                onChange={onChange}
                allowDuplicate={false}
                onRemove={onRemove}
            />
            {
                tooltip &&
                <div className='tooltip'>
                    {tooltip}
                </div>
            }
        </StyledChipsContainer>
    );
}

export default CustomChips;