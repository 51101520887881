import React, { useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import CustomTable from '../../../UtilComponents/CustomTable';
import CustomButton from '../../../UtilComponents/CustomButton';
import HyperLink from '../../../UtilComponents/HyperLink';
import AddNewToolDialog from '../../BuildPipeline/SubComponents/AddNewToolDialog';
import { StyledTabContent } from './ProfileStyle';
import ShareButton from "../../../UtilComponents/ShareButton";

const SubmisstionTab = (props) => {
    const { initialize, user, setMessage,  setShowMessage, userPipelines, userTools } = props;
    const history = useHistory();
    const [deleteDialog, setDeleteDialog] = useState({show: false, type: '', name: '', id: ''});
    const [addToolVisible, setAddToolVisible] = useState(false);

    const pipelineColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: (item) => (
                <div className='name'>
                    <HyperLink link={`/pipeline/${item.cell.row.original._id}`} text={item.value}/>
                </div>
            )
        },
        {
            Header: 'Data Type',
            accessor: 'datatype',
            Cell: (item) => (
                <span>{item.cell.row.original.datatype.join(', ')}</span>
            )
        },
        {
            Header: 'Manage',
            accessor: '',
            Cell: (item) => (
                <div className='manage'>
                    <CustomButton
                        className="left-button p-button-info p-button-rounded p-button-text"
                        icon='pi pi-pencil'
                        tooltip='Edit this pipeline'
                        disabled={false}
                        onClick={(e) => {history.push(`/edit/pipeline/${item.cell.row.original._id}`)}}
                    />
                    <CustomButton
                        className="p-button-danger p-button-rounded p-button-text"
                        icon='pi pi-times'
                        tooltip='Delete this pipeline'
                        onClick={(e) => {
                            e.preventDefault();
                            setDeleteDialog({show: true, type: 'pipeline', name: item.cell.row.original.name, id: item.cell.row.original._id});
                        }}
                    />
                    <ShareButton type="pipeline" name={item.cell.row.original.name} id={item.cell.row.original._id}/>
                </div>
            )
        },
        {
            Header: 'Verified',
            accessor: 'verified',
            Cell: (item) => (
                <span>{item.cell.row.original.verified ? <span className='verified'>Yes</span> : <span className='notverified'>No</span>}</span>
            )
        }
    ];

    const toolColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: (item) => (
                <div className='name'>
                    <HyperLink link={`/tools/${item.cell.row.original._id}`} text={item.value}/>
                </div>
            )
        },
        {
            Header: 'Manage',
            accessor: '',
            Cell: (item) => (
                <div className='manage'>
                    <CustomButton
                        className="left-button p-button-info p-button-rounded p-button-text"
                        icon='pi pi-pencil'
                        tooltip='Edit this tool'
                        disabled={false}
                        onClick={(e) => {history.push(`/edit/tool/${item.cell.row.original._id}`)}}
                    />
                    <CustomButton
                        className="p-button-danger p-button-rounded p-button-text"
                        icon='pi pi-times'
                        tooltip='Delete this tool'
                        onClick={(e) => {
                            e.preventDefault();
                            setDeleteDialog({show: true, type: 'tool', name: item.cell.row.original.name, id: item.cell.row.original._id});
                        }}
                    />
                    <ShareButton type="tool" name={item.cell.row.original.name} id={item.cell.row.original._id}/>
                </div>
            )
        },
    ];

    const dialogFooter = (
        <div>
            <CustomButton
                className='p-button-danger'
                icon="pi pi-times"
                label='Yes, delete'
                onClick={(e) => {
                    e.preventDefault();
                    deleteItem(deleteDialog.type, deleteDialog.id);
                }}
            />
            <CustomButton
                className='p-button-secondary'
                label='No, cancel'
                onClick={(e) => {
                    e.preventDefault();
                    hideDeleteDialog();
                }}
            />
        </div>
    );

    const hideDeleteDialog = () => {
        setDeleteDialog({show: false, type: '', name: '', id: ''});
    }

    const deleteItem = async (type, id) => {
        const res = await axios.post(`/api/data/${type}/delete`, {id: id, email: user.email});
        if(res.status === 200){
            setMessage({
                severity: `${res.data.pipelines ? 'warn' : 'success'}`,
                summary: res.data.message,
                detail: `${res.data.pipelines ? res.data.pipelines.join(', ') : ''}`,
                sticky: true
            });
        }else{
            setMessage({
                severity: 'error',
                summary: res.data.message,
                sticky: true
            });
        }
        setShowMessage(Math.random());
        setDeleteDialog({show: false, type: '', name: '', id: ''});
        initialize();
    };

    return(
        <React.Fragment>
            <StyledTabContent>
                <div className='table-container'>
                    <div className='table left'>
                        <div className='sub-title'>Pipelines Submitted by { user.email === 'admin@cobe.ca' ? 'all Users' : 'the User'}</div>
                        {
                            userPipelines.length > 0 ?
                                <CustomTable
                                    columns={pipelineColumns}
                                    data={userPipelines}
                                    datatype='pipeline(s)'
                                    showSearch={true}
                                />
                                :
                                <div className='sub-title'>No pipelines have been submitted by this user.</div>
                        }
                    </div>
                    <div className='table'>
                        <div className='sub-title'>
                            <span className='sub-title-text'>Tools Submitted by { user.email === 'admin@cobe.ca' ? 'all Users' : 'the User'}</span>
                            <CustomButton
                                label='Add New Tool'
                                onClick={(e) => {setAddToolVisible(true)}}
                                className='left p-button-sm p-button-primary'
                                tooltip='Add a new tool.'
                            />
                        </div>
                        {
                            userTools.length > 0 ?
                                <CustomTable
                                    columns={toolColumns}
                                    data={userTools}
                                    datatype='tool(s)'
                                    showSearch={true}
                                />
                                :
                                <div className='sub-title'>No tools have been submitted by this user.</div>
                        }
                        <AddNewToolDialog
                            visible={addToolVisible}
                            setVisible={setAddToolVisible}
                        />
                    </div>
                </div>
            </StyledTabContent>
            <Dialog
                visible={deleteDialog.show}
                header={`Would you like to delete ${deleteDialog.name}?`}
                footer={dialogFooter}
                onHide={hideDeleteDialog}
            >
            </Dialog>
        </React.Fragment>
    );
}

export default SubmisstionTab;
