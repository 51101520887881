import React from 'react';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';

const StyledReactSelect = styled(CreatableSelect)`
    &.Select--multi {
        padding: 0.5rem 0.5rem;
    }
`;

const CustomCreatableMultiSelect = (props) => {
    const { className, options, value, onChange, placeholder } = props;
    return(
        <StyledReactSelect 
            className={className}
            isMulti={true}
            options={options}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
        />
    );
}

export default CustomCreatableMultiSelect;