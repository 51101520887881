import React from 'react';
import CustomDropdown from '../../../UtilComponents/CustomDropdown';
import CustomInputText from '../../../UtilComponents/CustomInputText';
import RequiredField from '../../../UtilComponents/RequiredField';

const UserForm = (props) => {

    const { user, index, userOptions, pipelineUsers, setPipeline, isEdit } = props;

    const onUserNameChange = (name) => {
        if(isEdit){
            let users = [...pipelineUsers];
            users[index] = {
                ...users[index],
                name: name
            };
            setPipeline((prev) => ({
                ...prev,
                user: users
            }));
        }else{
            setPipeline((prev) => ({
                ...prev,
                user: {...prev.user, name: name}
            }));
        }
    };

    const onUserLinkChange = (link) => {
        if(isEdit){
            let users = [...pipelineUsers];
            users[index] = {
                ...users[index],
                link: link
            };
            setPipeline((prev) => ({
                ...prev,
                user: users
            }));
        }else{
            setPipeline((prev) => ({
                ...prev,
                user: {...prev.user, link: link}
            }));
        }
    };

    return(
        <React.Fragment>
            <div className='formRow'>
                <span className='label'>Lab Group / Institution<RequiredField />: </span>
                <CustomDropdown 
                    className='input'
                    value={user.name}
                    options={userOptions}
                    onChange={(e) => {onUserNameChange(e.value)}}
                    placeholder='Select or enter a lab group or institution this pipeline belongs to.'
                    filter={true}
                    editable
                />
            </div>
            {
                user.name.length > 0 && !userOptions.map(item => item.value).includes(user.name) &&
                <div className='formRow'>
                    <span className='label'>Link to lab group / institution website<RequiredField />: </span>
                    <CustomInputText 
                        className='input'
                        type='text'
                        value={user.link}
                        onChange={(e) => {onUserLinkChange(e.target.value)}}
                        width='80%'
                    />
                </div>
            }
        </React.Fragment>
    );
}

export default UserForm;