import styled from 'styled-components';

export const Container = styled.div`
    width: 90%;
    .header {
        width: 100%;
        display: flex;
        align-items: center;
        height: 60px;
        padding-top: 24px;
        padding-bottom: 24px;
        .title {
            font-size: 20px;
            font-weight: bold;
            margin-right: 20px;
        }
    }
`;