import React from 'react';
import styled from 'styled-components';
import { AutoComplete } from 'primereact/autocomplete';
import colors from '../../styles/colors';

const StyledAutoComplete = styled(AutoComplete)`
    width: 100%;
    .p-inputtext {
        width: 100%;
        .p-autocomplete-input-token input {
            font-size: 12px;
            color: ${colors.gray_text};
        }
    }
    .p-autocomplete-panel {
        font-size: 12px;
        color: ${colors.gray_text};
    }
`;

const CustomAutoComplete = (props) => {
    const { className, value, suggestions, completeMethod, onChange, onKeyPress, placeholder, forceSelection } = props;
    return(
        <StyledAutoComplete 
            className={className}
            field='name'
            value={value}
            suggestions={suggestions}
            onChange={onChange}
            onKeyPress={onKeyPress}
            completeMethod={completeMethod}
            placeholder={placeholder}
            forceSelection={forceSelection}
        />
    );
}

export default CustomAutoComplete;