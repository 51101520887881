import React from 'react';
import CustomButton from './CustomButton';
import axios from 'axios';

const DownloadButton = (props) => {

    const { filename, tooltip } = props;

    const downloadFile = async () => {
        const res = await axios.get(
            '/api/data/file/download', 
            { 
                params: { filename: filename },
                responseType: 'blob' 
            }
        );
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }

    return(
        <CustomButton 
            label='Pipeline Submission Template'
            className='p-button-text'
            icon='pi pi-download'
            tooltip={tooltip}
            onClick={downloadFile}
        />
    );
}

export default DownloadButton;