import React from 'react';
import CustomInputText from '../../../UtilComponents/CustomInputText';
import CustomCreatableMultiSelect from '../../../UtilComponents/CustomCreatableMultiSelect';
import CustomChips from '../../../UtilComponents/CustomChips';
import CustomDropdown from '../../../UtilComponents/CustomDropdown';
import RequiredField from '../../../UtilComponents/RequiredField';
import ToolPurposeForm from './ToolPurposeForm';
import { nodetypeOptions } from '../../../../utils/constants';

/**
 * The style is applied from the StyledBuilderForm component.
 * @param {*} props 
 * @returns 
 */
const ToolForm = (props) => {
    const { tool, setTool, inOutOptions } = props;
    return(
        <React.Fragment>
            <div className='formRow'>
                <span className='label'>Tool Name<RequiredField/>: </span>
                <CustomInputText 
                    className='input'
                    type='text'
                    value={tool.name}
                    onChange={(e) => {setTool({...tool, name: e.target.value})}}
                    width='80%'
                />
            </div>
            <div className='formRow'>
                <span className='label'>Tool Type: </span>
                <CustomDropdown 
                    className='input dropdown'
                    value={tool.type}
                    options={nodetypeOptions} 
                    onChange={(e) => {
                        setTool(prev => ({...prev, type: e.value}))
                    }} 
                    placeholder={'Select...'}
                    filter={true}
                />
            </div>
            <div className='formRow'>
                <span className='label'>Version(s): </span>
                <CustomChips
                    className='input' 
                    value={tool.versions} 
                    onChange={(e) => {
                        setTool(prev => ({...prev, versions: [...new Set(prev.versions.concat(e.value))]}))
                    }} 
                    onRemove={(e) => {
                        setTool(prev => {
                            let versions = [...prev.versions].filter(item => !e.value.includes(item));
                            return({
                                ...prev,
                                versions: versions
                            })
                        })
                    }}
                    tooltip="Input and press ENTER to add a version."
                />
            </div>
            <div className='formRow'>
                <span className='label'>Purpose<RequiredField/>: </span>
                <div className='multi-line-field'>
                    {
                        tool.purposes.map((purpose, i) => (
                            <ToolPurposeForm 
                                key={i} 
                                purpose={purpose} 
                                index={i}
                                tool={tool}
                                setTool={setTool}
                                isEdit={true}
                            />
                        ))
                    }
                </div> 
            </div>
            <div className='formRow'>
                <span className='label'>Input<RequiredField/>: </span>
                <CustomCreatableMultiSelect 
                    className='input'
                    options={inOutOptions}
                    value={tool.input}
                    onChange={(newValue) => {setTool({...tool, input: newValue})}}
                    width='80%'
                    placeholder='Select or enter input data type(s)'
                />
            </div>
            <div className='formRow'>
                <span className='label'>Output<RequiredField/>: </span>
                <CustomCreatableMultiSelect 
                    className='input'
                    options={inOutOptions}
                    value={tool.output}
                    onChange={(newValue) => {setTool({...tool, output: newValue})}}
                    width='80%'
                    placeholder='Select or enter output data type(s)'
                />
            </div>
            <div className='formRow'>
                <span className='label'>Link to tool info<RequiredField/>: </span>
                <CustomInputText 
                    className='input'
                    type='text'
                    value={tool.link}
                    onChange={(e) => {setTool({...tool, link: e.target.value})}}
                    width='80%'
                />
            </div>
            <div className='formRow'>
                <span className='label'>DOI: </span>
                <CustomInputText 
                    className='input'
                    type='text'
                    value={tool.doi}
                    onChange={(e) => {setTool({...tool, doi: e.target.value})}}
                    width='80%'
                />
            </div>
        </React.Fragment>
    );
}

export default ToolForm;