import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useKeycloak } from "@react-keycloak/web";
import { PipelineBuilderContext } from '../../../hooks/Contexts';
import Layout from '../../UtilComponents/Layout';
import Loading from '../../UtilComponents/Loading';
import CustomButton from '../../UtilComponents/CustomButton';
import CustomMessages from '../../UtilComponents/CustomMessages';
import { Dialog } from 'primereact/dialog';
import { submitPipelineSuccess, submitPipelineError } from '../../../utils/messages';
import { 
    StyledBuildPipeline,
    StyledWarningDialog, 
    StyledBuilderHeader
} from './BuildPipelineStyles';
import PipelineBuilder from './SubComponents/PipelineBuilder';
import { invalidExecutableLink } from '../../../utils/helper';

const BuildPipeline = () => {
    const { keycloak } = useKeycloak();

    if(!keycloak.authenticated){
        keycloak.login();
    }

    const [viewReady, setViewReady] = useState(false);
    const [pipeline, setPipeline] = useState({
        name: '',
        datatype: [],
        version: '',
        link: '',
        user: {name: '', link: ''},
        main: {
            executableLinks: [],
            nodes: []
        },
        subBranches: [],
        purposes: []
    });
    const [graphData, setGraphData] = useState({
        data: { nodes: [], links: [] },
        tools: [],
        filtered: false,
        ready: false
    });
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState(null);
    const [warningDialog, setWarningDialog] = useState({show: false, warnings: []});

    const submitPipeline = async (e) => {
        e.preventDefault();
        let warnings = checkSubmission(pipeline);
        if(warnings.length > 0){
            setWarningDialog({show: true, warnings: warnings});
        }else{
            const res = await axios.post('/api/data/pipeline/add', {
                    pipeline: {
                        ...pipeline, 
                        graphLinks: graphData.data.links
                    },
                    email: keycloak.idTokenParsed.email
                });
            if(res.status === 200){
                setMessage(submitPipelineSuccess);
            }else{
                setMessage(submitPipelineError);
            }
            setShowMessage(Math.random());
        }
    }

    const checkSubmission = (pipeline) => {
        let submissionWarnings = [];
        if(pipeline.name.length === 0 ||  pipeline.link.length === 0 || pipeline.user.name.length === 0){
            submissionWarnings.push('All the required fields are filled out.');
        }
        if(pipeline.main.nodes.length < 3){
            submissionWarnings.push('Have at least one tool in the data processing component of your pipeline.');
        }
        if(pipeline.purposes.length === 0){
            submissionWarnings.push('Add at least one downstream analysis for your pipeline by going to "Define/remove downstream analyses."');
        }
        if(invalidExecutableLink(pipeline.main.executableLinks)){
            submissionWarnings.push('Add a link to a GitHub repository (for scripts to be executed on HPC) or a CodeOcean capsule as an executable link.');
        }
        return(submissionWarnings);
    }

    useEffect(() => {
        const initialize = async () => {
            const res = await axios.get('/api/view/pipeline-builder/initialize-view', { params: { email: keycloak.idTokenParsed.email }});
            if(res.data){
                setPipeline({
                    ...res.data.pipeline, 
                    changeGraph: true
                });
                setGraphData({ 
                    ...graphData, 
                    data: {
                        nodes: res.data.graph.nodes,
                        links: res.data.graph.links
                    }, 
                    tools: res.data.graph.tools ? res.data.graph.tools : [],
                    filtered: false,
                    ready: true
                });
            }
            setViewReady(true);
        };
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const saveDraft = async () => {
            await axios.post('/api/view/pipeline-builder/save-draft', {
                pipeline: pipeline,
                graph: {
                    nodes: graphData.data.nodes,
                    links: graphData.data.links,
                    tools: graphData.tools
                },
                email: keycloak.idTokenParsed.email
            });
        }
        if(graphData.ready){
            saveDraft();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphData]);

    const dialogFooter = (
        <div>
            <CustomButton
                className='p-button-secondary'
                label='OK'
                onClick={(e) => {
                    e.preventDefault();
                    setWarningDialog({show: false, warnings: []});
                }}
            />
        </div>
    );

    return(
        <Layout>
            {
                viewReady ? 
                <StyledBuildPipeline>
                    <CustomMessages trigger={showMessage} message={message} />
                    <StyledBuilderHeader>
                        <CustomButton 
                            label='Submit for Verification' 
                            onClick={submitPipeline} 
                            className='p-button-sm p-button-success'
                            disabled={false}
                            tooltip='Submit your pipeline to CoBE.'
                        />
                    </StyledBuilderHeader>
                    <PipelineBuilderContext.Provider value={{ pipeline, setPipeline, graphData, setGraphData, isEdit: false }}>
                        <PipelineBuilder />       
                    </PipelineBuilderContext.Provider>
                    <Dialog
                        visible={warningDialog.show}
                        header='Your pipeline is not valid for submission'
                        footer={dialogFooter}
                        onHide={(e) => {setWarningDialog({show: false, warnings: []})}}
                        style={{width: '40vw', minWidth: '400px'}}
                    >
                        <StyledWarningDialog>
                            Please make sure that the following requirements are satisfied before submission:
                            <ul>
                                {
                                    warningDialog.warnings.map((warning, i) => (
                                        <li key={i}>{warning}</li>
                                    ))
                                }
                            </ul>
                            For more information about building your pipeline and how CoBE defines a valid pipeline, <br />
                            go to <a href='/tutorial?section=builder'>Using the Pipeline Builder</a>
                        </StyledWarningDialog>
                    </Dialog>
                </StyledBuildPipeline>
                :
                <Loading />
            }
        </Layout>
    );
}

export default BuildPipeline;