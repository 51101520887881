import React from 'react';
import ReactTooltip from "react-tooltip";
import styled from 'styled-components';

const StyledTooltip = styled(ReactTooltip)`
    width: ${props => props.width ? props.width : '250px'};
    font-size: 12px;
`;

const CustomTooltip = (props) => {
    const { id, tooltip, place, effect, width} = props;
    return(
        <StyledTooltip 
            id={id} 
            place={place ? place : "right"} 
            effect={effect ? effect : "solid"}
            width={width}
        >
            {tooltip}
        </StyledTooltip>
    );
}

export default CustomTooltip;