import React from 'react';
import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';
import colors from '../../../../../styles/colors';
import CustomTable from '../../../../UtilComponents/CustomTable';

const RemoveBtn = styled.button`
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
    color: ${colors.red};
    i {
        font-size: 11px;
    }
`;

const RecipientsDialog = (props) => {
    const { onHide, show, recipients, removeRecipient } = props;

    const columns = [
        {
            Header: 'Email',
            accessor: 'email'
        }
    ];
    if(removeRecipient){
        columns.push({
            Header: 'Remove',
            accessor: '',
            Cell: (item) => (
                <RemoveBtn onClick={() => {removeRecipient(item.cell.row.original._id)}}>
                    <i className='pi pi-times'></i>
                </RemoveBtn>
            )
        })
    }
    return(
        <Dialog
            style={{width: '400px'}}
            visible={show}
            header={`${recipients.length} recipients for this newsletter`}
            onHide={onHide}
        >
            <CustomTable
                columns={columns}
                data={recipients}
                datatype=''
                showSearch={true}
                pageSize={20}
            />
        </Dialog>
    );
}

export default RecipientsDialog;