const genericSuccessMessage = {
    severity: 'success', 
    summary: 'Success', 
    detail: ''
}

const genericErrorMessage = {
    severity: 'error', 
    summary: 'Error', 
    detail: '', 
}

const signedOut = { 
    severity: 'error', 
    summary: 'Signed Out', 
    detail: 'Please signin to access this page.', 
    sticky: true 
};

const pwdResetErrorMessage = {
    severity: 'error', 
    summary: 'Error', 
    detail: 'A servierside error occurred', 
    sticky: true 
};

const pwdResetSuccessMessage = {
    severity: 'success', 
    summary: 'Password Reset', 
    detail: 'Your password has been reset.', 
    sticky: true 
};

const genericError = { 
    severity: 'error', 
    summary: 'Error occured', 
    detail: 'Please try again.', 
    sticky: false 
};

const addAnalysisSuccess = { 
    severity: 'success', 
    summary: 'Analysis Added', 
    detail: 'Please add another analysis or submit the pipeline', 
    sticky: false 
};

const addAnalysisWarning = { 
    severity: 'warn', 
    summary: 'Analysis already exists', 
    detail: 'The analysis you are trying to add already exists', 
    sticky: false 
};

const removeAnalysisSuccess = { 
    severity: 'success', 
    summary: 'Analysis has been removed', 
    detail: 'Please add another analysis or submit the pipeline', 
    sticky: false 
};

const addNewToolSuccess = {
    severity: 'success', 
    summary: 'Tool Added', 
    detail: 'Please add the tool node to your pipeline', 
    sticky: true
}

const addLinkWarning = {
    severity: 'warn', 
    summary: 'Link already exists', 
    detail: 'The link you are trying to add already exists', 
    sticky: false 
}

const removeLinkWarning = {
    severity: 'warn', 
    summary: 'Link does not exist', 
    detail: 'The link you are trying to remove does not exist', 
    sticky: false 
}

const submitPipelineSuccess = { 
    severity: 'success', 
    summary: 'Pipeline Submitted', 
    detail: 'Your pipeline has been submitted for verification', 
    sticky: true 
};

const submitPipelineError = { 
    severity: 'error', 
    summary: 'Error', 
    detail: 'Pipeline could not be submitted due to server error', 
    sticky: true 
};

const editSuccess = {
    severity: 'success', 
    summary: 'Changes Submitted', 
    detail: 'Your changes have been submitted', 
    sticky: true 
};

const editError = {
    severity: 'error', 
    summary: 'Error', 
    detail: 'Changes could not be submitted due to server error', 
    sticky: true 
};

const pipelineVerifySuccess = {
    severity: 'success', 
    summary: 'Pipeline Verified', 
    detail: 'This pipeline is now publicly accessible', 
    sticky: false 
};

export {
    genericSuccessMessage,
    genericErrorMessage,
    signedOut,
    pwdResetErrorMessage,
    pwdResetSuccessMessage,
    genericError,
    addNewToolSuccess,
    addAnalysisSuccess,
    addAnalysisWarning,
    removeAnalysisSuccess,
    addLinkWarning,
    removeLinkWarning,
    submitPipelineSuccess,
    submitPipelineError,
    editSuccess,
    editError,
    pipelineVerifySuccess
}