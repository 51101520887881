import React from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import colors from '../../styles/colors';

const Container = styled.div`
    width: 100%;
    height: 100%;
    margin-top: ${ props => props.top ? props.top : '0px'};
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important;
`;

const Loading = (props) => {
    const { top } = props;
    return(
        <Container top={top}>
            <ReactLoading
                type="bubbles"
                color={colors.light_gray}
                height={160}
                width={160}
            />
        </Container>
    );
}

export default Loading;