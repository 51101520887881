import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { useKeycloak } from "@react-keycloak/web";

// used to display loading spinner
import PuffLoader from "react-spinners/PuffLoader";
import { css } from "@emotion/react";
import { TabPanel, TabView } from "primereact/tabview";
import { Checkbox } from "primereact/checkbox";
import CustomAutoComplete from "../../../UtilComponents/CustomAutoComplete";
import CustomButton from "../../../UtilComponents/CustomButton";
import Disclaimer from "../../../UtilComponents/Disclaimer";
import colors from "../../../../styles/colors";

import ToolTable from "./ToolTable";
import PipelineTable from "./PipelineTable";

const StyledSearch = styled.div`
  width: 50%;
  max-width: 550px;
  min-width: 350px;
  .title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;
  }
  .input {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin-bottom: 15px;
    .left {
      margin-right: 5px;
    }
    .label {
      font-size: 12px;
    }
  }
  .slider {
    width: 200px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .parameters {
    display: flex;
    margin-top: 10px;
    .parameter {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-right: 15px;
      .label {
        color: ${colors.gray_text};
        margin-right: 5px;
      }
    }
  }
  .searching {
    margin-top: 30px;
    font-size: 16px;
  }
`;

const SearchResult = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  margin-bottom: 30px;
  .title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-right: 20px;
  }
  .p-tabview {
    min-width: 100%;
  }
`;

const override = css`
  display: block;
  margin: 0 auto;
`;

const PipelineSearch = () => {
  const [question, setQuestion] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [searchResult, setSearchResult] = useState({
    tools: [],
    pipelines: [],
  });
  const [searching, setSearching] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  // const [showServicePipelinesOnly, setShowServicePipelinesOnly] = useState(false);
  const [showPipelineToolsOnly, setShowPipelineToolsOnly] = useState(false);
  const history = useHistory();
  const { keycloak } = useKeycloak();

  const getSuggestion = async (e) => {
    const res = await axios.get(`/api/data/search/suggestions`, {
      params: { query: e.query },
    });
    setSuggestions(res.data);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      search(e);
    }
  };

  const search = async (e) => {
    e.preventDefault();
    setSearchResult(false);
    setSearching(true);
    setActiveTab(0);
    const res = await axios.get(`/api/data/search`, {
      params: {
        question: typeof question === "string" ? question : question.name,
        servicePipelinesOnly: false,
        pipelineToolsOnly: showPipelineToolsOnly,
        email: keycloak.authenticated ? keycloak.idTokenParsed.email : null,
      },
    });
    console.log(res.data);
    setSearching(false);
    setSearchResult({
      ready: true,
      tools: res.data.tools,
      pipelines: res.data.pipelines,
    });
  };

  /**
   * Handles when browser back button is pressed, so that
   * the search results are retained.
   */
  useEffect(() => {
    if (history.action === "REPLACE") {
      if (history.location.state.search) {
        setQuestion(history.location.state.search.question);
        setSearchResult(history.location.state.search.results);
        setActiveTab(history.location.state.search.tab);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <StyledSearch>
        {/* <div className='title'>Find a tool or a pipeline</div> */}
        <div className="input">
          <CustomAutoComplete
            className="left"
            value={question}
            suggestions={suggestions}
            completeMethod={getSuggestion}
            onChange={(e) => {
              setQuestion(e.value);
            }}
            onKeyPress={onKeyPress}
            placeholder="Enter a question (ex. How to find chromatin loops?)"
          />
          {searching ? (
            <PuffLoader
              color={colors.hyperlink}
              loading={searching}
              css={override}
              size={30}
            />
          ) : (
            <CustomButton
              className="left"
              icon="pi pi-search"
              onClick={search}
              fontSize="14px"
              disabled={question.length === 0}
            />
          )}
        </div>
        <div className="parameters">
          <div className="parameter">
            <span className="label">Show pipeline tools only: </span>
            <Checkbox
              onChange={(e) => {
                setShowPipelineToolsOnly(e.checked);
              }}
              checked={showPipelineToolsOnly}
              tooltip="Show tools that are part of a pipeline"
            />
          </div>
        </div>
        {searching && (
          <div className="searching">Searching, please wait...</div>
        )}
      </StyledSearch>
      {searchResult.ready ? (
        searchResult.tools.length === 0 &&
        searchResult.pipelines.length === 0 ? (
          <div className="title">No results</div>
        ) : (
          <React.Fragment>
            <SearchResult>
              <Disclaimer />
            </SearchResult>
            <SearchResult>
              {searchResult.pipelines.length > 0 &&
              searchResult.tools.length > 0 ? (
                <TabView
                  activeIndex={activeTab}
                  onTabChange={(e) => {
                    setActiveTab(e.index && 1);
                  }}
                >
                  <TabPanel
                    header={` ${searchResult.pipelines.length} Pipeline(s) Found`}
                  >
                    <PipelineTable
                      pipelines={searchResult.pipelines}
                      tools={searchResult.tools}
                      search={{
                        question: question,
                        results: searchResult,
                        tab: activeTab,
                      }}
                    />
                  </TabPanel>
                  <TabPanel
                    header={` ${searchResult.tools.length} Tool(s) Found`}
                    styleClass="p-react-panel"
                  >
                    <ToolTable
                      className="results"
                      tools={searchResult.tools}
                      search={{
                        question: question,
                        results: searchResult,
                        tab: activeTab,
                      }}
                    />
                  </TabPanel>
                </TabView>
              ) : searchResult.pipelines.length > 0 &&
                searchResult.tools.length === 0 ? (
                <TabView>
                  <TabPanel
                    header={` ${searchResult.pipelines.length} Pipeline(s) Found`}
                  >
                    <PipelineTable
                      pipelines={searchResult.pipelines}
                      tools={searchResult.tools}
                      search={{
                        question: question,
                        results: searchResult,
                        tab: activeTab,
                      }}
                    />
                  </TabPanel>
                </TabView>
              ) : (
                <TabView>
                  <TabPanel
                    header={` ${searchResult.tools.length} Tool(s) Found`}
                  >
                    <ToolTable
                      className="results"
                      tools={searchResult.tools}
                      search={{
                        question: question,
                        results: searchResult,
                        tab: activeTab,
                      }}
                    />
                  </TabPanel>
                </TabView>
              )}
            </SearchResult>
          </React.Fragment>
        )
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default PipelineSearch;
