import React, {useEffect, useState} from "react";
import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";
import RequiredField from "./RequiredField";
import CustomCreatableMultiSelect from "./CustomCreatableMultiSelect";
import axios from "axios";
import colors from "../../styles/colors";
import CustomButton from "./CustomButton";

const StyledPreviewDialog = styled.div`
  .subject-line {
    font-size: 14px;
    margin-bottom: 15px;
    font-weight: bold;
  }
  .content {
    font-size: 14px;
    padding: 5px;
    border: 1px solid ${colors.border_gray};
    border-radius: 5px;
    white-space: pre-line;
    overflow-y: auto;
    max-height: 200px;
    word-break: break-word;
  }
`;

const PreviewDialog = (props) => {
  const { title, body, showPreviewDialog, hidePreview,confirmSubmit} = props;
  const dialogFooter = (
    <div>
      <CustomButton className='p-button-secondary' label='Cancel' onClick={hidePreview}/>
      <CustomButton className='p-button-success' label='Send' onClick={confirmSubmit} />
    </div>
  );

  return (
    <Dialog
      style={{width: '550px'}}
      visible={showPreviewDialog}
      header='Preview your submission'
      footer={ dialogFooter }
      onHide={hidePreview}
    >
      <StyledPreviewDialog>
        <div className='subject-line'>
          <span className='label'>{title}</span>
        </div>
        <div className='content' dangerouslySetInnerHTML={{ __html: body.replace(/\n/g, '<br>').replace(/ /g, '&nbsp;')  }} />
      </StyledPreviewDialog>
    </Dialog>
  )
}

const PopupContainer = styled.div`
  .popup-content {
    padding: 20px;
    background-color: white;
    border-radius: 5px;
  }

  .form-inputs {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
  }

  .form-input-title {
    font-weight: bold;
    margin-top: 0.5rem;
  }

  .form-input-container {
    margin-bottom: 0.5rem;
  }
`;

export function useFormValidation(initialState, submitCallback) {
  const [fields, setFields] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (name, value) => {
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const handleSubmit = async () => {
    const optional = ['link', 'dataType', 'submitter', 'email'];
    const requiredFields = Object.entries(fields).filter(([key, value]) => !optional.includes(key));
    if (requiredFields.every(([key, value]) => !['', null].includes(value))) {
      try {
        await submitCallback(fields);
        setFields(initialState);
        setErrorMessage('');
      } catch (error) {
        console.error('Error submitting:', error);
        setErrorMessage('An error occurred while submitting.');
      }
    } else {
      setErrorMessage('Please fill in all required fields.');
    }
  };

  return {
    fields,
    errorMessage,
    handleChange,
    handleSubmit,
  };
}

export function generateBody(props) {
  const { dataType, description, link, submitter, email } = props;
  let issueBody = '';

  if (dataType) {
    issueBody += `Data type: ${dataType.map(item => item.value).join(',')}\n\n`;
  }

  if (description) {
    issueBody += `${description}\n\n`;
  }

  if (link) {
    issueBody += `Additional information link: ${link}\n\n`;
  }

  if (submitter) {
    issueBody += `Submitted by: ${submitter}\n\n`;
  }

  if (email) {
    issueBody += `Email address: ${email}\n\n`;
  }
  return issueBody;
}


const PipelineRequestForm = (props) => {
  const { visible, onHide } = props;
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);
  const [datatypeOptions, setDatatypeOptions] = useState([]);

  const initialState = {
    title: '',
    dataType: null,
    description: '',
    link: '',
    submitter: '',
    email: '',
  };

  const { fields, errorMessage, handleChange, handleSubmit } = useFormValidation(
    initialState,
    async (data) => {

      onHide();

      const issueData = {
        title: `New Pipeline Request- ${data.title}`,
        body: generateBody(data),
      };

      try {
        await axios.post('/api/issues/submit', {
          issueData: issueData,
          tags: ['enhancement', 'help wanted']
        });

      } catch (error) {
        console.error('Error submitting pipeline request:', error);
      }
    }
  );

  const showPreview = () => {
    setShowPreviewDialog(true);
  };

  const hidePreview = () => {
    setShowPreviewDialog(false);
  };

  const confirmSubmit = async () => {
    await handleSubmit();
    hidePreview();
  };

  useEffect(() => {
    if (datatypeOptions.length > 0) {
      if (fields.dataType === null) {
        handleChange('dataType', []);
      }
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      const res = await axios.get('/api/view/decision-tree');
      if(res && res.data) setDatatypeOptions(res.data.datatypeOptions);
    }
    getData();
  }, []);

  return (
    <>
      <Dialog
        visible={visible}
        header="Pipeline Request"
        onHide={onHide}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '30vw' }}
      >
        <PopupContainer>
          <div className="popup-content">
            <div className="form-inputs">
              <span className="form-input-title">Request Title<RequiredField />:</span>
              <InputText
                id="pipelineTitle"
                value={fields.title}
                onChange={(e) => handleChange('title', e.target.value)}
              />

              <span className="form-input-title">Processed Data Type:</span>
              <CustomCreatableMultiSelect
                className='input'
                options={datatypeOptions}
                value={fields.dataType}
                onChange={(e) => handleChange('dataType', e)}
                placeholder='Select or enter data type(s)'
              />

              <span className="form-input-title">Request Description<RequiredField />:</span>
              <InputTextarea
                id="pipelineDescription"
                rows={5}
                value={fields.description}
                onChange={(e) => handleChange('description', e.target.value)}
              />

              <span className="form-input-title">Additional Information URL:</span>
              <InputText
                id="pipelineLink"
                value={fields.link}
                onChange={(e) => handleChange('link', e.target.value)}
              />

              <span className="form-input-title">Your Name:</span>
              <InputText
                id="submitterName"
                value={fields.submitter}
                onChange={(e) => handleChange('submitter', e.target.value)}
              />

              <span className="form-input-title">Your Email Address:</span>
              <InputText
                id="submitteremail"
                value={fields.email}
                onChange={(e) => handleChange('email', e.target.value)}
              />
            </div>

            <div className="form-actions">
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              <Button label="Preview" onClick={showPreview} />
            </div>
          </div>
        </PopupContainer>
      </Dialog>
      <PreviewDialog
        title = {"New Pipeline Request- " + fields.title}
        body={generateBody(fields)}
        showPreviewDialog={showPreviewDialog}
        hidePreview={hidePreview}
        confirmSubmit={confirmSubmit}
        />
    </>
  );
};

const BugReportForm = (props) => {
  const { visible, onHide } = props;
  const [showPreviewDialog, setShowPreviewDialog] = useState(false);

  const initialState = {
    title: '',
    description: '',
    submitter: '',
    email: '',
  };

  const { fields, errorMessage, handleChange, handleSubmit } = useFormValidation(
    initialState,
    async (data) => {
      const { title, description, submitter, email } = data;

      onHide();
      const issueData = {
        title: `Bug Report- ${title}`,
        body: generateBody(data),
      };

      try {
        await axios.post('/api/issues/submit', {
          issueData: issueData,
          tags: ['bug', 'invalid']
        });
      } catch (error) {
        console.error('Error submitting bug report:', error);
      }
    }
  );

  const showPreview = () => {
    setShowPreviewDialog(true);
  };

  const hidePreview = () => {
    setShowPreviewDialog(false);
  };

  const confirmSubmit = async () => {
    await handleSubmit();
    hidePreview();
  };

  return (
    <>
      <Dialog
        visible={visible}
        header="Bug Report"
        onHide={onHide}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '30vw' }}
      >
        <PopupContainer>
          <div className="popup-content">
            <div className="form-inputs">
              <span className="form-input-title">Report Title<RequiredField />:</span>
              <InputText
                id="title"
                value={fields.title}
                onChange={(e) => handleChange('title', e.target.value)}
              />

              <span className="form-input-title">Bug Description<RequiredField />:</span>
              <InputTextarea
                id="description"
                rows={5}
                value={fields.description}
                onChange={(e) => handleChange('description', e.target.value)}
              />

              <span className="form-input-title">Your Name:</span>
              <InputText
                id="submitterName"
                value={fields.submitter}
                onChange={(e) => handleChange('submitter', e.target.value)}
              />

              <span className="form-input-title">Your Email Address:</span>
              <InputText
                id="submitteremail"
                value={fields.email}
                onChange={(e) => handleChange('email', e.target.value)}
              />
            </div>
            <div className="form-actions">
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              <Button label="Preview" onClick={showPreview} />
            </div>
          </div>
        </PopupContainer>
      </Dialog>
      <PreviewDialog
        title = {"Bug Report- " + fields.title}
        body={generateBody(fields)}
        showPreviewDialog={showPreviewDialog}
        hidePreview={hidePreview}
        confirmSubmit={confirmSubmit}
      />
    </>
  );
};

export {
  PipelineRequestForm,
  BugReportForm,
};
