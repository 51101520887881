import styled from 'styled-components';
import colors from './colors';

const GraphContainer = styled.div`
    width: 100%;
    min-width: ${props => props.width ? props.width : '1000px'};
    height: ${props => props.height ? props.height : '500px'};
    position: relative;
    border: 1px solid ${colors.border_gray};

    display: flex;
    align-items: center;
    justify-content: center;

    #graph {
        .node {
            cursor: pointer;
            pointer-events: bounding-box;
        }
        
        .node circle {
            fill: #ffffff;
        }

        .node rect {
            fill: #ffffff;
        }

        .label {
            font-size: 9px;
            color: ${colors.light_gray};
        }

        .origin circle {
            stroke: ${colors.origin};
            stroke-width: 5px;
        }

        .datatype circle {
            stroke: ${colors.datatypeNode};
            stroke-width: 5px;
        }

        .executable-hpc circle {
            stroke: ${colors.executableHPC};
            stroke-width: 5px;
        }

        .service-pipeline circle {
            stroke: ${colors.servicePipeline};
            stroke-width: 5px;
        }

        .executable-codeocean circle {
            stroke: ${colors.executableCodeOcean};
            stroke-width: 5px;
        }

        .downstream-node circle {
            stroke: ${colors.downstreamNode};  
            stroke-width: 5px;
        }

        .database rect  {
            stroke: ${colors.origin};
            stroke-width: 5px;
        }

        .website circle  {
            stroke: ${colors.webApp};
            stroke-width: 5px;
        }
      
        .default circle  {
            stroke: ${colors.default};
            stroke-width: 5px;
        }

        .highlightTool circle {
            stroke: #ff4d4d;
            stroke-width: 5px;
            fill: #ff8080;
        }
          
        .edgePath path {
            stroke: ${colors.edgeDefault};
            stroke-width: 2px;
        }

        .edgePath marker {
            stroke: ${colors.edgeDefault};
            fill: ${colors.edgeDefault};
        }
    }

    #graphinfo {
        position: absolute;
        z-index: 998;
        left: 0;
        top: 0;
        padding: 5px;
        font-size: 11px;
        .line {
            margin-bottom: 5px;
            display: flex;
            align-items: center;
        }
        .legend {
            display: flex;
            .marker {
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .label {
                margin-left: 5px;
            }
            .node-circle {
                background-color: #ffffff;
                border-radius: 50%;  
                height: 12px;
                width: 12px;
            }
            .node-rect {
                background-color: #ffffff;  
                height: 12px;
                width: 12px;
            }
            .datatype {
                border: 2px solid ${colors.datatypeNode}; 
            }
            .pipeline {
                border: 2px solid ${colors.executableHPC};  
            }
            .executable-codeocean {
                border: 2px solid ${colors.executableCodeOcean};  
            }
            .database {
                border: 2px solid ${colors.origin};
            }
            .service-pipeline {
                border: 2px solid ${colors.servicePipeline};  
            }
            .default {
                border: 2px solid ${colors.default};
            }
            .arrow {
                font-size: 20px;
                font-weight: bold;
                color: ${colors.edgeDefault};
            }
            .star {
                font-size: 20px;
                text-align: center;
            }
        }
        .bottom-space {
            margin-bottom: 10px;
        }
    }

    #tooltip {
        width: 300px;
        position: absolute;
        z-index: -1;
        padding: 10px;
        background-color: #333333;
        right: 0;
        top: 0;
        opacity: 0;
        color: #ffffff;
        #tooltip-nodeName {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 10px;
        }
        #tooltip-nodeInfo {
            font-size: 12px;
            margin-bottom: 5px;
            div {
                margin-bottom: 2px;
            }
            table {
                td {
                    vertical-align: top;
                }
            }
        }
        #tooltip-nodeType {
            font-size: 12px;
        }
    }
`;

export default GraphContainer;
