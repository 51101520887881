const getFormattedNodes = (pathwayNodes, graphNodes) => {
    let excluded = ['origin', 'datatype'];
    let formatted = graphNodes
                        .filter(node => pathwayNodes.map(pn => pn.id).includes(node.id))
                        .map(node => ({
                            ...node,
                            index: pathwayNodes.findIndex(pn => pn.id === node.id),
                            info: {
                                ...node.info,
                                input: pathwayNodes.find(pn => pn.id === node.id).input,
                                output: pathwayNodes.find(pn => pn.id === node.id).output,
                            }
                        }))
                        .sort((a, b) => (a.index - b.index)) // sort by index in the order of appearence 
    formatted = formatted.filter(n => !excluded.includes(n.type));
    return formatted;
}

export const getNodes = (pipeline, graph) => {
    let pipelineNodes = [];
    let forks = [];
    
    // get nodes that make up the selected pipeline
    pipelineNodes = getFormattedNodes(pipeline.main.nodes, graph.nodes);
    pipeline.subBranches.forEach(branch => {
        forks.push({
            ...branch,
            nodes: getFormattedNodes(branch.nodes, graph.nodes)
        });
    });
    pipeline.purposes.forEach(purpose => {
        forks.push({
            title: purpose.text,
            nodes: getFormattedNodes(purpose.nodes, graph.nodes)
        });
    });
        
    return {
        pipelineNodes: pipelineNodes, 
        forks: forks
    };
}