import React, { useState, useEffect }from 'react';
import {
    useTable,
    useSortBy, 
    usePagination, 
    useFilters,
    useGlobalFilter, 
    useAsyncDebounce
} from 'react-table';
import colors from '../../styles/colors';
import styled from 'styled-components';

const StyledReactTable = styled.div`
    width: 100%;
    color: ${colors.gray_text};
    table{
        border-collapse: collapse;
        width: 100%;
    }
    thead {
        font-size: 12px;
        border-bottom: 1px solid ${colors.light_gray};
    }  

    tbody {
        font-size: 12px;
    }

    tbody tr:nth-child(even) {
        
    }

    th[class='header'] {
        vertical-align: top;
        .headerContent {
            display: flex;
            align-items: center;
            // justify-content: center;
            height: 20px;
            margin: 1.0rem 1.0rem;
        }
    }

    th {
        text-align: center;
        letter-spacing: 0.5px;
    }

    tbody td {
        padding: 1.5rem 1.0rem;
        text-align: left;
        border-bottom: 1px solid ${colors.light_gray};
        @media only screen and (max-width: 900px) {
            // word-break: break-word;
        }
    }

    .pagination {
        dipslay: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 14px;
        button {
            font-size: 12px;
            border: none;
            background: none;
            cursor: pointer;
        }
    }
`;

const SearchContainer = styled.div`
    max-width: 250px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.light_gray};
    margin-top: 15px;
    margin-bottom: 20px;
    input {
        width: 100%;
        margin-left: 5px;
        font-size: 12px;
        padding: 5px 3px;
        border: none;
    };
    input:focus {
        outline: none;
    }
`;

/**
 * Filter for global search of table
 */
const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, searchType }) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);
  
    return (
      <SearchContainer>
        <i className='pi pi-search'></i>
        <input
            type="text"
            value={value || ''}
            onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
            }}
            placeholder={`Search ${count} ${searchType}...`}
        />
      </SearchContainer>
    );
};

const CustomTable = (props) => {
    const { data, datatype, columns, style, disablePagination, showSearch, pageSize } = props;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        pageOptions,
        page,
        state: { 
            pageIndex, 
            globalFilter
            // pageSize
        },
        // gotoPage,
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable(
        {
            columns: columns, 
            data: data, 
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        usePagination
    );

    useEffect(() => {
        setPageSize(pageSize ? pageSize : 5);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <StyledReactTable style={style}>
            {
                showSearch &&
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    searchType={datatype}
                />
            }
            <table {...getTableProps()}>
                <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => (
                                        <th className='header' {...column.getHeaderProps()}>
                                            <div className='headerContent' {...column.getSortByToggleProps()}>
                                                { column.render('Header') }
                                            </div>
                                        </th>
                                    ))
                                }
                            </tr>
                        )) 
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        page.map(row => {
                            prepareRow(row);
                            return(
                                <tr {...row.getRowProps()}>
                                    {
                                        row.cells.map(cell => {
                                            return(
                                                <td key={Math.random()} {...cell.getCellProps}>
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })
                                    }
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            {
                !disablePagination ? (
                    <div className="pagination">
                        <button className="prev" onClick={() => previousPage()} disabled={!canPreviousPage}>
                            Prev
                        </button>
                        <span>
                            {`${pageIndex + 1} of ${pageOptions.length}`}
                        </span>
                        <button className="next" onClick={() => nextPage()} disabled={!canNextPage}>
                            Next
                        </button>
                    </div>
                )
                :
                null
            }
        </StyledReactTable>
    );
}

export default CustomTable;