import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DecisionTree from "../../Diagrams/DecisionTree";
import CustomDropdown from "../../UtilComponents/CustomDropdown";
import CustomButton from "../../UtilComponents/CustomButton";
import PipelineDialog from "../PipelineDialog/PipelineDialog";
import ChipGroup from "../../UtilComponents/ChipGroup";
import { formatNodeObjects } from "../../../utils/format-node";
import Disclaimer from "../../UtilComponents/Disclaimer";
import LikeButton from "../../UtilComponents/LikeButton";
import ShareButton from "../../UtilComponents/ShareButton";
import { useKeycloak } from "@react-keycloak/web";

const GraphHeader = styled.div`
  width: 100%;
  min-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  .title {
    font-size: 14px;
    font-weight: bold;
    margin-right: 20px;
  }
  .pipeline-buttons {
    display: flex;
    align-items: center;
    .executable-link {
      margin-left: 5px;
    }
  }
`;

const FilterElement = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  .label {
    font-size: 12px;
    margin-right: 10px;
  }
  .dropdown-pipelines {
    width: 250px;
  }
`;

const PipelineNavigation = (props) => {
  const { data } = props;
  const { keycloak } = useKeycloak();
  const [pipelineDropdown, setPipelineDropdown] = useState({
    selected: "None",
    options: [],
    disabled: false,
  });
  const [showDialog, setShowDialog] = useState(false);
  const [graph, setGraph] = useState({
    nodes: [],
    links: [],
  });

  useEffect(() => {
    setGraph({
      nodes: formatNodeObjects(data.pipelines, data.tools, data.graph.nodes),
      links: data.graph.links,
    });
    let options = data.pipelines.map((pipeline) => ({
      value: pipeline.name,
      label: pipeline.name,
    }));
    options.unshift({ value: "None", label: "None" });
    setPipelineDropdown({
      selected: "None",
      options: options,
      disabled: false,
    });
  }, [data]);

  const renderButtons = () => {
    if (pipelineDropdown.selected !== "None") {
      const pipeline = data.pipelines.find(
        (item) => item.name === pipelineDropdown.selected
      );
      if (pipeline) {
        return (
          <div className="pipeline-buttons">
            <CustomButton
              className="p-button-text p-button-sm p-button-info"
              icon="pi pi-search"
              label={`View details of ${pipelineDropdown.selected}`}
              onClick={() => {
                setShowDialog(true);
              }}
            />
            <ChipGroup
              executableInfo={{
                name: `${pipeline.name}(${pipeline.datatype.join(",")})`,
                id: pipeline._id,
                type: "pipeline",
                isService: pipeline.core,
              }}
              executableLinks={pipeline.main.executableLinks}
              core={pipeline.core ? pipeline.link : null}
              disabled={false}
              executableType="pipeline"
            />
            <LikeButton
              id={pipeline._id}
              type="pipeline"
              user={
                keycloak.authenticated
                  ? { email: keycloak.idTokenParsed.email }
                  : null
              }
              disabled={true}
            />
            <ShareButton
              id={pipeline._id}
              type="pipeline"
              name={pipeline.name}
            />
          </div>
        );
      }
    }
  };

  return (
    <React.Fragment>
      <Disclaimer />
      <GraphHeader>
        {data.initialized ? (
          <div className="title">Explore Pipelines</div>
        ) : (
          <React.Fragment>
            <div className="title">{`${data.pipelines.length} Pipelines Found:`}</div>
            <FilterElement>
              <span className="label">Highlight pipeline: </span>
              <CustomDropdown
                className="dropdown-pipelines"
                value={pipelineDropdown.selected}
                options={pipelineDropdown.options}
                onChange={(e) =>
                  setPipelineDropdown((prev) => ({
                    ...prev,
                    selected: e.value,
                  }))
                }
                filter={true}
                placeholder="Highlight..."
              />
            </FilterElement>
            <React.Fragment>{renderButtons()}</React.Fragment>
          </React.Fragment>
        )}
      </GraphHeader>
      <DecisionTree
        graph={graph}
        pipelines={data.pipelines}
        filtered={data.filtered}
        selectedPipeline={pipelineDropdown.selected}
        width={1000}
        height={500}
      />
      <PipelineDialog
        visible={showDialog}
        onHide={() => {
          setShowDialog(false);
        }}
        data={data}
        selectedPipeline={pipelineDropdown.selected}
      />
    </React.Fragment>
  );
};

export default PipelineNavigation;
