export const toolType = [
    'software',
    'rLibrary',
    'rWorkflow',
    'rDb',
    'pythonScript',
    'website',
    'database'
];

export const baseType = [
    'file'
];

export const nodetypeOptions = [
    {value: 'software', label: 'Software'},
    {value: 'pythonScript', label: 'Python Script'},
    {value: 'rDb', label: 'R-DB'},
    {value: 'rLibrary', label: 'R-Library'},
    {value: 'rWorkflow', label: 'R-Workflow'},
    {value: 'website', label: 'Website'},
    {value: 'database', label: 'Database'}
];

export const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const reservedDomains = [
    "cobe.ca"
];
export const allowedDomains = [
    "uhnresearch.ca"
];