import React from 'react';

/**
 * A small component used to indicate if a form field is required.
 * @returns styled span with asterisk
 */
const RequiredField = () => (
    <span style={{color: 'red', fontSize: '14px'}}>*</span>
);

export default RequiredField;