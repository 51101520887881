import React, { useEffect } from 'react';
import Routes from './Routes/Routes';
import GlobalStyles from './styles/GlobalStyles';
import ReactGA from 'react-ga4';

const App = () => {

  // Google Analytics set up
	useEffect(() => {
		ReactGA.initialize('G-PQHMLSCL3F');
		ReactGA.send({
      hitType: "pageview", 
      path: window.location.pathname + window.location.search
    });
	}, []);

  return (
    <React.Fragment>
      <GlobalStyles />
      <Routes />
    </React.Fragment>
  );
}

export default App;
