import styled from 'styled-components';
import colors from './colors';

export const StyledTable = styled.table`
    width: 100%;
    min-width: 550px;
    border-collapse: collapse;
    th, td{
        text-align: left;
        padding: 20px 20px;
        font-size: 12px;
    }
    tr {
        border-bottom: 1px solid ${colors.light_gray};
    }
    .center {
        text-align: center;
    }
`;
