import React from "react";
import { StyledTable } from "../../../../styles/StyledTable";
import { StyledLink } from "../../../../styles/utils";
import styled from "styled-components";
import ChipGroup from "../../../UtilComponents/ChipGroup";
import LikeButton from "../../../UtilComponents/LikeButton";
import { useKeycloak } from "@react-keycloak/web";
import ShareButton from "../../../UtilComponents/ShareButton";

const StyledContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  .purpose-text {
    margin-right: 5px;
  }
  .chip {
    margin-left: 10px;
  }
`;

const ToolTable = (props) => {
  const { tools, search } = props;
  const { keycloak } = useKeycloak();
  return (
    <StyledTable>
      <thead>
        <tr>
          <th className="content">Name</th>
          <th className="content">Type</th>
          <th className="content center">Popularity Score</th>
          <th className="content">Purposes</th>
        </tr>
      </thead>
      <tbody>
        {tools.map((tool, i) => (
          <tr key={i}>
            <td className="content">
              <StyledContent>
                <StyledLink
                  to={{
                    pathname: `tools/${tool._id}`,
                    state: { search: search },
                  }}
                >
                  {tool.name}
                </StyledLink>
              </StyledContent>
            </td>
            <td className="content">{tool.type}</td>
            <td className="content center">{tool.score}</td>
            <td className="content">
              {tool.purposes.map((purpose, i) => (
                <StyledContent key={i}>
                  <span className="purpose-text">{purpose.text}</span>
                  <ChipGroup
                    executableLinks={purpose.executableLinks}
                    disabled={false}
                    executableType="tool usage sample"
                    executableInfo={{
                      name: tool.name,
                      id: tool._id,
                      type: "tool",
                    }}
                  />
                </StyledContent>
              ))}
            </td>
            <td>
              <LikeButton
                id={tool._id}
                type="tool"
                user={
                  keycloak.authenticated
                    ? { email: keycloak.idTokenParsed.email }
                    : undefined
                }
              />
            </td>
            <td>
              <ShareButton id={tool._id} type="tool" name={tool.name} />
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default ToolTable;
