import styles from "../styles/node-style";
import { toolType } from "./constants";

const getExecutablePathwayNodes = (pathway) => {
  if (pathway.executableLinks.length > 0) {
    const defaultLink = pathway.executableLinks.find(
      (link) => link.default === true
    );
    if (defaultLink.linkType.length > 0) {
      return {
        linkType: defaultLink.linkType,
        nodes: pathway.nodes.map((node) => node.id),
      };
    }
    return null;
  }
  return null;
};

const getExecutableNodes = (pathwayNodes, allNodes, tools) => {
  let executableNodes = [];
  for (let pathwayNode of pathwayNodes) {
    let node = allNodes.find((n) => n.id === pathwayNode.id);
    if (node && node.type !== "origin" && node.type !== "datatype") {
      let found = tools.find(
        (tool) => tool._id.toString() === node.toolId.toString()
      );
      let toolPurpose = found.purposes.find(
        (purpose) => purpose.text === node.purpose
      );
      if (toolPurpose && toolPurpose.executableLinks.length > 0) {
        let defaultLink = toolPurpose.executableLinks.find(
          (link) => link.default
        );
        executableNodes.push({
          nodeId: node.id,
          linkType: defaultLink.linkType,
        });
      }
    }
  }
  return executableNodes;
};

/**
 * Assigns style to each node of a given network of pipelines and nodes.
 * This determines the color of a node in the rendered network.
 * Currently assigns four styles:
 * default (gray),
 * pipeline node (purple),
 * downstream analysis node (light blue) and
 * service (core) pipeline node (orange)
 * @param {*} pipelines
 * @param {*} nodes
 * @returns nodes with node style assigned.
 */
const assignNodeStyle = (pipelines, tools, nodes, isBuilder) => {
  let executableNodes = {
    HPC: [],
    CodeOcean: [],
    CodeOceanVPC: [],
    service: [],
    WebApp: [],
    downstream: [],
  };
  let executablePathwayNodes = {
    HPC: [],
    CodeOcean: [],
    CodeOceanVPC: [],
    service: [],
    WebApp: [],
  };
  for (let pipeline of pipelines) {
    if (pipeline.core) {
      executablePathwayNodes.service = executablePathwayNodes.service.concat(
        pipeline.main.nodes.map((item) => item.id)
      );
      for (let purpose of pipeline.purposes) {
        executablePathwayNodes.service = executablePathwayNodes.service.concat(
          purpose.nodes.map((item) => item.id)
        );
      }
    } else {
      let pathway = getExecutablePathwayNodes(pipeline.main);
      if (pathway) {
        executablePathwayNodes[pathway.linkType] = executablePathwayNodes[
          pathway.linkType
        ].concat(pathway.nodes);
      }
      let execNodes = getExecutableNodes(pipeline.main.nodes, nodes, tools);
      for (const linkType of Object.keys(executableNodes)) {
        executableNodes[linkType] = executableNodes[linkType].concat(
          execNodes
            .filter((node) => node.linkType === linkType)
            .map((node) => node.nodeId)
        );
      }
      for (let branch of pipeline.subBranches) {
        pathway = getExecutablePathwayNodes(branch);
        if (pathway) {
          executablePathwayNodes[pathway.linkType] = executablePathwayNodes[
            pathway.linkType
          ].concat(pathway.nodes);
        }
        execNodes = getExecutableNodes(branch.nodes, nodes, tools);
        for (const linkType of Object.keys(executableNodes)) {
          executableNodes[linkType] = executableNodes[linkType].concat(
            execNodes
              .filter((node) => node.linkType === linkType)
              .map((node) => node.nodeId)
          );
        }
      }
      for (let purpose of pipeline.purposes) {
        if (isBuilder) {
          executableNodes.downstream = executableNodes.downstream.concat(
            purpose.nodes.map((node) => node.id)
          );
        } else {
          execNodes = getExecutableNodes(purpose.nodes, nodes, tools);
          for (const linkType of Object.keys(executableNodes)) {
            executableNodes[linkType] = executableNodes[linkType].concat(
              execNodes
                .filter((node) => node.linkType === linkType)
                .map((node) => node.nodeId)
            );
          }
        }
      }
    }
  }

  let allPathwayNodes = [];
  for (const linkType of Object.keys(executablePathwayNodes)) {
    executablePathwayNodes[linkType] = [
      ...new Set(executablePathwayNodes[linkType]),
    ];
    allPathwayNodes = allPathwayNodes.concat(executablePathwayNodes[linkType]);
  }
  for (const linkType of Object.keys(executablePathwayNodes)) {
    executableNodes[linkType] = executableNodes[linkType].filter(
      (node) => !allPathwayNodes.includes(node)
    );
  }

  nodes = nodes.map((node) => {
    if (node.type !== "origin" && node.type !== "datatype") {
      let style = styles.default;
      let className = "default";
      if (
        executablePathwayNodes.HPC.includes(node.id) ||
        executableNodes.HPC.includes(node.id)
      ) {
        style = styles.executableHPC;
        className = "executable-hpc";
      }
      if (
        executablePathwayNodes.CodeOcean.includes(node.id) ||
        executableNodes.CodeOcean.includes(node.id)
      ) {
        style = styles.executableCodeOcean;
        className = "executable-codeocean";
      }
      if (
        executablePathwayNodes.CodeOceanVPC.includes(node.id) ||
        executableNodes.CodeOceanVPC.includes(node.id)
      ) {
        style = styles.executableCodeOcean;
        className = "executable-codeocean";
      }
      if (
        executablePathwayNodes.service.includes(node.id) ||
        executableNodes.service.includes(node.id)
      ) {
        style = styles.servicePipeline;
        className = "service-pipeline";
      }
      if (executableNodes.downstream.includes(node.id)) {
        style = styles.downstreamNode;
        className = "downstream-node";
      }
      node.style = { ...style };
      node.class = className;
    } else {
      node.style = styles[node.type];
      node.class = node.type;
    }
    return node;
  });

  for (const node of nodes) {
    if (node.type === "database") {
      node.style = styles.database;
      node.class = "database";
    }

    if (node.type === "website") {
      node.style = styles.website;
      node.class = "website";
    }
  }

  return nodes;
};

/**
 * Formats node.info object so that it can be properly displayed on the tooltip.
 * @param {*} pipelines
 * @param {*} tools
 * @param {*} nodes
 * @returns nodes with info object
 */
const assignNodeInfo = (pipelines, tools, nodes) => {
  // find tool nodes for given pipelines to add input/output to the nodes.
  let toolNodes = [];
  for (let pipeline of pipelines) {
    toolNodes = toolNodes.concat(
      pipeline.main.nodes.filter(
        (node) =>
          (node.input && node.input.length > 0) ||
          (node.output && node.output.length > 0)
      )
    );
    for (let branch of pipeline.subBranches) {
      toolNodes = toolNodes.concat(
        branch.nodes.filter(
          (node) =>
            (node.input && node.input.length > 0) ||
            (node.output && node.output.length > 0)
        )
      );
    }
    for (let purpose of pipeline.purposes) {
      toolNodes = toolNodes.concat(
        purpose.nodes.filter(
          (node) =>
            (node.input && node.input.length > 0) ||
            (node.output && node.output.length > 0)
        )
      );
    }
  }
  for (let node of nodes) {
    if (toolType.includes(node.type)) {
      let found = tools.find(
        (item) => item._id.toString() === node.toolId.toString()
      );
      let foundNode = toolNodes.find((item) => item.id === node.id);
      let toolPurpose = found.purposes.find(
        (purpose) => purpose.text === node.purpose
      );
      node.info = {
        version: node.version,
        purpose: node.purpose,
        keywords: found.keywords,
        input: foundNode.input,
        output: foundNode.output,
        score: found.score,
        link: found.link,
        doi: found.doi,
        executableLinks: toolPurpose ? toolPurpose.executableLinks : [],
      };
      // delete node.version;
      // delete node.purpose;
    }
  }
  return nodes;
};

/**
 * Assigns style and tooltip info objects to each node.
 * @param {*} pipelines
 * @param {*} tools
 * @param {*} nodes
 * @returns
 */
const formatNodeObjects = (pipelines, tools, nodes, isBuilder = false) => {
  let formattedNodes = [...nodes];
  formattedNodes = assignNodeStyle(pipelines, tools, formattedNodes, isBuilder);
  formattedNodes = assignNodeInfo(pipelines, tools, formattedNodes);
  return formattedNodes;
};

export { formatNodeObjects, getExecutablePathwayNodes };
