import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../../UtilComponents/Layout';
import PropTypes from 'prop-types';
import { Markup } from 'interweave';
import Disclaimer from '../../UtilComponents/Disclaimer';
import Moment from "moment";
import axios from "axios";
import Loading from "../../UtilComponents/Loading";
import styled from "styled-components";
import colors from "../../../styles/colors";

const Header= styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 20px;
    font-weight: bold;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;


const Container = styled.div`
  width: 80%;
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
`;

const StyledNews = styled.div`
    width: 80%;
    .subject {
        color: ${colors.gray_text};
        display: flex;
        align-items: center;
        height: 60px;
        font-size: 20px;
        font-weight: normal;
        margin-top: 30px;
    }
    .date{
        color: ${colors.light_gray};
        display: flex;
        align-items: center;
        height: 30px;
        font-size: 12px;
        font-weight: normal;
    }
    .content {
        font-size: 15px;
        line-height: 25px;
        font-weight: normal;
        width: 75%;
        color: ${colors.gray_text};
    }
    .divider {
        margin-bottom: 30px;
        padding-top: 10px;
    }
`;

const IndividualNews = (props) => {
    const {
        match: {params},
    } = props;

    const [ready, setReady] = useState(false);
    const [letter, setLetter] = useState({});
    const history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0)
        const getNews = async () => {
            const res = await axios.get('/api/data/stats/news');

            const letter = res.data.news.filter(item => item.type ==="letter" && item.subject.toLowerCase()
                .replace(/ /g,'-')
                .replace(/[^\w-]+/g,'') === params.token)[0];
            setLetter(letter);
            setReady(true);
        }
        getNews();
    }, [params.token]);

    useEffect(() => {
        return(() => {
            if(history.action === 'POP' && history.location.pathname === '/') {
                console.log('history')
                history.replace({
                    pathname: '/',
                    state: {
                    }
                });
            }
        });
    }, [history]);

    return(
        <Layout>
            <Header>
                <Disclaimer />
            </Header>
            <Container>
                <StyledNews className="individual">
                    {
                        ready?
                            <>
                                <div className='date'>{Moment(letter.date).format("MMM Do, YYYY")}</div>
                                <div className='content'><Markup content={letter.subject}></Markup></div>
                                <div className='content'><Markup content={letter.content}></Markup></div>
                            </>
                            : <Loading top='100px'/>
                    }
                </StyledNews>
            </Container>
        </Layout>
    );

}

IndividualNews.propTypes = {
    /**
     * IndividualNews' param token
     */
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default IndividualNews;
