import * as d3 from "d3";
import colors from "../styles/colors";
import { toolType } from "./constants";
import { getNodeIdLabel } from "./helper";
import { getExecutablePathwayNodes } from "./format-node";
// const tooltype = ['software', 'rLibrary', 'pythonScript', 'assay'];

/**
 * Function used to style an edge object.
 * @param {*} edge - selected edge
 * @param {*} color - color of the edge
 * @param {*} strokeWidth - width of the edge
 */
const styleEdge = (edge, color, strokeWidth) => {
  let edgegroup = d3.select(edge);
  edgegroup
    .select("path")
    .style("stroke", color)
    // .style("stroke-width", strokeWidth);
  edgegroup
    .select("marker")
    .style("stroke", color)
    .style("fill", color)
    .select("path")
    .style("stroke", color);
};

const getNode = (node, showNodeId) => {
  let nodeLabel = getNodeLabel(node);
  let nodeIdLabel = getNodeIdLabel(node.id);
  return {
    toolId: node.toolId,
    label: `${nodeLabel}${showNodeId ? `\\n(${nodeIdLabel})` : ""}`,
    name: node.name,
    type: node.type,
    info: node.info,
    shape: node.style.shape,
    colors: node.style.colors,
    class: `${node.class}`,
    width: node.style.shape === "circle" ? 50 : 50,
    height: node.style.shape === "circle" ? 50 : 50,
    labelStyle: "text-anchor: right;",
  };
};

const getNodeLabel = (node) => {
  let label =
    node.name.length <= 15
      ? node.name
      : node.name.substring(0, 11).concat("...");
  return label;
};

const getNodeInfo = (node) => {
  if (toolType.includes(node.type)) {
    let innerhtml = `
            <table>
                <tr>
                    <td>Version:</td>
                    <td>${
                      node.info.version ? node.info.version : "Unavailable"
                    }</td>
                </tr>
                <tr>
                    <td>Purpose:</td>
                    <td>${
                      node.info.purpose.length > 0
                        ? node.info.purpose
                        : "Unavailable"
                    }</td>
                </tr>
                ${
                  node.info.input && node.info.input.length > 0
                    ? `<tr>
                        <td>Input:</td>
                        <td>${node.info.input.join(", ")}</td>
                    </tr>`
                    : ""
                }
                ${
                  node.info.output && node.info.output.length > 0
                    ? `<tr>
                        <td>Output:</td>
                        <td>${node.info.output.join(", ")}</td>
                    </tr>`
                    : ""
                }
            </table>
            ${
              node.info.executableLinks && node.info.executableLinks.length > 0
                ? `<div>
                    <p>Executable Analysis Available</p>
                </div>`
                : ""
            }
        `;
    return innerhtml;
  } else {
    return "";
  }
};

const assignEdgeColors = (d3, executableEdges) => {
  console.log(executableEdges)
  let svg = d3.select(`#graph`);
  let edges = svg.selectAll(".edgePath").nodes();
  edges.forEach((edge) => {
    if (
      executableEdges.HPC.find(
        (item) =>
          item.source === edge.__data__.v && item.target === edge.__data__.w
      )
    ) {
      styleEdge(edge, colors.executableHPC, 2);
    }
    if (
      executableEdges.CodeOcean.find(
        (item) =>
          item.source === edge.__data__.v && item.target === edge.__data__.w
      )
    ) {
      styleEdge(edge, colors.executableCodeOcean, 2);
    }
    if (
      executableEdges.CodeOceanVPC.find(
        (item) =>
          item.source === edge.__data__.v && item.target === edge.__data__.w
      )
    ) {
      styleEdge(edge, colors.executableCodeOcean, 2);
    }
    if (
      executableEdges.service.find(
        (item) =>
          item.source === edge.__data__.v && item.target === edge.__data__.w
      )
    ) {
      styleEdge(edge, colors.servicePipeline, 2);
    }
  });
};

const highlightPipeline = (d3, selected, pipelines, executableEdges) => {
  let svg = d3.select(`#graph`);
  let edges = svg.selectAll(".edgePath").nodes();
  let nodes = svg.selectAll(".node").nodes();
  if (selected !== "None") {
    resetHighlight(d3, executableEdges);

    // get all the nodes in the selected pipeline
    let pipeline = pipelines.find((pipeline) => pipeline.name === selected);
    let pipelineNodes = pipeline.main.nodes;
    for (let branch of pipeline.subBranches) {
      pipelineNodes = pipelineNodes.concat(branch.nodes);
    }
    for (let purpose of pipeline.purposes) {
      pipelineNodes = pipelineNodes.concat(purpose.nodes);
    }
    pipelineNodes = pipelineNodes.map((node) => node.id);

    edges.forEach((edge) => {
      if (
        pipelineNodes.includes(edge.__data__.v) &&
        pipelineNodes.includes(edge.__data__.w)
      ) {
        let color = colors.edgeDefault;
        if (
          executableEdges.HPC.find(
            (item) =>
              item.source === edge.__data__.v && item.target === edge.__data__.w
          )
        ) {
          color = colors.executableHPC;
        }
        if (
          executableEdges.CodeOcean.find(
            (item) =>
              item.source === edge.__data__.v && item.target === edge.__data__.w
          )
        ) {
          color = colors.executableCodeOcean;
        }
        if (
          executableEdges.CodeOceanVPC.find(
            (item) =>
              item.source === edge.__data__.v && item.target === edge.__data__.w
          )
        ) {
          color = colors.executableCodeOcean;
        }
        if (
          executableEdges.service.find(
            (item) =>
              item.source === edge.__data__.v && item.target === edge.__data__.w
          )
        ) {
          color = colors.servicePipeline;
        }

        styleEdge(edge, color, 3);
      } else {
        d3.select(edge).style("opacity", 0.3);
      }
    });

    nodes.forEach((node) => {
      if (!pipelineNodes.includes(node.__data__)) {
        d3.select(node).style("opacity", 0.3);
      }
    });
  } else {
    resetHighlight(d3, executableEdges);
  }
};

const resetHighlight = (d3, executableEdges) => {
  let edges = d3.select(`#graph`).selectAll(".edgePath").nodes();
  edges.forEach((edge) => {
    let color = colors.edgeDefault;
    if (
      executableEdges.HPC.find(
        (item) =>
          item.source === edge.__data__.v && item.target === edge.__data__.w
      )
    ) {
      color = colors.executableHPC;
    }
    if (
      executableEdges.CodeOcean.find(
        (item) =>
          item.source === edge.__data__.v && item.target === edge.__data__.w
      )
    ) {
      color = colors.executableCodeOcean;
    }
    if (
      executableEdges.CodeOceanVPC.find(
        (item) =>
          item.source === edge.__data__.v && item.target === edge.__data__.w
      )
    ) {
      color = colors.executableCodeOcean;
    }
    if (
      executableEdges.service.find(
        (item) =>
          item.source === edge.__data__.v && item.target === edge.__data__.w
      )
    ) {
      color = colors.servicePipeline;
    }
    styleEdge(edge, color, 2);
  });

  d3.selectAll(".edgePath").style("opacity", 1);
  d3.selectAll(".node").style("opacity", 1);
};

const getExecutablePipelineEdges = (pipelines, links) => {
  let executableNodes = {
    HPC: [],
    CodeOcean: [],
    CodeOceanVPC: [],
    service: [],
  };
  let executableLinks = {
    HPC: [],
    CodeOcean: [],
    CodeOceanVPC: [],
    service: [],
  };
  for (const pipeline of pipelines) {
    if (pipeline.core) {
      executableNodes.service = executableNodes.service.concat(
        pipeline.main.nodes.map((item) => item.id)
      );
      for (let purpose of pipeline.purposes) {
        executableNodes.service = executableNodes.service.concat(
          purpose.nodes.map((item) => item.id)
        );
      }
    } else {
      let pathway = getExecutablePathwayNodes(pipeline.main);
      if (pathway) {
        executableNodes[pathway.linkType] = executableNodes[
          pathway.linkType
        ].concat(pathway.nodes);
      }
      for (let branch of pipeline.subBranches) {
        pathway = getExecutablePathwayNodes(branch);
        if (pathway) {
          executableNodes[pathway.linkType] = executableNodes[
            pathway.linkType
          ].concat(pathway.nodes);
        }
      }
    }
    // eslint-disable-next-line no-loop-func
  }
  for (const key of Object.keys(executableLinks)) {
    executableLinks[key] = links.filter(
      (link) =>
        link.source !== 'node_0' && 
        executableNodes[key].includes(link.source) &&
        executableNodes[key].includes(link.target)
    );
  }
  return executableLinks;
};

const assignEdgeWidth = (d3, pipelines) => {
  let svg = d3.select(`#graph`);
  let edges = svg.selectAll(".edgePath").nodes();

  let edgeWidthObjects = pipelines.map(pipeline => ({
    strokeWidth: pipeline.strokeWidth,
    links: pipeline.graphLinks
  }));

  edgeWidthObjects.sort((a, b) => b.strokeWidth - a.strokeWidth);
  let uniqueEdges = [];
  for(let edgeWidth of edgeWidthObjects){
    let notFound = edgeWidth.links.filter(link => !uniqueEdges.find(edge => edge.source === link.source && edge.target === link.target));
    notFound = notFound.filter(edge => edge.source !== 'node_0');
    edgeWidth.links = notFound;
    uniqueEdges = uniqueEdges.concat(notFound);
  }

  edgeWidthObjects = edgeWidthObjects.filter(edgeWidth => edgeWidth.strokeWidth);
  edges.forEach(edge => {
    d3.select(edge).select("path").style("stroke-width", 2);
    d3.select(edge).select("marker").attr("markerWidth", 3);
  })
  for(const edgeWidthObj of edgeWidthObjects){
    edges.forEach((edge) => {
      if (
        edgeWidthObj.links.find(
          (item) =>
            item.source === edge.__data__.v && item.target === edge.__data__.w
        )
      ) {
        d3.select(edge).select("path").style("stroke-width", edgeWidthObj.strokeWidth);
        d3.select(edge).select("marker").attr("markerWidth", 3);
      }
    });
  }

  return(edgeWidthObjects);
}

export {
  getNode,
  getNodeLabel,
  getNodeInfo,
  assignEdgeColors,
  assignEdgeWidth,
  highlightPipeline,
  resetHighlight,
  getExecutablePipelineEdges,
};
