import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Layout from '../../UtilComponents/Layout';
import EditPipeline from './SubComponents/EditPipeline';
import EditTool from './SubComponents/EditTool';
import { useKeycloak } from "@react-keycloak/web";

const EditMain = () => {
    const { keycloak } = useKeycloak();
    const { type, id } = useParams();
    const [editable, setEditable] = useState({
        ready: false,
        error: false,
        data: undefined
    });

    if(!keycloak.authenticated){
        keycloak.login();
    }

    useEffect(() => {
        const getData = async () => { 
            try{
                const res = await axios.post(`/api/view/edit/${type}/${id}`, { email: keycloak.idTokenParsed.email});
                setEditable({
                    ready: true,
                    error: false,
                    data: res.data
                });
            }catch(error){
                console.log(error);
                setEditable({
                    ready: true,
                    error: true,
                    data: undefined
                });
            }
        }   
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEditView = () => {
        switch(type){
            case 'pipeline':
                return(<EditPipeline data={editable.data} />);
            case 'tool':
                return(<EditTool tool={editable.data} />);
            default:
                break;
        }
    }

    return(
        <Layout>
            {
                editable.ready &&
                <React.Fragment>
                    {
                        editable.error ?
                        <h3>{`The ${type} could not be found.`}</h3>
                        :
                        editable.data ?
                        getEditView()
                        :
                        <h3>{`You are not authorized to edit this ${type}.`}</h3>
                    }
                </React.Fragment>
            }
        </Layout>
    );
}

export default EditMain;