import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomButton from '../../../UtilComponents/CustomButton';
import CustomMessages from '../../../UtilComponents/CustomMessages';
import { editSuccess, editError } from '../../../../utils/messages';
import { Container } from '../EditStyle';
import { StyledBuilderForm } from '../../BuildPipeline/BuildPipelineStyles';
import ToolForm from '../../BuildPipeline/Forms/ToolForm';
import Loading from '../../../UtilComponents/Loading';
import { useKeycloak } from "@react-keycloak/web";

const EditTool = (props) => {
    const { tool } = props;
    const { keycloak } = useKeycloak();
    const [editable, setEditable] = useState();
    const [inOutOptions, setInOutOptions] = useState([]);
    const [ready, setReady] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        const initialize = async () => {
            if(typeof tool !== 'undefined'){
                const res = await axios.get('/api/data/tool/newtooloptions');
                setInOutOptions(res.data.inOutData.map(item => ({label: item, value: item})));
                setEditable({
                    ...tool,
                    input: tool.input.map(item => ({label: item, value: item})),
                    output: tool.output.map(item => ({label: item, value: item}))
                });
                setReady(true);
            }
        }
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        const res = await axios.post('/api/data/tool/edit', {
            tool : {
                ...editable,
                input: editable.input.map(item => item.value),
                output: editable.output.map(item => item.value)
            },
            email: keycloak.idTokenParsed.email
        });
        if(res.status === 200){
            setMessage(editSuccess);
        }else{
            setMessage(editError);
        }
        setShowMessage(Math.random());
    }

    return(
        <Container>
            {
                ready ?
                <React.Fragment>
                    <CustomMessages trigger={showMessage} message={message} />
                    <div className='header'>
                        <div className='title'>Edit Tool: {tool.name}</div>
                        <CustomButton 
                            label='Submit Changes' 
                            onClick={onSubmit} 
                            className='p-button-sm p-button-success'
                            disabled={false}
                        />
                    </div>
                    <div className='form'>
                        <StyledBuilderForm>
                            <ToolForm 
                                tool={editable}
                                setTool={setEditable}
                                inOutOptions={inOutOptions}
                                isEdit={true}
                                prevVersions={tool.versions}
                                prevPurposes={tool.purposes}
                            />
                        </StyledBuilderForm>
                    </div>
                </React.Fragment>
                :
                <Loading top={'200px'}/>
            }
        </Container>
    );
}

export default EditTool;