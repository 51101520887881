import React, { useEffect, useState } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import ToolInfoView from "../UtilComponents/ToolInfoView";
import HyperLink from "../UtilComponents/HyperLink";
import ChipGroup from "../UtilComponents/ChipGroup";
import CustomChip from "../UtilComponents/CustomChip";
import LikeButton from "../UtilComponents/LikeButton";
import { useKeycloak } from "@react-keycloak/web";
// import ShareButton from "../UtilComponents/ShareButton";

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .executable-link {
    margin-left: 10px;
  }
  .edit-link {
    margin-left: 10px;
  }
`;

const PipelineList = styled.div``;

const ToolHeader = (props) => {
  const { node, user } = props;
  const [showEdit, setShowEdit] = useState(false);
  const showEditButton = async (toolId, user) => {
    if (user) {
      if (user.email === "admin@cobe.ca") {
        return true;
      }
      const res = await axios.get(
        `/api/data/tool/submittedBy?toolId=${toolId}`
      );
      if (res.data.email === user.email) {
        return true;
      }
    }
    return false;
  };
  useEffect(() => {
    const getData = async () => {
      let show = await showEditButton(node.toolId, user);
      setShowEdit(show);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledHeader>
      <span>
        <HyperLink
          link={`/tools/${node.toolId}`}
          text={`${node.name}${
            node.info.version ? `(${node.info.version})` : ""
          }`}
        />
      </span>
      <ChipGroup
        executableLinks={node.info.executableLinks}
        disabled={false}
        executableType="tool"
        executableInfo={{
          name: node.name,
          id: node.toolId,
          type: "tool",
        }}
      />
      <span className="edit-link">
        {showEdit ? (
          <CustomChip
            id="tool-edit-button"
            label={
              <span>
                <i className="pi pi-pencil"></i> Edit
              </span>
            }
            tooltipText="Edit this tool"
            tooltipPlace="right"
            link={`/edit/tool/${node.toolId}`}
          />
        ) : (
          ""
        )}
      </span>
      <LikeButton id={node.toolId} type="tool" user={user} disabled={true} />
      {/*<ShareButton id= {node.toolId} type='tool' name={node.name}/>*/}
    </StyledHeader>
  );
};

const getDataTypeHeader = (node) => {
  return (
    <StyledHeader>
      <span>{node.name}</span>
    </StyledHeader>
  );
};

const getPipelineList = (node, pipelines, disabled) => (
  <PipelineList>
    <h4>
      {node.type === "datatype"
        ? `${pipelines.length} pipeline${
            pipelines.length > 1 ? "s are " : " is "
          } available for ${node.name}`
        : `${node.name}${
            node.info.version ? `(${node.info.version})` : ""
          } can be found in the following pipelines:`}
    </h4>
    <table>
      <tbody>
        {pipelines.map((item, i) => (
          <tr key={i}>
            <td>
              <HyperLink
                link={`/pipeline/${item._id}`}
                text={`${item.name} (${item.datatype})`}
              />
            </td>
            <td>
              <ChipGroup
                executableInfo={{
                  name: `${item.name}(${item.datatype.join(",")})`,
                  id: item._id,
                  type: "pipeline",
                  isService: item.core,
                }}
                executableLinks={item.main.executableLinks}
                core={item.core ? item.link : null}
                disabled={false}
                executableType="pipeline"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </PipelineList>
);

const DecisionTreeDialog = (props) => {
  const { visible, clickedNode, pipelinesWithClickedNode, onHide } = props;
  const { keycloak } = useKeycloak();
  return (
    <React.Fragment>
      {typeof clickedNode !== "undefined" && (
        <Dialog
          header={
            clickedNode.type === "datatype" ? (
              getDataTypeHeader(clickedNode)
            ) : (
              <ToolHeader
                node={clickedNode}
                user={
                  keycloak.authenticated
                    ? { email: keycloak.idTokenParsed.email }
                    : undefined
                }
              />
            )
          }
          visible={visible}
          onHide={onHide}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "40vw" }}
        >
          {typeof clickedNode.toolId !== "undefined" &&
            typeof clickedNode.info !== "undefined" && (
              <ToolInfoView toolInfo={clickedNode.info} />
            )}
          {pipelinesWithClickedNode.length > 0 &&
            getPipelineList(
              clickedNode,
              pipelinesWithClickedNode,
              !keycloak.authenticated
            )}
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default DecisionTreeDialog;
