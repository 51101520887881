import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import { PipelineBuilderContext } from '../../../../hooks/Contexts';
import { RadioButton } from 'primereact/radiobutton';
import StyledRadioButtonGroup from '../../../../styles/StyledRadioButtonGroup';
import { 
    StyledPipelineCanvas, 
    StyledSubHeader 
} from '../BuildPipelineStyles';
import { formatNodeObjects } from '../../../../utils/format-node';
import PipelineInfo from '../SubComponents/PipelineInfo';
import DecisionTree from '../../../Diagrams/DecisionTree';

// Subcomponents used to modify the pipeline data.
import AddNode from '../SubComponents/AddNode';
import RemoveNode from '../SubComponents/RemoveNode';
import AddRemoveLink from '../SubComponents/AddRemoveLink';
import DefineAnalysis from '../SubComponents/DefineAnalysis';
import RemoveAnalysis from '../SubComponents/RemoveAnalysis';

const StyledPipelineBuilder = styled.div`
    width: 100%;
    display: flex;
    .editor {
        width: 60%;
        margin-right: 20px;
    }
`;

const StyledFormContainer = styled.div`
    margin-top: 20px;
`;

const PipelineBuilder = () => {
    const { pipeline, graphData } = useContext(PipelineBuilderContext);

    const [graph, setGraph] = useState({
        nodes: [],
        links: [],
        ready: false
    });
    const [builderMode, setBuilderMode] = useState('pipeline');

    useEffect(() => {
        if(graphData.ready){
            setGraph({
                nodes: formatNodeObjects([pipeline], graphData.tools, graphData.data.nodes, true),
                links: graphData.data.links,
                ready: true
            });
        }else{
            setGraph({
                nodes: [],
                links: [],
                ready: false
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphData]);

    return(
        <StyledPipelineBuilder> 
            <div className='editor'>
                <PipelineInfo />
                {
                    graphData.ready && 
                    <React.Fragment>
                        <StyledRadioButtonGroup>
                            <div className='radiobutton'>
                                <RadioButton 
                                    inputId="pipeline" 
                                    name="addMode" 
                                    value="pipeline" 
                                    onChange={(e) => setBuilderMode(e.value)} checked={builderMode === 'pipeline'} 
                                />
                                <label htmlFor="pipeline">Build your pipeline tree</label>
                            </div>
                            <div className='radiobutton'>
                                <RadioButton 
                                    inputId="purpose" 
                                    name="addMode" 
                                    value="downstream" 
                                    onChange={(e) => setBuilderMode(e.value)} checked={builderMode === 'downstream'} 
                                    disabled={pipeline.main.nodes.length < 3}
                                    tooltip={pipeline.main.nodes.length < 3 ? 'Add at least one tool node to the pipeline to enable.' : 'Add at least one downstream analysis to submit.'}
                                />
                                <label htmlFor="pipeline">Define/remove downstream analyses</label>
                            </div>
                        </StyledRadioButtonGroup>
                        <StyledFormContainer>
                        {
                            builderMode === 'pipeline' &&
                            <React.Fragment>
                                <StyledSubHeader>Add node to your pipeline:</StyledSubHeader>
                                <AddNode />
                                <StyledSubHeader>Remove node from your pipeline:</StyledSubHeader>
                                <RemoveNode />
                                <StyledSubHeader>Connect/disconnect existing nodes:</StyledSubHeader>
                                <AddRemoveLink />
                            </React.Fragment>
                        }
                        {
                            builderMode === 'downstream' &&
                            <React.Fragment>
                                <StyledSubHeader>Define a downstream analysis:</StyledSubHeader>
                                <DefineAnalysis />
                                <StyledSubHeader>Remove a downstream analysis:</StyledSubHeader>
                                <RemoveAnalysis />
                            </React.Fragment>
                        }
                        </StyledFormContainer>
                    </React.Fragment>
                }
            </div>
            <StyledPipelineCanvas>
                {
                    graph.ready ?
                    <DecisionTree 
                        graph={graph} 
                        filtered={false} 
                        selectedPipeline={'None'} 
                        pipelines={[pipeline]}
                        corePipelines={[]} 
                        width={500}
                        height={600}
                        hideGraphInfo={true}
                        showNodeId={true}
                        zoomOff={true}
                        disableEdgeWidth={true}
                    />
                    :
                    <div className='message'>
                        Enter pipeline information to start building your pipeline.
                    </div>
                }
            </StyledPipelineCanvas>
        </StyledPipelineBuilder>
    );
}

export default PipelineBuilder;