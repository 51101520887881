import React, {useState} from 'react';
import {StyledTabContent} from "./ProfileStyle";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import ComparisonChart from "./AchievementComponents/ComparisonChart";
import styled from "styled-components";
import ContributionTable from "./AchievementComponents/ContributionTable";

const StyledAchievement = styled.div`
  display: flex;
  flex-direction: row;
`;


const AchievementTab = (props) => {
    const { userPipelines, userTools, user } = props;
    const [rerender, setRerender]= useState(false);
    return(
        <StyledTabContent>
            <StyledAchievement>
                <ContributionTable userPipelines = {userPipelines} userTools={userTools} user={user}/>
                <ComparisonChart userPipelines = {userPipelines} userTools={userTools} user={user} rerender={rerender} setRerender={setRerender}/>
            </StyledAchievement>
        </StyledTabContent>
    );
}

export default AchievementTab;
