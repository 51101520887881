import React, { Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import colors from "../../styles/colors";
import { useKeycloak } from "@react-keycloak/web";
import RequestMenu from "../UtilComponents/RequestMenu";

const StyledNavigation = styled.div`
  width: 100%;
  height: 40px;
  border-bottom: 1px solid ${colors.navigation_background};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavigationContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  a {
    text-decoration: none;
  }
`;

const LogoContainer = styled.div`
  height: 70%;
  align-items: center;
  margin-right: 30px;
  img {
    height: 100%;
  }
`;

const StyledLinks = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    .gray {
        color: ${colors.light_gray};
        margin-left: 15px;
    }
    .gray.active {
        color ${colors.hyperlink};
    }
    a:first-child {
        margin-left: 0;
    }
    #bugReport {
        margin-right: 20px;
        background-color: yellow;
        color: #000000;
        padding: 5px 10px;
        border-radius: 5px;
    }
    .submit-pipeline {
        margin-right: 20px;
        background-color: ${colors.button_background_default};
        color: #ffffff;
        padding: 5px 10px;
        border-radius: 5px;
    }
`;

const AuthButton = styled.button`
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  color: #ffffff;
  text-decoration: none;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  background-color: ${colors.button_background_default};
  cursor: pointer;
`;

/**
 * Component for the navigation with links and logo.
 *
 * @component
 * @example
 *
 */
const Navigation = () => {
  const { keycloak, initialized } = useKeycloak();
  const token = keycloak?.authenticated && keycloak?.token;
  window.accessToken = token;

  const renderAuthButton = () => {
    if (keycloak.authenticated) {
      return (
        <AuthButton
          onClick={() => {
            keycloak.logout();
          }}
        >
          Sign Out
        </AuthButton>
      );
    } else {
      return (
        <AuthButton
          onClick={() => {
            keycloak.login();
          }}
        >
          Sign In / Register
        </AuthButton>
      );
    }
  };

  return (
    <StyledNavigation>
      <Container>
        <NavigationContent>
          <LogoContainer>
            <img alt="CoBE" src="/images/logos/logo-square-outline.png" />
          </LogoContainer>
          <StyledLinks>
            <NavLink exact to="/" className="gray" activeClassName="active">
              Home
            </NavLink>
            <NavLink
              exact
              to="/explore"
              className="gray"
              activeClassName="active"
            >
              Explore Pipelines
            </NavLink>
            {initialized
              ? keycloak.authenticated && (
                  <Fragment>
                    <NavLink
                      exact
                      to="/user/profile"
                      className="gray"
                      activeClassName="active"
                    >
                      Profile
                    </NavLink>
                    <NavLink
                      exact
                      to="/pipeline-builder"
                      className="gray"
                      activeClassName="active"
                    >
                      Build Pipelines
                    </NavLink>
                  </Fragment>
                )
              : ""}
            <NavLink
              exact
              to="/tutorial"
              className="gray"
              activeClassName="active"
            >
              Tutorial
            </NavLink>
            <NavLink
              exact
              to="/contact"
              className="gray"
              activeClassName="active"
            >
              Contact
            </NavLink>
            <NavLink exact to="/news" className="gray" activeClassName="active">
              News
            </NavLink>
          </StyledLinks>
        </NavigationContent>
        <NavigationContent>
          <StyledLinks>
            {initialized
              ? keycloak.authenticated && (
                  <NavLink
                    exact
                    to="/submit-pipeline"
                    className="submit-pipeline"
                  >
                    Submit Your Pipeline
                  </NavLink>
                )
              : ""}
            <RequestMenu/>
            {initialized && renderAuthButton()}
          </StyledLinks>
        </NavigationContent>
      </Container>
    </StyledNavigation>
  );
};

export default Navigation;
