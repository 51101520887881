import styled from "styled-components";

const StyledCustomChipButton = styled.button`
  outline: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  background-color: ${(props) => props.color};
  font-size: 12px;
  padding: 3px;
  border-radius: 3px;
`;

const CustomChipButton = (props) => {
  const { label, onClick, color, disabled } = props;
  return (
    <StyledCustomChipButton onClick={onClick} color={color} disabled={disabled}>
      {label}
    </StyledCustomChipButton>
  );
};

export default CustomChipButton;
