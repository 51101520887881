import React, { useState } from "react";
import styled from "styled-components";
import { Dialog } from "primereact/dialog";
import ReactTooltip from "react-tooltip";
import CustomChip from "./CustomChip";
import CustomChipButton from "./CustomChipButton";
import { getChipColor, onChipClick } from "../../utils/helper";
import colors from "../../styles/colors";

const StyledChipGroup = styled.div`
  .executable-link {
    margin: 2px;
  }
  .execution_link_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .execution_link {
    text-decoration: none;
    font-size: 14px;
    padding: 3px;
    border-radius: 5px;
    color: #ffffff;
    background-color: ${colors.executableCodeOcean};
  }
`;

const ChipGroup = (props) => {
  const {
    executableInfo,
    executableLinks,
    core,
    disabled,
    executableType,
    showHPC,
  } = props;
  const user = undefined;

  const [visible, setVisible] = useState(false);

  let vpc = executableLinks.find((link) => link.linkType === "CodeOceanVPC");
  let saas = executableLinks.find((link) => link.linkType === "CodeOcean");

  if (vpc && saas) {
    const linkStyle = {
      textDecoration: "none",
      fontSize: "12px",
      padding: "5px",
      borderRadius: "3px",
      color: "#ffffff",
      backgroundColor: colors.executableCodeOcean,
    };
    return (
      <StyledChipGroup>
        <ReactTooltip id="codeocean_button" />
        <div
          data-tip={
            disabled
              ? "Sign in or register to access this feature."
              : "Execute on CodeOcean"
          }
          data-for={"codeocean_button"}
          data-place="bottom"
          data-effect="solid"
        >
          <CustomChipButton
            onClick={() => {
              console.log("clicked");
              setVisible(true);
            }}
            label="CodeOcean"
            color={getChipColor("CodeOcean")}
          />
        </div>
        <Dialog
          visible={visible}
          header="Select your execution platform"
          onHide={() => {
            setVisible(false);
          }}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "30vw" }}
        >
          <div>
            <div>You have two options to execute this CodeOcean capsule:</div>
            <div
              className="execution_link_container"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <a
                className="execution_link"
                href={vpc.executableLink}
                target="_blank"
                rel="noopener noreferrer"
                style={linkStyle}
              >
                Execute on UHN's Private Cloud
              </a>
              <a
                className="execution_link"
                href={saas.executableLink}
                target="_blank"
                rel="noopener noreferrer"
                style={linkStyle}
              >
                Execute on the Public Platform
              </a>
            </div>
          </div>
        </Dialog>
      </StyledChipGroup>
    );
  }

  return (
    <StyledChipGroup>
      {executableLinks && executableLinks.length > 0 ? (
        <React.Fragment>
          {executableLinks.map((linkObj, i) => {
            if (!showHPC && linkObj.linkType === "HPC") {
              return "";
            }
            return (
              <CustomChip
                id={`${Math.floor(Math.random() * 100)}-${i}`}
                key={i}
                className="executable-link"
                label={linkObj.linkType === 'HPC' ? 'GitHub' : linkObj.linkType}
                tooltipText={`Link to the ${executableType} executable on ${
                  linkObj.linkType === 'HPC' ? 'GitHub' : linkObj.linkType
                }`}
                tooltipPlace="right"
                link={linkObj.executableLink}
                disabled={disabled}
                fontSize="12px"
                color={getChipColor(linkObj.linkType)}
                onClick={() => {
                  onChipClick(executableInfo, linkObj, user);
                }}
              />
            );
          })}
        </React.Fragment>
      ) : (
        ""
      )}
      {core ? (
        <CustomChip
          id={`${Math.floor(Math.random() * 100)}`}
          className="executable-link"
          label="Service"
          tooltipText="This pipeline and downstream analyses are provided as a service"
          tooltipPlace="right"
          link={core}
          fontSize="12px"
          color={getChipColor("service")}
          onClick={() => {
            onChipClick(executableInfo, undefined, user);
          }}
        />
      ) : (
        ""
      )}
    </StyledChipGroup>
  );
};

export default ChipGroup;
