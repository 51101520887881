import React from 'react';
import styled from 'styled-components';
import colors from '../../../styles/colors';
import { StyledLink } from '../../../styles/utils';

const StyledMatchedAnalysis = styled.div`
    width: 30%;
    min-width: 300px;
    font-size: 12px;
    margin-bottom: 20px;
    margin-right: 20px;
    .section {
        .header {
            font-size: 14px;
            font-weight: bold;
            margin-top: 5px;
            margin-bottom: 5px;
        }
        .content{
            margin-top: 10px;
            margin-left: 10px;
            overflow-y: auto;
            position:relative;
            max-height: 50vh;
            .list-title {
                font-weight: bold;
            }
            li {
                margin-bottom: 10px;
            }
            .highlight {
                color: ${colors.edgeHighlight};
            }
        }
    }
`;

const MatchedAnalysis = (props) => {
    const { steps, purposes } = props;

    let nodes = [];
    purposes.forEach(purpose => {
        nodes = nodes.concat(purpose.nodes);
    });
    nodes = nodes.map(node => node.name);
    return(
        <StyledMatchedAnalysis>
            {
                steps.length > 0 &&
                <div className='section'>
                    <div className='header'>Matched Pipeline Step(s)</div>
                    <div className='content'>
                        <ul>
                            {
                                steps.map((step, i) =>
                                    <li key={i}>
                                        <StyledLink to={`/tools/${step.toolId}`} target='_blank'>
                                            <span className={'highlight'}>{step.name}</span>
                                        </StyledLink>
                                        - {step.purpose}
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            }
            {
                purposes.length > 0 &&
                <div className='section'>
                    <div className='header'>Matched Downstream Analyses</div>
                    <div className='content'>
                    {
                        purposes.map((purpose, i) =>
                            <div key={i}>
                                <div className='list-title'>{purpose.text}</div>
                                <ul>
                                    {
                                        purpose.nodes.map((node, i) =>
                                            <li key={i}>
                                                <StyledLink to={`/tools/${node.toolId}`} target='_blank'>
                                                    <span className='highlight'>{node.name}</span>
                                                </StyledLink>
                                                 - {node.purpose}
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        )
                    }
                    </div>
                </div>
            }
        </StyledMatchedAnalysis>
    );
}

export default MatchedAnalysis;
