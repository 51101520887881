import styled from 'styled-components';
import colors from './colors';

const StyledReport = styled.div`
    fontFamily: Noto Sans, sans-serif;
    fontSize: 12px; 
    color: ${colors.gray_text};
    .title {
        display: flex;
        align-items: center;
        .chip {
            margin-left: 5px;
        }
    }
    .report-content {
        margin-left: 30px;
    }
    .header-line {
        margin-bottom: 15px;
    }
`;

const StyledReportSection = styled.div`
    margin-bottom: 30px;
    .section-title {
        margin-bottom: 30px;
        font-size: 14px;
        .chip {
            margin-left: 5px;
        }
    }
`;

const ReportList = styled.ol`
    counter-reset: li;

    li {
        position: relative;
        list-style-type: none;
    }

    li:before {
        content: counter(li)'.';
        counter-increment: li;
        position: absolute;
        margin-right: 10px;
        left: -20px;
        font-weight: bold;
    }
`;

const StyledReportNode = styled.div`
    margin-bottom: 20px;
    .header {
        margin-top: -5px;
        display: flex;
        align-items: center;
        b {
            margin-right: 10px;
        }
    }
    .node-content {
        margin-top: 5px;
        margin-left: 20px;
        .node-content-line {
            margin-bottom: 5px;
        }
    }
`;

export {
    StyledReport,
    StyledReportSection,
    ReportList,
    StyledReportNode
}