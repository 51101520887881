import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import * as dagreD3 from "dagre-d3";
import GraphContainer from "../../styles/GraphContainer";
import DecisionTreeDialog from "./DecisionTreeDialog";
import { toolType } from "../../utils/constants";
import {
  getExecutablePipelineEdges,
  highlightPipeline,
  getNode,
  assignEdgeWidth,
  assignEdgeColors,
  getNodeInfo,
} from "../../utils/format-tree";

const margin = {
  top: 20,
  right: 20,
  bottom: 20,
  left: 20,
};

const DecisionTree = (props) => {
  const {
    graph,
    pipelines,
    filtered,
    selectedPipeline,
    highlightedTools,
    width,
    height,
    hideGraphInfo,
    showNodeId,
    zoomOff,
    disableEdgeWidth
  } = props;

  const [nodeDialogVisible, setNodeDialogVisible] = useState(false);
  const [clickedNode, setClickedNode] = useState(undefined);
  const [pipelinesWithClickedNode, setPipelinesWithClickedNode] = useState([]);

  // Get edges that are part of executable  or service (core) pipelines.
  // Used to give different edge color for exeutable routes.
  const executablePipelines = pipelines.filter(
    (item) => item.main.executableLinks.length > 0 || item.core
  );
  useEffect(() => {
    if (graph.nodes.length > 0) {
      draw();
    } else {
      d3.select(`#graph`).selectAll("*").remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graph, pipelines]);

  useEffect(() => {
    let executableEdges = getExecutablePipelineEdges(
      executablePipelines,
      graph.links
    );
    highlightPipeline(d3, selectedPipeline, pipelines, executableEdges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPipeline]);

  const draw = () => {
    // variables used to keep track of graph states
    let zoomGroup = null;

    let g = new dagreD3.graphlib.Graph()
      .setGraph({
        rankdir: "TB", //filtered ? "LR" : "TB",
        marginx: 20,
        marginy: 20,
      })
      .setDefaultEdgeLabel(() => ({}));

    // Add nodes to the graph. The first argument is the node id. The second is
    // metadata about the node.
    for (let node of graph.nodes) {
      let nodeData = getNode(node, showNodeId);
      if (
        Array.isArray(highlightedTools) &&
        highlightedTools.includes(nodeData.name)
      ) {
        nodeData.class = "highlightTool";
        nodeData.colors = {
          default: "#ff4d4d",
          hovered: "#ff8080",
          clicked: "#ff4d4d",
        };
      }
      g.setNode(node.id, nodeData);
    }

    // Add edges to the graph.
    graph.links.forEach((link) => {
      g.setEdge(link.source, link.target, { curve: d3.curveBasis });
    });

    let renderDagre = new dagreD3.render();

    // set svg dim
    let svg = d3.select(`#graph`);
    svg.selectAll("*").remove(); // redraw every time the width changes
    svg.attr("width", width).attr("height", height);

    // add zoom
    let zoomScale = 1;
    let transform = d3.zoomIdentity
      .translate(margin.left, margin.top)
      .scale(zoomScale); // initial view
    let zoom = d3.zoom().on("zoom", handleZoom);

    // call
    if (!zoomOff) {
      svg.call(zoom).call(zoom.transform, transform);
    }

    // add group for zoom
    zoomGroup = svg
      .append("g")
      .attr("class", "zoomGroup")
      .attr("transform", transform);

    // draws the graph
    renderDagre(zoomGroup, g);
    let nodes = zoomGroup.selectAll(".node");
    if(!disableEdgeWidth){
      assignEdgeWidth(d3, pipelines);
    }

    // Assign different color to edges that are part of the executable pipeline routes.
    let executableEdges = getExecutablePipelineEdges(
      executablePipelines,
      graph.links
    );
    assignEdgeColors(d3, executableEdges);

    // display/hide tool tips on hover
    if (!hideGraphInfo) {
      nodes.on("mouseenter", (d, i) => {
        let hovered = g.node(d);
        d3.select(nodes.nodes()[i])
          .select(g.node(d).shape)
          .style("fill", hovered.colors.hovered)
          .style("stroke", hovered.colors.default);

        let tooltip = d3.select("#tooltip");

        tooltip.select("#tooltip-nodeName").html(hovered.name);
        tooltip.select("#tooltip-nodeInfo").html(getNodeInfo(hovered));
        tooltip.select("#tooltip-nodeType").html(hovered.type);

        tooltip
          .style("z-index", 999)
          .style("max-height", "0px")
          .style("opacity", 0.8)
          .transition()
          .ease(d3.easeCubicOut)
          .duration(1000)
          .style("max-height", "100%");
      });

      nodes.on("mouseleave", (d, i) => {
        let hovered = g.node(d);
        d3.select(nodes.nodes()[i])
          .select(hovered.shape)
          .style(
            "fill",
            Array.isArray(highlightedTools) &&
              highlightedTools.includes(hovered.name)
              ? "#ff8080"
              : "#ffffff"
          )
          .style("stroke", hovered.colors.default);
        d3.select("#tooltip")
          .transition()
          .ease(d3.easeCubicOut)
          .duration(500)
          .style("max-height", "0px")
          .style("opacity", 0);
      });
    }
    // display dialog on click
    nodes.on("click", (d, i) => {
      let clicked = g.node(d);
      if (toolType.includes(clicked.type)) {
        // find pipelines the clicked tool belongs to
        let pipelinesWithClicked = pipelines.filter((pipeline) => {
          if (pipeline.main.nodes.find((node) => node.id === d)) {
            return true;
          }
          for (const purpose of pipeline.purposes) {
            if (purpose.nodes.find((node) => node.id === d)) {
              return true;
            }
          }
          return false;
        });
        setPipelinesWithClickedNode(pipelinesWithClicked);
        setClickedNode(clicked);
        setNodeDialogVisible(true);
      } else if (clicked.type === "datatype") {
        let pipelinesWithClicked = pipelines.filter((pipeline) =>
          pipeline.datatype.includes(clicked.name)
        );
        setPipelinesWithClickedNode(pipelinesWithClicked);
        setClickedNode(clicked);
        setNodeDialogVisible(true);
      }
    });

    // fit entire graph to canvas (default: 1200 x 600)
    zoomScale = Math.min(
      (width - margin.left) / g.graph().width,
      (height - margin.top) / g.graph().height
    );
    zoomScale = Math.floor(zoomScale * 100) / 100;

    zoom.scaleExtent([zoomScale, 2]);

    // let translateX = filtered
    //   ? margin.left
    //   : (width - g.graph().width * zoomScale) / 2;
    let translateX = (width - g.graph().width * zoomScale) / 2;
    let translateY = (height - g.graph().height * zoomScale - margin.top) / 2;

    let graphBox = zoomGroup.node().getBBox();
    let topLeft = [graphBox.x - margin.left, graphBox.y - margin.top];
    let bottomRight = [
      graphBox.x + graphBox.width + margin.right,
      graphBox.y + graphBox.height + margin.bottom,
    ];

    zoom.translateExtent([topLeft, bottomRight]);

    svg
      .transition()
      .duration(750)
      .call(
        zoom.transform,
        d3.zoomIdentity.translate(translateX, translateY).scale(zoomScale)
      );

    function handleZoom() {
      if (zoomGroup) {
        zoomGroup.attr("transform", d3.event.transform);
      }
    }
  };

  return (
    <GraphContainer id="graphContainer" width={width} height={height}>
      <svg id="graph"></svg>
      {!hideGraphInfo && (
        <div id="graphinfo">
          <div className="line">Scroll to zoom in/out</div>
          <div className="line legend">
            <span className="marker">
              <div className="node-circle executable-codeocean"></div>
            </span>
            <span className="label">Executable on Code Ocean or WebApp</span>
          </div>
          <div className="line legend">
            <span className="marker">
              <div className="node-circle pipeline"></div>
            </span>
            <span className="label">Available on GitHub</span>
          </div>
          <div className="line legend">
            <span className="marker">
              <div className="node-circle service-pipeline"></div>
            </span>
            <span className="label">
              Pipeline and analyses available as a service
            </span>
          </div>
          <div className="line legend">
            <span className="marker">
              <div className="node-circle datatype"></div>
            </span>
            <span className="label">Processed data type</span>
          </div>
          <div className="line legend">
            <span className="marker">
              <div className="node-rect database"></div>
            </span>
            <span className="label">Database</span>
          </div>
          <div className="line legend">
            <span className="marker">
              <div className="node-circle default"></div>
            </span>
            <span className="label">Pending for executables</span>
          </div>
        </div>
      )}
      {!hideGraphInfo && (
        <div id="tooltip" className="tooltip">
          <div id="tooltip-nodeName"></div>
          <div id="tooltip-nodeInfo"></div>
          <div id="tooltip-nodeType"></div>
        </div>
      )}
      {
        <DecisionTreeDialog
          visible={nodeDialogVisible}
          clickedNode={clickedNode}
          pipelinesWithClickedNode={pipelinesWithClickedNode}
          onHide={() => {
            setNodeDialogVisible(false);
            setClickedNode(undefined);
          }}
        />
      }
    </GraphContainer>
  );
};

export default DecisionTree;
