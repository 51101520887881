import React from 'react';
import CustomTooltip from './CustomTooltip';
import styled from 'styled-components';
import colors from '../../styles/colors';

const StyledInputText = styled.input`
    width: ${props => props.width ? props.width: '100%'};
    padding: 0.5rem 0.4rem;
    border: 1px solid ${colors.border_gray};
    border-radius: 3px;
    font-size: 12px;
    color: ${colors.gray_text};
    :focus {
        border: 1px solid ${colors.hyperlink};
        outline: none;
        box-shadow: 0 0 0 3px ${colors.form_focused};
    }
`;

const CustomInputText = (props) => {

    const {id, className, value, type, onChange, width, onKeyDown, placeholder, tooltip} = props;

    return(
        <React.Fragment>
            <StyledInputText 
                className={className}
                type={type}
                value={value}
                onChange={onChange}
                width={width}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                data-tip 
                data-for={id}
            />
            {
                tooltip && tooltip.length > 0 &&
                <CustomTooltip id={id} tooltip={tooltip} place="right" effect="solid" />
            }
            
        </React.Fragment>
    );
}

export default CustomInputText;