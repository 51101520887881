import { Chart } from 'primereact/chart';
import { Calendar } from 'primereact/calendar';
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import styled from "styled-components";
import React, {Fragment, useEffect, useState} from "react";
import colors from "../../../../../styles/colors";
import Loading from "../../../../UtilComponents/Loading";
import axios from "axios";


const StyledDiagram= styled.div`
  margin: 10px 20px;
  font-size: 12px;
  font-weight: bold;
  max-width: 50vw;
`;

const getLightTheme = () => {
    let basicOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef',
                }
            },
            y: {
                ticks: {
                    color: '#495057',
                },
                grid: {
                    color: '#ebedef',
                }
            }
        }
    };
    return {
        basicOptions,
    }
}


const StyledStats = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  width: 100%;
	.header {
		align-self: flex-start;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		margin: 0px 20px 10px 20px;
		.date-picker {
			margin-right: 20px;
		}
	}
`;

const ComparisonChart = (props) => {
    let { userPipelines, userTools, user } = props;
    const [ userSubmission, setUserSubmission] = useState({tools: [], pipelines: [], tools_ave: 0, pipelines_ave: 0, ready: false})
    const [ userExpert, setUserExpert] = useState({tools: [], pipelines: [], tools_ave: 0, pipelines_ave: 0, ready: false});
    const [ userLikes, setUserLikes] = useState({tools: [], pipelines: [], tools_ave: 0, pipelines_ave: 0, ready: false});
    const minDate = new Date(2022, 0, 1);
    const maxDate = new Date();
    const [startDate, setStartDate] = useState(minDate);
    const [endDate, setEndDate] = useState(maxDate);
    const { basicOptions} = getLightTheme();
    const [ basicData , setBasicData]= useState ({labels: ['Submissions', 'Likes', 'Expertise'],
        datasets: [
            { label: 'Individual', backgroundColor: `${colors.light_blue}`, data: [] },
            { label: 'Average', backgroundColor: `${colors.dark_blue}`, data: []}
        ]},)

    useEffect(() => {
        const initialize = async () => {
            const expertise = await axios.get('/api/data/expert/user-stats', {
                params: {
                    email: user.email,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                    average: true
                }});

            const likes = await axios.get('/api/data/like/user-likes', {
                params: {
                    email: user.email,
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                    average: true
                }});

            const submission_ave = await axios.get('/api/users/avg_submit', {
                params: {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString()
                }});

            if (expertise) setUserExpert({
                tools: expertise.data.tools || [],
                pipelines: expertise.data.pipelines|| [],
                tools_ave: expertise.data.tools_ave|| 0,
                pipelines_ave: expertise.data.pipelines_ave|| 0,
                ready: true});

            if (likes) setUserLikes({
                tools: likes.data.likes.tools|| [],
                pipelines: likes.data.likes.pipelines|| [],
                tools_ave: likes.data.tools_ave || 0,
                pipelines_ave: likes.data.pipelines_ave || 0,
                ready: true});

            // Assign submit date to pipelines and tools without submission date
            if (submission_ave) setUserSubmission(
                {
                    tools: userTools.map(item => {
                        let dateSubmitted = item.dateSubmitted ? item.dateSubmitted : new Date(2022, 0, 1);
                        if(typeof dateSubmitted === 'string'){
                            dateSubmitted = new Date(dateSubmitted);
                        }
                        if (dateSubmitted.getTime() >= startDate.getTime() && dateSubmitted.getTime() <= endDate.getTime())
                            return ({
                            ...item, dateSubmitted});
                        else return({});
                    }).filter(item=> Object.values(item).length),
                    pipelines: userPipelines.map(item => {
                        let dateSubmitted = item.dateSubmitted? item.dateSubmitted : new Date(2022, 0, 1);
                        if(typeof dateSubmitted === 'string'){
                            dateSubmitted = new Date(dateSubmitted);
                        }
                        if( dateSubmitted.getTime() >= startDate.getTime() && dateSubmitted.getTime() <= endDate.getTime())
                            return ({ ...item, dateSubmitted });
                        else return({});
                    }).filter(item=> Object.values(item).length),
                    tools_ave: submission_ave.data.tools_ave|| 0,
                    pipelines_ave: submission_ave.data.pipelines_ave|| 0,
                    ready: true
                }
            )

        }
        initialize();
    }, [user.email,startDate,endDate]);

    useEffect(() => {
        setBasicData({
            labels: [
                'Submitted Pipelines',
                'Submitted Tools',
                'Liked Pipelines',
                'Liked Tools',
                'Expert on Pipelines',
                'Expert on Tools'
            ],
            datasets: [
                {
                    label: 'Individual',
                    backgroundColor: `${colors.light_blue}`,
                    data:
                        [
                            userSubmission.pipelines.length,
                            userSubmission.tools.length,
                            userLikes.pipelines.length,
                            userLikes.tools.length,
                            userExpert.pipelines.length,
                            userExpert.tools.length
                        ]
                },
                {
                    label: 'Average',
                    backgroundColor: `${colors.dark_blue}`,
                    data:
                        [
                            userSubmission.pipelines_ave,
                            userSubmission.tools_ave,
                            userLikes.pipelines_ave,
                            userLikes.tools_ave,
                            userExpert.pipelines_ave,
                            userExpert.tools_ave
                        ]
                }
            ]
        })
    }, [userLikes, userExpert, userPipelines, userTools, userSubmission]);

    return(
        <StyledStats>
            <div className='header'>
                <div className='date-picker'>
                    <span>Start: </span>
                    <Calendar
                        dateFormat="yy-mm-dd"
                        value={startDate}
                        minDate={minDate}
                        maxDate={endDate}
                        onChange={(e) => setStartDate(e.value)}
                        disabled={!userLikes.ready}
                    />
                 </div>
                <div className='date-picker'>
                    <span>End: </span>
                    <Calendar
                        dateFormat="yy-mm-dd"
                        value={endDate}
                        minDate={startDate}
                        maxDate={maxDate}
                        onChange={(e) => setEndDate(e.value)}
                        disabled={!userLikes.ready}
                    />
                </div>
            </div>
            {
                userLikes.ready ?
                    <Fragment>
                        <StyledDiagram>
                            <div className="label">{`Contributions to CoBE - Individual vs Average`}</div>
                            <Chart type="bar" data={basicData} options={basicOptions}/>
                        </StyledDiagram>
                    </Fragment>
                    :
                    <Loading />
            }
        </StyledStats>
    )
}


export default ComparisonChart;
