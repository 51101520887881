import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { PipelineBuilderContext } from '../../../../hooks/Contexts';

import CustomButton from '../../../UtilComponents/CustomButton';
import CustomDropdown from '../../../UtilComponents/CustomDropdown';
import CustomMultiSelect from '../../../UtilComponents/CustomMultiSelect';
import CustomMessages from '../../../UtilComponents/CustomMessages';
import { genericError, addAnalysisSuccess, addAnalysisWarning } from '../../../../utils/messages';
import { StyledBuilderForm } from '../BuildPipelineStyles';
import { toolType } from '../../../../utils/constants';
import { getNodeIdLabel } from '../../../../utils/helper';

const DefineAnalysis = () => {
    const { pipeline, setPipeline, setGraphData } = useContext(PipelineBuilderContext);
    const [purposeOptions, setPurposeOptions] = useState([]);
    const [nodeOptions, setNodeOptions] = useState([]);
    const [newPurpose, setNewPurpose] = useState({text: '', nodes: []});
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        const initialize = async () => {
            const res = await axios.get(`/api/data/pipeline/purposes?datatype=${pipeline.datatype}`);
            setPurposeOptions(res.data.options);
            
        }
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let allnodes = pipeline.main.nodes;
        pipeline.purposes.forEach(purpose => {
            allnodes = allnodes.concat(purpose.nodes);
        });
        allnodes = allnodes
            .filter(node => toolType.includes(node.type))
            .map(node => ({label: `${node.name} - ${getNodeIdLabel(node.id)}`, id: node.id}));
        allnodes.sort((a, b) => a.label.localeCompare(b.name));
        setNodeOptions(allnodes);
    }, [pipeline]);

    const defineAnalysis = (e) => {
        e.preventDefault();
        try{
            if(!pipeline.purposes.find(purpose => purpose.text === newPurpose.text)){
                let allnodes = [...pipeline.main.nodes];
                pipeline.purposes.forEach(purpose => {
                    allnodes = allnodes.concat([...purpose.nodes]);
                });
                let purposeIds = newPurpose.nodes.map(node => node.id);
                let purposes = [...pipeline.purposes];
                purposes.push({
                    executableLinks: [],
                    text: newPurpose.text,
                    nodes: allnodes.filter(node => purposeIds.includes(node.id))
                });
                setPipeline(prev => ({
                    ...prev,
                    main: {
                        ...prev.main,
                        nodes: pipeline.main.nodes.filter(node => !purposeIds.includes(node.id))
                    },
                    purposes: purposes,
                    changeGraph : true
                }));
                setGraphData(prev => ({
                    ...prev,
                    analysisAdded: true
                }));
                setMessage(addAnalysisSuccess);
            }else{
                setMessage(addAnalysisWarning);
            }
            setNewPurpose({text: '', nodes: []});
        }catch(err){
            console.log(err);
            setMessage(genericError);
        }finally{
            setShowMessage(Math.random());
        }
    }

    const selectedItemTemplate = (option) => {
        if (option) {
            return (<span className='selected-item'>{option.label}</span>);
        }
        return "Select...";
    }

    return(
        <StyledBuilderForm>
            <CustomMessages trigger={showMessage} message={message} />
            <div className='formRow'>
                <span className='label'>Analysis Purpose: </span>
                <CustomDropdown 
                    className='input'
                    value={newPurpose.text}
                    options={purposeOptions}
                    onChange={(e) => {setNewPurpose({text: e.value, nodes: []})}}
                    placeholder='Select from available options or enter a new purpose'
                    filter={true}
                    editable
                />
            </div>
            <div className='formRow'>
                <span className='label'>Downstream Analysis Tools: </span>
                <CustomMultiSelect
                    className='input'
                    value={newPurpose.nodes}
                    options={nodeOptions}
                    optionLabel='label'
                    selectedItemTemplate={selectedItemTemplate}
                    onChange={(e) => {setNewPurpose({...newPurpose, nodes: e.value})}}
                    placeholder='Select node(s) that belong to this downstream analysis'
                    filter={true}
                    editable
                    disabled={newPurpose.text.length === 0}
                />
            </div>
            <div className='buttonGroup'>
                <CustomButton 
                    className='p-button-sm p-button-primary'
                    label='Add Downstream Analysis' 
                    onClick={defineAnalysis} 
                    disabled={newPurpose.text.length === 0 || newPurpose.nodes.length === 0}
                />
            </div>
        </StyledBuilderForm>
    );
}

export default DefineAnalysis;