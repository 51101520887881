import React from 'react';
import { SubContainer } from '../TutorialStyles';
import YoutubeEmbed from './YoutubeEmbed';

const Search = () => {
    return(
        <SubContainer>
            <h1>Search for Pipelines and Tools</h1>
            <h3>Pipelines and tools documented in CoBE can be explored in three ways:</h3>
            <div className='section'>
                <ol>
                    <li>Search by a processed data type.</li>
                    <li>Search by a query.</li>
                    <li>Filter pipelines by using pipeline wizard.</li>
                </ol>
                The following video demonstrates these use cases.
            </div>
            <div className='section video'>
                <YoutubeEmbed embedId='13sqNP-NX1U' />
            </div>
        </SubContainer>
    );
}

export default Search;