import React, {useEffect, useState} from 'react';
import axios from "axios";
import styled from 'styled-components';
import colors from '../../../../styles/colors';
import ReactLoading from 'react-loading';

const StyledContainer = styled.div`
  width: 100%;
  border: 1px solid ${colors.border_gray};
  margin: 5px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  justify-content: center;
  min-height: 120px;
`;

const StyledStats= styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 3;
  
    .number {
        font-size: 18px;
        font-weight: bold;
    }
    .label {
        margin-top: 5px;
        font-size: 14px;
    }
`;

const Stats = () => {
    const [stats, setStats] = useState({"Datatypes": 0 , "Pipelines": 0, "CoBE Tools": 0, "Total Tools": 0, "Users": 0});
    const [ready, setReady] = useState(false);

    const initialize = async () => {
        const res = await axios.get('/api/data/stats/total');
        const {users_num, total_tools_num, datatypes_num, pipelines_num, tools_num } = res.data; //tool/pipelines are sorted by weight_scores
        if (pipelines_num) {
            setStats({
                "Datatypes": datatypes_num,
                "Pipelines": pipelines_num,
                "CoBE Tools": tools_num,
                "Total Tools": total_tools_num,
                "Users": users_num,
            });
            setReady(true);
        }
    }

    useEffect(() => {
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <StyledContainer>
        {
            ready ?
            Object.keys(stats).map((item, i) => (
                <StyledStats key = {i}>
                    <span className="number">{stats[item]}</span>
                    <div className="label">{item}</div>
                </StyledStats>
            ))
            :
                <ReactLoading type="bubbles" color={colors.light_gray} height={75} width={75}/>
        }
        </StyledContainer>
    );
}

export default Stats;

