import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from '../keycloak';

import {
  Home,
  Tutorial,
  Contact,
  News,
  IndividualNews,
  ExplorePipelines,
  BuildPipeline,
  Profile,
  EditMain,
  IndividualTools,
  IndividualPipeline,
  SubmitPipeline
} from '../Components/index';

import PrivateRoute from './PrivateRoute';

const eventLogger = (event, error) => {
  console.log('onKeycloakEvent', event, error);
}

const Routes = () => {
  return(
    <Router>
      <ReactKeycloakProvider 
        authClient={keycloak} 
        onEvent={eventLogger}
        initOptions={{checkLoginIframe: false}}
      >
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/tutorial' exact component={Tutorial} />
          <Route path='/contact' exact component={Contact} />
          <Route path='/news' exact component={News} />
          <Route path='/news/:token' exact component={IndividualNews} />
          <Route path='/explore' exact component={ExplorePipelines} />
          <Route path='/pipeline/:id' component={IndividualPipeline} />
          <Route path='/tools/:id' component={IndividualTools} />
          <Route path='/submit-pipeline' component={SubmitPipeline} />
          <PrivateRoute path='/pipeline-builder' exact component={BuildPipeline} />
          <PrivateRoute path='/user/profile' exact component={Profile} />
          <PrivateRoute path='/edit/:type/:id' component={EditMain} />
        </Switch>
      </ReactKeycloakProvider>
    </Router>
  );
}

export default Routes;
