import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import CustomDropdown from '../../../UtilComponents/CustomDropdown';
import CustomMultiSelect from '../../../UtilComponents/CustomMultiSelect';
import CustomButton from '../../../UtilComponents/CustomButton';
import PipelineNavigation from '../../ExplorePipelines/PipelineNavigation';

const StyledWizardContainer = styled.div`
    width: 50%;
    max-width: 550px;
    min-width: 350px;
    .title {
        font-weight: bold;
        font-size: 16px;
    }
    .filterElement {
        margin-top: 20px;
        margin-bottom: 20px;
        .label {
            font-size: 14px;
        }
        .dropdown {
            width: 300px;
        }
        .purpose-dropdown {
            width: 100%;
            margin-top: 10px;
        }
    }
    .horizontal {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .vertical {

    }
    .button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
`;

const PipelineWizard = () => {
    const [data, setData] = useState({
        graph: {},
        routes: [],
        filtered: false,
        ready: false
    });

    const [datatypeDropdown, setDatatypeDropdown] = useState({
        selected: '',
        options: [],
        disabled: false
    });

    const [purposeDropdown, setPurposeDropdown] = useState({
        selected: [],
        options: [],
        disabled: true
    });

    const initialize = async () => {
        const res = await axios.get('/api/view/decision-tree');
        setDatatypeDropdown(prev => ({
            ...prev, 
            selected: '',
            options: res.data.datatypeOptions
        }));
    }

    const findPipelines = async (e) => {
        e.preventDefault();
        const res = await axios.post('/api/data/graph/filter', {
                datatype: datatypeDropdown.selected,
                purposes: purposeDropdown.selected,
        });
        console.log(res.data)
        res.data.pipelines.sort((a, b) => {
            return(b.score - a.score);
        });
        setData({
            ...res.data,
            filtered: true,
            ready: true
        });
    };

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        const update = async () => {
            let dropdown = {selected: [], options: [], disabled: true};
            if(datatypeDropdown.selected !== ''){
                const res = await axios.get(`/api/data/pipeline/purposes?datatype=${encodeURIComponent(datatypeDropdown.selected)}`);
                dropdown = {...dropdown, options: res.data.options, disabled: false};
            }
            setPurposeDropdown(dropdown);
            setData({
                graph: {},
                pipelines: [],
                filtered: false,
                ready: false
            });
        }
        update();
    }, [datatypeDropdown.selected]);

    return(
        <React.Fragment>
            <StyledWizardContainer>
                <div className='filterElement horizontal'>
                    <span className='label'>Select a data type to be processed: </span>
                    <CustomDropdown 
                        className='dropdown'
                        value={datatypeDropdown.selected}
                        options={datatypeDropdown.options}
                        filter={true}
                        onChange={(e) => {setDatatypeDropdown(prev => ({...prev, selected: e.value}))}}
                        placeholder='Select...'
                    />
                </div>
                {
                    datatypeDropdown.selected.length > 0 &&
                    <div className='filterElement vertical'>
                        <div className='label'>Select downstream analyses you would like to perform: </div>
                        <CustomMultiSelect 
                            className='purpose-dropdown '
                            value={purposeDropdown.selected}
                            options={purposeDropdown.options}
                            filter={true}
                            onChange={(e) => {setPurposeDropdown(prev => ({...prev, selected: e.value}))}}
                            placeholder='Select...'
                        />
                    </div>
                }
                {
                    datatypeDropdown.selected.length > 0 && purposeDropdown.selected.length > 0 &&
                    <div className='filterElement button'>
                        <CustomButton 
                            className='p-button-sm' 
                            label='Find Pipelines' 
                            onClick={findPipelines} 
                        />
                    </div>
                }
            </StyledWizardContainer>
            {
                data.ready && <PipelineNavigation data={data} />
            }
        </React.Fragment>
    );
}

export default PipelineWizard;