import Keycloak from 'keycloak-js';

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keycloak = new Keycloak('/data/keycloak.json');
// keycloak.init({
//   checkLoginIframe: false
// })
// const keycloak = new Keycloak({
//     "realm": "pmcobe",
//     "auth-server-url": "https://oa.pmgenomics.ca/auth",
//     clientId: 'cobe-frontend',
//     checkLoginIframe: false
// });

export default keycloak;