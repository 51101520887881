import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import { PipelineBuilderContext } from '../../../../hooks/Contexts';
import { StyledBuilderForm } from '../BuildPipelineStyles';
import CustomDropdown from '../../../UtilComponents/CustomDropdown';
import CustomButton from '../../../UtilComponents/CustomButton';
import CustomCreatableMultiSelect from '../../../UtilComponents/CustomCreatableMultiSelect';
import CustomAutoComplete from '../../../UtilComponents/CustomAutoComplete';
import CustomMessages from '../../../UtilComponents/CustomMessages';
import RequiredField from '../../../UtilComponents/RequiredField';
import { genericError } from '../../../../utils/messages';
import { getNodeIdLabel } from '../../../../utils/helper';

const AddNode = () => {
    const { graphData, setGraphData, pipeline, setPipeline } = useContext(PipelineBuilderContext);

    const [newNode, setNewNode] = useState(null);
    const [tool, setTool] = useState('');
    const [newEdgeSource, setNewEdgeSource] = useState(null);
    const [suggestions, setSuggestions] = useState([]);
    const [versionDropdown, setVersionDropdown] = useState({selected: '', options: []});
    const [purposeDropdown, setPurposeDropdown] = useState({selected: '', options: []});
    const [inputDropdown, setInputDropdown] = useState({selected: [], options: []});
    const [outputDropdown, setOutputDropdown] = useState({selected: [], options: []});
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if(tool && typeof tool === 'object' && tool.name.length > 0){
            const getToolOptions = async() => {
                let res = await axios.get(`/api/view/pipeline-builder/tool?name=${tool.name}`);
                console.log(res.data);
                setNewNode({toolName: tool.name});
                setVersionDropdown({...versionDropdown, options: res.data.versions});
                setPurposeDropdown({...purposeDropdown, options: res.data.purposes.map(purpose => ({label: purpose.text, value: purpose.text}))});
                setInputDropdown({...inputDropdown, options: res.data.input});
                setOutputDropdown({...outputDropdown, options: res.data.output});
            }
            getToolOptions();
        }else{
            setVersionDropdown({selected: '', options: []});
            setPurposeDropdown({selected: '', options: []});
            setInputDropdown({selected: [], options: []});
            setOutputDropdown({selected: [], options: []});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tool]);

    useEffect(() => {
        if(
            versionDropdown.selected.length > 0 || 
            purposeDropdown.selected.length > 0 || 
            inputDropdown.selected.length > 0 || 
            outputDropdown.selected.length > 0){
            setNewNode({
                toolName: tool.name, 
                version: versionDropdown.selected,
                purpose: purposeDropdown.selected,
                input: inputDropdown.selected.map(item => item.value),
                output: outputDropdown.selected.map(item => item.value)
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        versionDropdown.selected, 
        purposeDropdown.selected, 
        inputDropdown.selected, 
        outputDropdown.selected
    ]);

    const getSuggestion = async (e) => {
        const res = await axios.get(`/api/data/tool/suggestions`, {params: {query: e.query}});
        setSuggestions(res.data);
    }

    const addNode = async (e) => {
        e.preventDefault();
        try{
            const res = await axios.get('/api/view/pipeline-builder/node', {
                params: {
                    type: newNode.type,
                    id: newNode.id,
                    toolName: newNode.toolName,
                    version: newNode.version ? newNode.version : null,
                    purpose: newNode.purpose
                }
            });
            console.log(res.data);
            let addedNode = res.data.node;
            let nodes = [...graphData.data.nodes];
            let links = [...graphData.data.links];
            links.push({source: newEdgeSource, target: addedNode.id});
            let pipelineNodes = [...pipeline.main.nodes];
            pipelineNodes.push({
                ...addedNode, 
                input: newNode.input, 
                output: newNode.output
            });
            setPipeline({
                ...pipeline, 
                main: { ...pipeline.main, nodes: pipelineNodes},
                changeGraph: true
            });
            setGraphData({
                ...graphData, 
                data: {
                    nodes: nodes.concat(addedNode).map(node => ({
                        ...node, 
                        info: {
                            version: node.version, 
                            purpose: node.purpose
                        }
                    })), 
                    links: links
                },
                tools: graphData.tools.concat(res.data.tool)
            });
        }catch(err){
            console.log(err);
            setMessage(genericError);
            setShowMessage(Math.random());
        }finally{
            setNewEdgeSource(null);
            setNewNode(null);
            setTool('');
        } 
    }

    const resetForm = (e) => {
        e.preventDefault();
        setNewEdgeSource(null);
        setNewNode(null);
        setTool('');
    }

    const disableAddNode = () => {
        return(
            !newNode || 
            !newNode.purpose || 
            newNode.input.length === 0 || 
            newNode.output.length === 0
        );
    }

    const getSourceNodeOptions = () => {
        // Exclude 'Genomics Data' node (origin) from the source node options by default
        let options = graphData.data.nodes
            .filter(item => item.type !== 'origin')
            .map(node => ({value: node.id, label: `${node.name} - ${getNodeIdLabel(node.id)}`}));
            
        return options;
    }

    return(
        <StyledBuilderForm>
            <CustomMessages trigger={showMessage} message={message} />
            <div className='formRow'>
                <span className='label'>Source Node<RequiredField />: </span>
                <CustomDropdown 
                    className='input dropdown'
                    value={newEdgeSource}
                    options={getSourceNodeOptions()}  
                    onChange={(e) => {setNewEdgeSource(e.value)}} 
                    placeholder={'Select...'}
                    filter={true}
                />
            </div>
            <div className='formRow'>
                <span className='label'>Tool Name<RequiredField />: </span>
                <CustomAutoComplete 
                    className='input'
                    value={tool}
                    suggestions={suggestions}
                    completeMethod={getSuggestion}
                    onChange={(e) => {setTool(e.value)}}
                    placeholder='Search for existing tools.'
                    forceSelection={true}
                />
            </div>
            {
                tool && typeof tool === 'object' && tool.name.length > 0 &&
                <React.Fragment>
                    <div className='formRow'>
                        <span className='label'>Version: </span>
                        <CustomDropdown 
                            className='input dropdown'
                            value={versionDropdown.selected}
                            options={versionDropdown.options} 
                            onChange={(e) => {
                                setVersionDropdown({...versionDropdown, selected: e.value});
                            }} 
                            placeholder='Select or enter tool version'
                            filter={true}
                            editable
                        />
                    </div>
                    <div className='formRow'>
                        <span className='label'>Purpose<RequiredField />: </span>
                        <CustomDropdown 
                            className='input dropdown'
                            value={purposeDropdown.selected}
                            options={purposeDropdown.options} 
                            onChange={(e) => {
                                setPurposeDropdown({...purposeDropdown, selected: e.value});
                            }} 
                            placeholder='Select or enter tool usage purpose'
                            filter={true}
                            editable
                        />
                    </div>
                    <div className='formRow'>
                        <span className='label'>Input<RequiredField />: </span>
                        <CustomCreatableMultiSelect 
                            className='input dropdown'
                            options={inputDropdown.options}
                            value={inputDropdown.selected}
                            onChange={(newValue) => {
                                setInputDropdown({...inputDropdown, selected: newValue});
                            }} 
                            placeholder='Select or enter input data type(s)'
                        />
                    </div>
                    <div className='formRow'>
                        <span className='label'>Output<RequiredField />: </span>
                        <CustomCreatableMultiSelect 
                            className='input dropdown'
                            options={outputDropdown.options}
                            value={outputDropdown.selected}
                            onChange={(newValue) => {
                                setOutputDropdown({...outputDropdown, selected: newValue});
                            }} 
                            placeholder='Select or enter output data type(s)'
                        />
                    </div>
                </React.Fragment>
            }
            <div className='buttonGroup'>
                <CustomButton 
                    label='Reset Node' 
                    onClick={resetForm} 
                    className='p-button-sm p-button-secondary left'
                />
                <CustomButton 
                    label='Add Node' 
                    onClick={addNode} 
                    className='p-button-sm p-button-primary'
                    disabled={disableAddNode() || !newEdgeSource}
                />
            </div>
        </StyledBuilderForm>
    );
}

export default AddNode;