import React from 'react';
import { SubContainer } from '../TutorialStyles';
import YoutubeEmbed from './YoutubeEmbed';

const Run = () => {
    return(
        <SubContainer>
            <h1>Run Pipelines and Analyses</h1>
            <h3>CoBE enables researchers and analysts to run selected pipelines and tools.</h3>
            <div className='section'>
                In order to use this feature, you need:
                <ol>
                    <li>
                        Access to a designated HPC (H4H) to run pipelines to process data that contains PHI.
                    </li>
                    <li>
                        Account at <a href="https://codeocean.com/product/" rel='noopener noreferrer' target='_blank'>CodeOcean</a> to easily execute analysis with your data in a computational container (capsule).
                    </li>
                </ol>
            </div>
			<h3>How to run a node or pipeline on Code Ocean</h3>
            <div className='section video'>
                <YoutubeEmbed embedId='CpQ12i2t_kk' />
            </div>
            <h3>How to gain access to H4H</h3>
            <div className='section'>
                <ol>
                    <li>
                        Register an account and login at <a href="https://ppms.us/uhn/login/?pf=3" rel='noopener noreferrer' target='_blank'>https://ppms.us/uhn/login/?pf=3</a> with your UHN credentials.
                    </li>
                    <li>
                        Go to <a href="https://ppms.us/uhn/req/?pf=3&project=true" rel='noopener noreferrer' target='_blank'>https://ppms.us/uhn/req/?pf=3&project=true</a> to open an “HPC Services Request”.
                    </li>
                    <li>
                        Fill out the fields.
                    </li>
                    <li>
                        Download the request document from the documents menu at the top and have it signed by your PI.
                    </li>
                    <li>
                        Return the signed document to <b>zhibin.lu@uhnresearch.ca</b>. 
                    </li>
                </ol>
            </div>
			<h3>The following video describes how to run a pipeline on H4H with your data</h3>
            <div className='section video'>
                <YoutubeEmbed embedId='ysBuM-lzZnc' />
            </div>
            <h3>The following video describes how to run a pipeline on H4H with your data</h3>
            <div className='section video'>
                <YoutubeEmbed embedId='ysBuM-lzZnc' />
            </div>
            {/* <h3>The following video describes how to run a downstream analysis on CodeOcean with your data</h3>
            <div className='section video'>
                <YoutubeEmbed embedId='5mlHvu5N69I' />
            </div> */}
        </SubContainer>
    );
}

export default Run;