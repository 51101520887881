import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomTable from '../../../../UtilComponents/CustomTable';
import RecipientsDialog from './RecipientsDialog';
import PreviewDialog from './PreviewDialog';
import { StyledLinkButton } from '../ProfileStyle';
import { useKeycloak } from "@react-keycloak/web";

const NewsletterHistoryTab = () => {
    const { keycloak } = useKeycloak();
    const [newsletters, setNewsletters] = useState([]);
    const [recipients, setRecipients] = useState([]);
    const [selectedNewsletter, setSelectedNewsletter] = useState(undefined);
    const [dialogs, setDialogs] = useState({
        showRecipients: false,
        showPreview: false
    });

    useEffect(() => {
        const getData = async () => {
            const res = await axios.get('/api/admin/newsletter/history', { params: { email: keycloak.idTokenParsed.email } });
            setNewsletters(res.data);
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const historyColumns = [
        {
            Header: 'Subject',
            accessor: 'subject'
        },
        {
            Header: 'Recipients',
            accessor: '',
            Cell: (item) => (
                <StyledLinkButton 
                    className='recipients-btn' 
                    onClick={() => {
                        setRecipients(item.cell.row.original.recipients);
                        setDialogs({...dialogs, showRecipients: true});
                    }}
                >
                    {item.cell.row.original.recipients.length} recipients
                </StyledLinkButton>
            )
        },
        {
            Header: 'Date',
            accessor: 'date',
            Cell: (item) => (
                <span>{item.cell.row.original.date.split('T')[0]}</span>
            )
        },
        {
            Header: 'View',
            Cell: (item) => (
                <StyledLinkButton 
                    className='recipients-btn' 
                    onClick={() => {
                        setSelectedNewsletter(item.cell.row.original);
                        setDialogs({...dialogs, showPreview: true});
                    }}>
                    View
                </StyledLinkButton>
            )
        }
    ];

    return(
        <React.Fragment>
            <CustomTable
                columns={historyColumns}
                data={newsletters}
                datatype=''
                showSearch={true}
                pageSize={20}
            />
            {
                dialogs.showRecipients &&
                <RecipientsDialog 
                    onHide={() => {
                        setDialogs({...dialogs, showRecipients: false});
                        setRecipients([]);
                    }}
                    show={dialogs.showRecipients}
                    recipients={recipients}
                />
            }
            {
                dialogs.showPreview &&
                <PreviewDialog
                    onHide={() => {
                        setDialogs({...dialogs, showPreview: false});
                        setSelectedNewsletter(undefined);
                    }}
                    show={dialogs.showPreview}
                    newsletter={selectedNewsletter}
                />
            }
        </React.Fragment>
    );
}

export default NewsletterHistoryTab;