import React from "react";
import styled from "styled-components";
import { StyledTable } from "../../../../styles/StyledTable";
import { StyledLink } from "../../../../styles/utils";
import ChipGroup from "../../../UtilComponents/ChipGroup";
import LikeButton from "../../../UtilComponents/LikeButton";
import { useKeycloak } from "@react-keycloak/web";
import ShareButton from "../../../UtilComponents/ShareButton";

const StyledList = styled.div`
  .list-title {
    font-weight: bold;
  }
  li {
    .tool-usage-container {
      display: flex;
      align-items: center;
      .analysis-title {
        margin-right: 10px;
      }
    }
  }
`;

const PipelineTable = (props) => {
  const { pipelines, tools, search, tab } = props;
  const { keycloak } = useKeycloak();
  console.log(pipelines);
  return (
    <StyledTable>
      <thead>
        <tr>
          <th className="content">Pipeline</th>
          <th className="content">Data Type</th>
          <th className="content center">Popularity Score</th>
          <th className="content">Tool Usage</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {pipelines.map((pipeline, i) => (
          <tr key={i}>
            <td className="content">
              <StyledLink
                to={{
                  pathname: `/pipeline/${pipeline._id}`,
                  state: {
                    pipelineSteps: pipeline.main.nodes,
                    purposes: pipeline.purposes,
                    tools: tools.map((tool) => tool.name),
                    search: search,
                    tab: tab,
                  },
                }}
              >
                {pipeline.name}
              </StyledLink>
              <ChipGroup
                executableLinks={pipeline.main.executableLinks}
                core={pipeline.core ? pipeline.link : null}
                disabled={false}
                executableType="pipeline"
                executableInfo={{
                  name: `${pipeline.name}(${pipeline.datatype.join(",")})`,
                  id: pipeline._id,
                  type: "pipeline",
                  isService: pipeline.core,
                }}
              />
            </td>
            <td className="content">{pipeline.datatype.join(", ")}</td>
            <td className="content center">{pipeline.score}</td>
            <td>
              {pipeline.main.nodes.length > 0 && (
                <StyledList>
                  <div className="list-title">Pipeline Step(s)</div>
                  <ul>
                    {pipeline.main.nodes.map((item, i) => (
                      <li key={i}>
                        {item.name} - {item.purpose}
                      </li>
                    ))}
                    {pipeline.subBranches.map((branch) =>
                      branch.nodes.map((node, i) => (
                        <li key={i}>
                          {node.name} - {node.purpose}
                        </li>
                      ))
                    )}
                  </ul>
                </StyledList>
              )}
              {pipeline.purposes.map((item, i) => (
                <StyledList key={i}>
                  <div className="list-title">
                    Downstream Analysis: {item.text}
                  </div>
                  {
                    <ul>
                      {item.nodes.map((node, i) => (
                        <li key={i}>
                          <div className="tool-usage-container">
                            <span className="analysis-title">
                              {node.name} - {node.purpose}
                            </span>
                            <ChipGroup
                              executableLinks={node.executableLinks}
                              disabled={false}
                              executableType="tool usage sample"
                              executableInfo={{
                                name: node.name,
                                id: node.toolId,
                                type: "tool",
                              }}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  }
                </StyledList>
              ))}
            </td>
            <td>
              <LikeButton
                id={pipeline._id}
                type="pipeline"
                user={
                  keycloak.authenticated
                    ? { email: keycloak.idTokenParsed.email }
                    : undefined
                }
              />
            </td>
            <td>
              <ShareButton
                id={pipeline._id}
                type="pipeline"
                name={pipeline.name}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default PipelineTable;
