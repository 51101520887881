import React from 'react';
import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';

import DialogContent from './DialogContent';
import PDFExport from './PDFExport';
import DownloadPipeline from "../../UtilComponents/DownloadPipeline";
import { useKeycloak } from "@react-keycloak/web";

const StyledHeader = styled.div`
  .p-button-sm {
    font-size: 12px;
    margin-left: 5px;
    border-radius: 5px;
    padding: 8px 15px;
    top : 8px;
    height: 30px;
`;

const PipelineDialog = (props) => {
    const {visible, onHide, data, selectedPipeline} = props;
    const { keycloak } = useKeycloak();
    const customHeader = (
        <StyledHeader>
            <PDFExport data={data} selectedPipeline={selectedPipeline} disabled={keycloak.authenticated ? false : true}/>
            <DownloadPipeline
                selectedPipeline= {data.pipelines.filter(p => p.name === selectedPipeline)[0]}
                tooltip='Download the pipeline markup'
                disabled={keycloak.authenticated ? false : true}
            />
        </StyledHeader>
    );

    return(
        <Dialog
            visible={visible}
            header={customHeader}
            onHide={onHide}
            breakpoints={{'960px': '75vw'}}
            style={{width: '50vw'}}
        >
            {
                data.pipelines.length > 0 && selectedPipeline !== 'None' &&
                <DialogContent data={data} selectedPipeline={selectedPipeline} />
            }
        </Dialog>
    );
}

export default PipelineDialog;
