import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Editor } from 'primereact/editor';
import styled from 'styled-components';
import CustomInputText from '../../../../UtilComponents/CustomInputText';
import CustomButton from '../../../../UtilComponents/CustomButton';
import RecipientsDialog from './RecipientsDialog';
import PreviewDialog from './PreviewDialog';
import { StyledLinkButton } from '../ProfileStyle';
import { useKeycloak } from "@react-keycloak/web";

const StyledEditor = styled.div`
    .editor-line {
        min-width: 400px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .label {
            font-size: 12px;
            font-weight: bold;
            margin-right: 10px;
        }
    }
`;

const SendNewsletterTab = (props) => {
    const { setMessage, setShowMessage } = props;
    const { keycloak } = useKeycloak();
    const [newsletter, setNewsletter] = useState({
        subject: '',
        recipients: []
    });
    const [text, setText] = useState('');
    const [dialogs, setDialogs] = useState({
        showRecipients: false,
        showPreview: false
    });

    useEffect(() => {
        const getData = async () => {
            const res = await axios.get('/api/users', { params: { email: keycloak.idTokenParsed.email } });
            setNewsletter({...newsletter, recipients: res.data.sort((a, b) => a.email.localeCompare(b.email))});
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeRecipient = (id) => {
        setNewsletter({
            ...newsletter, 
            recipients: newsletter.recipients.filter(item => String(item._id) !== String(id))
        });
    }

    const sendNewsletter = async (e) => {
        e.preventDefault();
        const res = await axios.post(
            '/api/admin/newsletter/send', 
            {
                newsletter: {
                    ...newsletter,
                    content: text
                }
            }, 
            { params: { email: keycloak.idTokenParsed.email } }
        );
        setMessage({
            severity: res.status === 200 ? 'success' : 'error',
            summary: res.data.message
        });
        setShowMessage(Math.random());
        setDialogs({...dialogs, showPreview: false});
    }

    return(
        <React.Fragment>
            <StyledEditor>
                <div className='editor-line'>
                    <span className='label'>Subject: </span>
                    <CustomInputText
                        value={newsletter.subject}
                        type='text'
                        onChange={(e) => setNewsletter({...newsletter, subject: e.target.value})}
                    />
                </div>
                <div className='editor-line'>
                    <span className='label'>Recipients: </span>
                    <StyledLinkButton onClick={() => {setDialogs({...dialogs, showRecipients: true})}}>
                        {newsletter.recipients.length} recipients
                    </StyledLinkButton>
                </div>
                <Editor 
                    style={{height:'320px', marginBottom:'15px'}} 
                    value={text} 
                    onTextChange={(e) => setText(e.htmlValue)}
                />
                <CustomButton
                    className='p-button-sm'
                    label='Preview'
                    onClick={() => {setDialogs({...dialogs, showPreview: true})}}
                    disabled={
                        newsletter.subject.length === 0 ||
                        newsletter.recipients.length === 0 ||
                        !text ||
                        text.length === 0
                    }
                />
            </StyledEditor>
            <RecipientsDialog 
                onHide={() => {setDialogs({...dialogs, showRecipients: false})}}
                show={dialogs.showRecipients}
                recipients={newsletter.recipients}
                removeRecipient={removeRecipient}
            />
            <PreviewDialog
                onHide={() => {setDialogs({...dialogs, showPreview: false})}}
                show={dialogs.showPreview}
                newsletter={{...newsletter, content: text}}
                sendNewsletter={sendNewsletter}
            />
        </React.Fragment>
    );
}

export default SendNewsletterTab;