import {Dialog} from "primereact/dialog";
import {LinkedinShareButton, TwitterShareButton} from "react-share";
import {AiFillLinkedin, AiFillTwitterSquare} from "react-icons/ai";
import React, {useState} from "react";
import CustomButton from "./CustomButton";

const ShareButton = (props) => {
    const {type, name, id} = props;
    const [shareDialog, setShareDialog] =  useState({show: false, type: type, name: name, id:id});
    const [copied, setCopied] = useState(false);

    const hideShareDialog = () => {
        setCopied(false);
        setShareDialog({show: false, type: type, name: name, id: id});
    }

    const shareDialogFooter = (
        <div>
            <CustomButton
                className='p-button-info'
                icon="pi pi-copy"
                label={copied? 'Link copied':'Copy Link'}
                onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(
                        "tool" ?
                        `www.pmcobe.ca/tools/${shareDialog.id}`:
                        `www.pmcobe.ca/pipeline/${shareDialog.id}`);
                    hideShareDialog();
                }}
            />
            <CustomButton
                className='p-button-secondary'
                label='Cancel'
                onClick={(e) => {
                    e.preventDefault();
                    hideShareDialog();
                }}
            />
        </div>
    );

    return(
        <span>
            <CustomButton
                className="p-button-info p-button-rounded p-button-text"
                icon='pi pi-share-alt'
                tooltip={`Share this ${type}`}
                onClick={(e) => {
                    e.preventDefault();
                    setShareDialog({show: true, type: type, name: name, id:id});
                }}
            />
            <Dialog
                visible={shareDialog.show}
                header={`Share ${shareDialog.name} on:`}
                footer={shareDialogFooter}
                onHide={hideShareDialog}
                focusOnShow={false}
            >
                <LinkedinShareButton
                    url= {shareDialog.type === "tool" ? `www.pmcobe.ca/tools/${shareDialog.id}`:
                        `www.pmcobe.ca/pipeline/${shareDialog.id}`}
                    title={`Check out ${shareDialog.name} ${shareDialog.type} on CoBE!`}
                >
                    <AiFillLinkedin style={{ opacity: '0.5', marginLeft: '10px'}} size={24} />
                </LinkedinShareButton>
                <TwitterShareButton
                    url= {shareDialog.type === "tool" ? `www.pmcobe.ca/tools/${shareDialog.id}`:
                        `www.pmcobe.ca/pipeline/${shareDialog.id}`}
                    title={`Check out ${shareDialog.name} ${shareDialog.type} on CoBE!`}>
                    <AiFillTwitterSquare style={{ opacity: '0.5', marginLeft: '10px'}} size={24} />
                </TwitterShareButton>
            </Dialog>
        </span>
    );
}

export default ShareButton;
