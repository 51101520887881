import {StyledTable} from "../../../../styles/StyledTable";
import styled from "styled-components";
import React from "react";
import {StyledLink} from "../../../../styles/utils";

const StyledContent = styled.div`
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
`;

const PipelineTable = (props) => {
    const { pipelines } = props;
    return(
        <StyledTable>
            <thead>
            <tr>
                <th className='content'>Pipeline</th>
                <th className='content'>Data Type</th>
                <th className='content'>Downstream Analysis</th>
            </tr>
            </thead>
            <tbody>
            {
                pipelines.map((pipeline, i) => (
                    <tr key={i} className='content'>
                        <td className='content'>
                            <StyledContent>
                                <StyledLink to={{pathname: `/pipeline/${pipeline._id}`,}}
                                            target='_blank'
                                >
                                    {pipeline.name}
                                </StyledLink>
                            </StyledContent>
                        </td>
                        <td className='content'>
                            <StyledContent>{pipeline.datatype.join(', ')}</StyledContent>
                        </td>
                        <td className='content'>
                            { pipeline.purposes.map((item, i) => (<StyledContent key={i}>{item.text}</StyledContent>))}
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </StyledTable>
    );
}

const ToolTable = (props) => {
    const { tools } = props;
    return(
        <StyledTable>
            <thead style={{fontSize:'10px'}}>
            <tr >
                <th className='content'>Tool</th>
                <th className='content'>Purposes</th>
            </tr>
            </thead>
            <tbody>
            {
                tools.map((tool, i) => (
                    <tr key={i}>
                        <td>
                            <StyledContent>
                                <StyledLink to={{pathname: `tools/${tool._id}`}} target='_blank'>{tool.name}</StyledLink>
                            </StyledContent>
                        </td>
                        <td className='content'>
                            {
                                tool.purposes.map((purpose, i) => (
                                    <StyledContent key={i}>
                                        <span>{purpose.text}</span>
                                    </StyledContent>
                                ))
                            }
                        </td>
                    </tr>
                ))
            }
            </tbody>
        </StyledTable>
    );
}

export {
  ToolTable,
  PipelineTable
};
