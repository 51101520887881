import React, { useRef, useState } from 'react';
import { TieredMenu } from 'primereact/tieredmenu';
import { PipelineRequestForm, BugReportForm} from "./RequestForm";

export default function RequestMenu() {
  const menu = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const items = [
    {
      label: 'New Pipeline Request',
      command: () => {
        setSelectedOption('pipeline');
      }
    },
    {
      separator: true
    },
    {
      label: 'Bug Report',
      command: () => {
        setSelectedOption('bug');
      }
    }
  ];

  return (
    <div style={{'cursor': 'pointer'}}>
      <TieredMenu model={items} popup ref={menu} breakpoint="767px"/>
      <a id="bugReport" label="Request/Report" onClick={(e) => menu.current.toggle(e)}>
        Request / Report
      </a>

      {selectedOption === 'pipeline' && <PipelineRequestForm visible={true} onHide={() => setSelectedOption(null)}/>}
      {selectedOption === 'bug' && <BugReportForm visible={true} onHide={() => setSelectedOption(null)}/>}
    </div>
  )
};
