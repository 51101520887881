import React, { useState, useEffect,  useRef } from 'react';
import Layout from '../../UtilComponents/Layout';
import { Toast } from 'primereact/toast';
import { useLocation } from 'react-router-dom';

import { StyledTutorial, Navigation } from './TutorialStyles';
import Overview from './SubComponents/Overview';
import UserInterfaceGuide from './SubComponents/UserInterfaceGuide';
import Search from './SubComponents/Search';
import Run from './SubComponents/Run';
import PipelineExamples from './SubComponents/PipelineExamples';
import PipelineBuilderGuide from './SubComponents/PipelineBuilderGuide';

const Tutorial = () => {
    const search = useLocation().search;
    const section = new URLSearchParams(search).get("section");
    const [display, setDisplay] = useState(section ? section : 'overview');

    const toast = useRef(null);

    const handleToastClose = () => {
        if (window.gtag) { //Sending data to GA
          window.gtag('event', 'close', {
            'event_category': 'Toast Notifications',
            'event_label': '2024 Update Toast Tutorial Page'
          });
        }
    };

    useEffect(() => {
        if (toast.current) {
          toast.current.show({
            severity: 'info', 
            summary: 'Incoming Update Announcement', 
            detail:
			<span>
				{'Exciting news, CoBE users! The redesign is coming soon and we want your input. Click '}
					<a style={{textDecoration: 'none', color: 'blue' }} href='https://forms.microsoft.com/Pages/ResponsePage.aspx?id=Bc4N2T0GT0Gu_sKS_xjYsMydkalOU75Kux5RzowFBltUN1BDQ0xPSzlGV1ZHTzdPRURIRjVGVU05US4u' target='_blank'>
						{'here'} 
					</a>
				{' to take a quick survey and be a part of shaping our future enhancements!'}
			</span>,
            closable: true,
            life: 10000000,
          });
        }
    }, []);


    return(
        <Layout>
            <Toast ref={toast} style={{ position: 'static', width: '93%', marginTop: '15px' }}onHide={() => handleToastClose()} />
            <StyledTutorial>
                <Navigation>
                    <ul>
                        <li className={display === 'overview' ? 'selected' : undefined}>
                            <button
                                type='button' 
                                onClick={() => setDisplay('overview')}
                            >
                                Overview
                            </button>
                        </li>
                        <li className={display === 'web-interface' ? 'selected' : undefined}>
                            <button
                                type='button' 
                                onClick={() => setDisplay('web-interface')}
                            >
                                Web Interface
                            </button>
                        </li>
                        <li className={display === 'search' ? 'selected' : undefined}>
                            <button 
                                type='button' 
                                onClick={() => setDisplay('search')}
                            >
                                Search for pipelines and tools
                            </button>
                        </li>    
                        <li className={display === 'run' ? 'selected' : undefined}>
                            <button 
                                type='button' 
                                onClick={() => setDisplay('run')}
                            >
                                Run pipelines and analyses
                            </button>
                        </li>
                        <li className={display === 'examples' ? 'selected' : undefined}>
                            <button 
                                type='button' 
                                onClick={() => setDisplay('examples')}
                            >
                                Pipeline examples
                            </button>
                        </li>
                        <li className={display === 'builder' ? 'selected' : undefined}>
                            <button 
                                type='button' 
                                onClick={() => setDisplay('builder')}
                            >
                                Pipeline Builder
                            </button>
                        </li>
                    </ul>
                </Navigation>
                {display === 'overview' && <Overview /> }
                {display === 'web-interface' && <UserInterfaceGuide /> }
                {display === 'search' && <Search /> }
                {display === 'run' && <Run /> }
                {display === 'examples' && <PipelineExamples /> }
                {display === 'builder' && <PipelineBuilderGuide />}
            </StyledTutorial>
        </Layout>
    );
}

export default Tutorial;