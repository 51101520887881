import React from 'react';
import styled from 'styled-components';
import HyperLink from './HyperLink';

const StyledToolInfoView = styled.table`
    td {
        padding-bottom: 5px;
    }
`

const ToolInfoView = (props) => {
    const { toolInfo } = props;
    return(
        <StyledToolInfoView>
            <tbody>
                {
                    toolInfo.keywords.length > 0 ? 
                    <tr>
                        <td>Keywords: </td>
                        <td>{toolInfo.keywords.join(', ')}</td>
                    </tr>
                    :
                    <tr></tr>
                }
                {
                    <tr>
                        <td>Purpose: </td>
                        <td>{toolInfo.purpose ? toolInfo.purpose : 'Unavailable'}</td>
                    </tr>
                }
                {
                    <tr>
                        <td>Input: </td>
                        <td>{toolInfo.input.length > 0 ? toolInfo.input.join(', ') : 'Unavailable'}</td>
                    </tr>
                }
                {
                    
                    <tr>
                        <td>Output: </td>
                        <td>{toolInfo.output.length > 0 ? toolInfo.output.join(', ') : 'Unavailable'}</td>
                    </tr>
                }
                {
                    <tr>
                        <td>Score: </td>
                        <td>{toolInfo.score ? toolInfo.score : 0}</td>
                    </tr>
                }
                { 
                    <tr>
                        <td>Link: </td>
                        <td>
                            {
                                toolInfo.link ? <HyperLink link={toolInfo.link} text={toolInfo.link} /> : 'Unavailable'
                            }
                        </td>
                    </tr>
                }
                {
                    
                    <tr>
                        <td>DOI: </td>
                        <td>
                            {
                                toolInfo.doi ? <HyperLink link={toolInfo.doi} text={toolInfo.doi} /> : 'Unavailable'
                            }
                        </td>
                    </tr>
                }
            </tbody>
        </StyledToolInfoView>
    );
}

export default ToolInfoView;