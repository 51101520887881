import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomTable from '../../../UtilComponents/CustomTable';
import LikeButton from "../../../UtilComponents/LikeButton";
import HyperLink from '../../../UtilComponents/HyperLink';
import Loading from '../../../UtilComponents/Loading';
import { StyledTabContent } from './ProfileStyle';

const FavoritesTab = (props) => {
    const { user } = props;
    const [userLikedPipelines, setUserLikedPipelines] = useState([]);
    const [userLikedTools, setUserLikedTools] = useState([]);
    const [likesReady, setLikesReady] = useState(false);

    useEffect(() => {
        const initializeLikes = async () => {
            const res = await axios.get('/api/data/like/user-likes', { params: { email: user.email }});
            setUserLikedTools(res.data.likes.tools);
            setUserLikedPipelines(res.data.likes.pipelines);
            setLikesReady(true);
        }
        initializeLikes();
    }, [user.email]);

    const likedPipelineColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: (item) => (
                <div className='name'>
                    <HyperLink link={`/pipeline/${item.cell.row.original._id}`} text={item.value}/>
                </div>
            )
        },
        {
            Header: 'Data Type',
            accessor: 'datatype',
            Cell: (item) => (
                <span>{item.cell.row.original.datatype.join(', ')}</span>
            )
        },
        {
            Header: 'Manage',
            accessor: '',
            Cell: (item) => (
                <LikeButton
                    id = {item.cell.row.original._id}
                    type = 'pipeline'
                    user = {user}
                    disabled={false}
                />
            )
        }
    ];

    const likedToolColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: (item) => (
                <div className='name'>
                    <HyperLink link={`/tools/${item.cell.row.original._id}`} text={item.value}/>
                </div>
            )
        },
        {
            Header: 'Manage',
            accessor: '',
            Cell: (item) => (
                <LikeButton
                    id = {item.cell.row.original._id}
                    type = 'tool'
                    user = {user}
                    disabled={false}
                />
            )
        },
    ];

    return(
        <React.Fragment>
            {
                likesReady ?
                <StyledTabContent>
                    <div className='table-container'>
                        <div className='table left'>
                            <div className='sub-title'>Favourite Pipelines of { user.email === 'admin@cobe.ca' ? 'all Users' : 'the User'}</div>
                            {
                                userLikedPipelines.length > 0 ?
                                    <CustomTable
                                        columns={likedPipelineColumns}
                                        data={userLikedPipelines}
                                        datatype='pipeline(s)'
                                        showSearch={true}
                                    />
                                    :
                                    <div className='sub-title'>No pipelines have been Liked by this user.</div>
                            }
                        </div>
                        <div className='table'>
                            <div className='sub-title'>Favourite Tools of { user.email === 'admin@cobe.ca' ? 'all Users' : 'the User'}</div>
                            {
                                userLikedTools.length > 0 ?
                                    <CustomTable
                                        columns={likedToolColumns}
                                        data={userLikedTools}
                                        datatype='tool(s)'
                                        showSearch={true}
                                    />
                                    :
                                    <div className='sub-title'>No tools have been Liked by this user.</div>
                            }
                        </div>
                    </div>
                </StyledTabContent>
                :
                <Loading top='100px'/>
            }
        </React.Fragment>
    );
}

export default FavoritesTab;
