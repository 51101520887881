import React, {useMemo} from 'react';
import { PDFDownloadLink, Page, Text, Link, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import styled from 'styled-components';

import colors from '../../../styles/colors';
import { getNodes } from './helper';
import fontRegular from '../../../styles/fonts/Roboto-Regular.ttf';
import fontBold from '../../../styles/fonts/Roboto-Bold.ttf';
import { toolType } from '../../../utils/constants';
import CustomButton from '../../UtilComponents/CustomButton';
import ReactTooltip from 'react-tooltip';
import { getChipColor } from '../../../utils/helper';

Font.register({
    family: 'Roboto', 
    fonts: [
        {src: fontRegular},
        {src: fontBold}
    ]
});

// Create styles
const LinkText = styled.span`
    i {
        font-size: 12px;
    }
`;

const downloadLinkStyle = {
    fontSize: '12px',
    fontWeight: 'normal',
    textDecoration: 'none',
    padding: '8px 15px',
    color: '#ffffff',
    backgroundColor: '#2196f3',
    borderRadius: '5px'
}

const styles = StyleSheet.create({
    page: {
        marginTop: '10px',
        marginLeft: '30pt',
        fontFamily: 'Roboto',
        fontSize: '9pt', 
        color: colors.gray_text
    },
    header: {
        fontSize: '12pt',
        fontWeight: 'bold',
        marginTop: '10pt',
        marginBottom: '10pt',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    content: {
        marginLeft: '30pt'
    },
    section: {
        marginBottom: '10pt'
    },
    sectionTitle: {
        fontWeight: 'bold'
    },
    link: {
        color: colors.hyperlink, 
        textDecoration: 'none'
    },
    badgeGroup: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '10px'
    },
    badge: {
        backgroundColor: colors.hyperlink,
        borderRadius: 3,
        marginLeft: '5px',
        marginRight: '5px'
    },
    badgeLink: {
        textDecoration: 'none',
        color: '#ffffff',
        paddingTop: '1px',
        paddingBottom: '1px',
        paddingLeft: '3px',
        paddingRight: '3px',
        margin: '2px'
    },
    reportSection: {
        marginBottom: '20pt'
    },
    reportSectionTitle: {
        marginBottom: '10pt', 
        fontSize: '12pt',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    reportList: {
        marginLeft: '10pt'
    },
    node: {
        marginBottom: '10pt'
    },
    nodeTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    nodeTitleNumber: {
        fontWeight: 'bold',
        marginRight: '15pt'
    },
    nodeTitleText: {
        fontWeight: 'bold'
    },
    nodeContent: {
        marginLeft: '20pt'
    },
    nodeContentLine: {
        marginBottom: '5pt'
    }
});

/**
 * Renders and exports PDF version of a pipeline report.
 * @param {*} props 
 * @returns 
 */
const PDFExport = (props) => {

    const { data, selectedPipeline, disabled } = props;
    const pipeline = data.pipelines.find(pipeline => pipeline.name === selectedPipeline);

    const getReportOutline = () => {
        // get nodes that make up the selected pipeline
        const { pipelineNodes, forks } = getNodes(pipeline, data.graph);

        return(
            <View>
                <View style={styles.reportSection}>
                    <Text style={styles.reportSectionTitle}>CORE: {selectedPipeline}</Text> 
                    <View style={styles.reportList}>
                    {
                        pipelineNodes.map((node, i) => (
                            <View key={i}>{getFormattedNode(node, i)}</View>
                        ))
                    }
                    </View>
                </View>
                {
                    forks.map((fork, i) => (
                        <View style={styles.reportSection} key={i}>
                            <View style={styles.reportSectionTitle}>
                                <Text>FORK {i + 1}: {fork.title}</Text> 
                                {
                                    fork.executableLinks && fork.executableLinks.length > 0 &&
                                    <View style={styles.badgeGroup}>
                                        {
                                            fork.executableLinks.map(linkObj => (
                                                <View style={{...styles.badge, backgroundColor: getChipColor(linkObj.linkType)}}>
                                                    <Link style={styles.badgeLink} src={linkObj.executableLink}>
                                                        {`Link to pipeline executable for this fork on ${linkObj.linkType}`}
                                                    </Link>
                                                </View>
                                            ))
                                        }
                                    </View>
                                }
                            </View>
                            <View style={styles.reportList}>
                            {
                                fork.nodes.map((node, k) => (
                                    <View key={k}>{getFormattedNode(node, k)}</View>
                                ))
                            }
                            </View>
                        </View>
                    ))
                }
            </View>
        );
    };

    const getFormattedNode = (node, i) => {
        if(toolType.includes(node.type)){
            return(ToolNode(node, i));
        }else{
            return(FileNode(node, i));
        }
    };

    const FileNode = (node, i) => (
        <View style={styles.node}>
            <View style={styles.nodeTitleContainer}>
                <View style={styles.nodeTitleNumber}>
                    <Text>{i+1}</Text>
                </View>
                <View>
                    <Text>
                        [Data] <Text style={styles.nodeTitleText}>{node.name}</Text>
                    </Text>
                </View>
            </View>
        </View>
    );

    const ToolNode = (node, i) => (
        <View style={styles.node}>
            <View style={styles.nodeTitleContainer}>
                <View style={styles.nodeTitleNumber}>
                    <Text>{i+1}</Text> 
                </View>
                <View>
                    <Text>
                        [Tool] <Text style={styles.nodeTitleText}>{node.name}{node.info.version ? ` (${node.info.version})` : ''}</Text>
                    </Text>
                </View>
                {
                    node.info.executableLinks.length > 0 &&
                    <View style={styles.badgeGroup}>
                        {
                            node.info.executableLinks.map((linkObj, i) => (
                                <View key={i} style={{...styles.badge, backgroundColor: getChipColor(linkObj.linkType)}}>
                                    <Link style={styles.badgeLink} src={linkObj.executableLink}>
                                        {`Run Tool on ${linkObj.linkType}`}
                                    </Link>
                                </View>
                            ))
                        }
                    </View>
                }
            </View>
            <View style={styles.nodeContent}>
                <View style={styles.nodeContentLine}>
                    <Text>
                        {
                            node.info.keywords ?
                            <Text>
                                Keywords: {node.info.keywords.map((k, i) => [i > 0 && ', ', <Text key={i}>{k}</Text>])}
                            </Text>
                            :
                            ''
                        }
                    </Text>
                </View>
                <View style={styles.nodeContentLine}>
                    <Text>
                        {
                            node.info.purpose ?
                            <Text>{ `Purpose: ${node.info.purpose}`}</Text>
                            :
                            ''
                        }
                    </Text>
                </View>
                <View style={styles.nodeContentLine}>
                    <Text>
                        {
                            node.info.input.length > 0 ?
                            <Text>
                                Input: {node.info.input.map((k, i) => [i > 0 && ', ', <Text key={i}>{k}</Text>])}
                            </Text>
                            :
                            ''
                        }
                    </Text>
                </View>
                <View style={styles.nodeContentLine}>
                    <Text>
                        {
                            node.info.output.length > 0 ?
                            <Text>
                                Output: {node.info.output.map((k, i) => [i > 0 && ', ', <Text key={i}>{k}</Text>])}
                            </Text>
                            :
                            ''
                        }
                    </Text>
                </View>
                <View style={styles.nodeContentLine}>
                    <Text>Score: {node.info.score ? node.info.score : 0}</Text>
                </View>
                <View style={styles.nodeContentLine}>
                    <Text>
                    {
                        node.info.link ? 
                        <Text>Link: <Link style={styles.link} src={node.info.link}>{node.info.link}</Link></Text> 
                        : 
                        ''
                    }
                    </Text>
                </View>
                <View style={styles.nodeContentLine}>
                    <Text>
                    {
                        node.info.doi ? 
                        <Text>DOI: <Link style={styles.link} src={node.info.doi}>{node.info.doi}</Link></Text> 
                        : 
                        ''
                    }
                    </Text>
                </View>
            </View>
        </View>
    );

    const PDFContent = () => {
        return(
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.header}>
                        <Text>{selectedPipeline}</Text>
                        {
                            pipeline.main.executableLinks.length > 0 &&
                            <View style={styles.badgeGroup}>
                                {
                                    pipeline.main.executableLinks.map((linkObj, i) => (
                                        <View key={i} style={{...styles.badge, backgroundColor: getChipColor(linkObj.linkType)}}>
                                            <Link style={styles.badgeLink} src={linkObj.executableLink}>
                                                {`Run Pipeline on ${linkObj.linkType}`}
                                            </Link>
                                        </View>
                                    ))
                                }
                            </View>
                        }
                    </View>
                    <View style={styles.content}>
                        <View style={styles.section}>
                            <Text>
                                <Text style={styles.sectionTitle}>Version: </Text> {pipeline.version ? pipeline.version : 'Not Available'}
                            </Text>
                        </View>
                        <View style={styles.section}>
                            <Text>
                                <Text style={styles.sectionTitle}>Users: </Text> 
                                {
                                    pipeline.user.length > 0 ? 
                                    pipeline.user.map((u, i) => 
                                        [   
                                            i > 0 && ', ', u.link.length > 0 ? 
                                            <Link key={i} style={styles.link} src={u.link}>{u.name}</Link> : u.name
                                        ]
                                    )
                                    : 
                                    'Not Available'
                                }
                            </Text>
                        </View>
                        <View style={styles.section}>
                            <Text>
                                <Text style={styles.sectionTitle}>Link: </Text> 
                                {
                                    pipeline.link ? 
                                    <Link style={styles.link} src={pipeline.link}>{pipeline.link}</Link>
                                    : 
                                    'Not Available'
                                }
                            </Text>
                        </View>
                        {
                            getReportOutline()
                        }
                    </View>
                </Page>
            </Document>
        );
    }

    const updateDownloadCount = async (pipelineId) => {
        console.log(pipelineId);
        const res = await fetch(`/api/data/pipeline/update/count/${pipelineId}`);
        if(!res.ok){
            const json = await res.json();
            console.log(json.error);
        }
    }

    return(
        // Wrap the component rendering function in useMemo to render it only once to prevent re-rednering of PDF documents multiple times.
        useMemo(() => (
            disabled ?
            <React.Fragment>
                <ReactTooltip />
                <span
                    data-tip={disabled ? 'Sign in or register to access this feature.' : ''}
                    data-place={'top'}
                    data-effect='solid'
                >
                    <CustomButton 
                        className="p-button-sm"
                        icon="pi pi-download"
                        label={`Export`} 
                        disabled={true}
                    />
                </span>
            </React.Fragment>
            :
            <span onClick={() => {updateDownloadCount(pipeline._id)}}>
                <PDFDownloadLink 
                    document={<PDFContent />} 
                    fileName={`${selectedPipeline}.pdf`} 
                    style={downloadLinkStyle}
                >
                {
                    ({ loading }) => (
                        loading ? 
                        'Preparing document...' 
                        : 
                        <LinkText><i className="pi pi-download"></i> Export</LinkText>
                    )
                }
                </PDFDownloadLink>
            </span>
            // eslint-disable-next-line react-hooks/exhaustive-deps
        ), [])
    );
}

export default PDFExport;