import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { PipelineBuilderContext } from '../../../../hooks/Contexts';
import PipelineInfoForm from '../Forms/PipelineInfoForm';
import CustomButton from '../../../UtilComponents/CustomButton';
import CustomMessages from '../../../UtilComponents/CustomMessages';
import { signedOut } from '../../../../utils/messages';
import AddNewToolDialog from './AddNewToolDialog';
import { useKeycloak } from "@react-keycloak/web";

export const StyledPipelineInfo = styled.div`
    margin-bottom: 30px;
    font-size: 12px;
    .tables {
        display: flex;
        .left {
            margin-right: 20px;
        }
    }
    table {
        margin-bottom: 10px;
        td {
            padding-bottom: 10px;
        }
        .value {
            font-weight: bold;
        }
    }
    .button-group {
        .left {
            margin-right: 10px;
        }
    }
`;

const StyledHeader = styled.div`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
`;

const PipelineInfo = () => {
    const { pipeline, setPipeline, graphData, setGraphData, isEdit } = useContext(PipelineBuilderContext);
    const { keycloak } = useKeycloak();
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState(null);
    const [addToolVisible, setAddToolVisible] = useState(false);

    const initializePipeline = async (pipeline) => {
        const res = await axios.post('/api/view/pipeline-builder/initialize-tree', {
            pipeline: {
                ...pipeline,
                datatype: pipeline.datatype.map(item => item.value)
            },
            email: keycloak.idTokenParsed.email
        });
        if(res.data){
            setPipeline({
                ...res.data.pipeline, 
                changeGraph: true
            });
            setGraphData({ 
                ...graphData, 
                data: res.data.graph, 
                ready: true
            });
        }else{
            setMessage(signedOut);
            setShowMessage(Math.random());
        }
    }

    const resetPipeline = async (e) => {
        e.preventDefault();
        await axios.post('/api/view/pipeline-builder/reset-view', { email: keycloak.idTokenParsed.email });
        setPipeline({
            name: '',
            datatype: [],
            version: '',
            link: '',
            user: {name: '', link: ''},
            main: {
                executableLinks: [],
                nodes: []
            },
            subBranches: [],
            purposes: []
        });
        setGraphData({
            data: { nodes: [], links: [] },
            tools : [],
            filtered: false,
            ready: false
        });
    }

    const render = () => {
        const form = (
            <React.Fragment>
                <CustomMessages trigger={showMessage} message={message} />
                <StyledHeader>Pipeline information: </StyledHeader>
                <PipelineInfoForm pipeline={pipeline} setPipeline={setPipeline} initializePipeline={initializePipeline} isEdit={isEdit} />
            </React.Fragment>
        );
        if(!graphData.ready){
            return(form);
        }
        if(isEdit && graphData.ready){
            return(
                <React.Fragment>
                    {
                        form
                    }
                    <div className='button-group'>
                        <CustomButton 
                            label='Add New Tool' 
                            onClick={(e) => {setAddToolVisible(true)}} 
                            className='left p-button-sm p-button-primary'
                            tooltip='Add a new tool, if it does not yet exist in our database.'
                        />
                    </div>
                    <AddNewToolDialog 
                        visible={addToolVisible} 
                        setVisible={setAddToolVisible}
                    />
                </React.Fragment>
            );
        }
        return(
            <React.Fragment>
                <PipelineInfoForm 
                    pipeline={pipeline} 
                    setPipeline={setPipeline} 
                    initializePipeline={initializePipeline} 
                    initialized={true} 
                />
                <div className='button-group'>
                    <CustomButton 
                        label='Reset Pipeline' 
                        onClick={resetPipeline} 
                        className='left p-button-sm p-button-secondary'
                    />
                    <CustomButton 
                        label='Add New Tool' 
                        onClick={(e) => {setAddToolVisible(true)}} 
                        className='left p-button-sm p-button-primary'
                        tooltip='Add a new tool, if it does not yet exist in our database.'
                    />
                </div>
                <AddNewToolDialog 
                    visible={addToolVisible} 
                    setVisible={setAddToolVisible}
                />
            </React.Fragment>
        );
    }

    return(
        <StyledPipelineInfo>
            {
                render()
            }
        </StyledPipelineInfo>
    );
}

export default PipelineInfo;