import React, { useState, useContext } from 'react';
import { PipelineBuilderContext } from '../../../../hooks/Contexts';

import CustomButton from '../../../UtilComponents/CustomButton';
import CustomDropdown from '../../../UtilComponents/CustomDropdown';
import CustomMessages from '../../../UtilComponents/CustomMessages';
import { StyledBuilderForm } from '../BuildPipelineStyles';
import { genericError, removeAnalysisSuccess } from '../../../../utils/messages';

const RemoveAnalysis = () => {
    const { pipeline, setPipeline, setGraphData } = useContext(PipelineBuilderContext);
    const [purposeToRemove, setPurposeToRemove] = useState(null);
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState(null);

    const removeAnalysis = (e) => {
        e.preventDefault();
        try{
            let selected = pipeline.purposes.find(purpose => purpose.text === purposeToRemove);
            let otherPurposes = pipeline.purposes.filter(purpose => purpose.text !== purposeToRemove);
            let nodeIds = selected.nodes.map(node => node.id);
            let shared = [];
            for(const other of otherPurposes){
                let otherIds = other.nodes.map(node => node.id);
                // eslint-disable-next-line no-loop-func
                shared = shared.concat(otherIds.filter(id => nodeIds.includes(id)));
            }
            nodeIds = nodeIds.filter(id => !shared.includes(id));
            let pipelineNodes = pipeline.main.nodes.concat(selected.nodes.filter(node => nodeIds.includes(node.id)));
            let purposes = pipeline.purposes.filter(item => item.text !== selected.text);
            setPipeline(prev => ({
                ...prev,
                main: {
                    ...prev.main,
                    nodes: pipelineNodes
                },
                purposes: purposes,
                changeGraph: true
            }));
            setGraphData(prev => ({
                ...prev,
                analysisAdded: false
            }));
            setMessage(removeAnalysisSuccess);
        }catch(err){
            console.log(err);
            setMessage(genericError);
        }finally{
            setShowMessage(Math.random());
            setPurposeToRemove(null);
        }
    } 

    return(
        <StyledBuilderForm>
            <CustomMessages trigger={showMessage} message={message} />
            <div className='formRow'>
                <span className='label'>Analysis Purpose: </span>
                <CustomDropdown 
                    className='input'
                    value={purposeToRemove}
                    options={pipeline.purposes.map(item => ({
                        label: `${item.text} [${item.nodes.map(node => node.name).join(', ')}]`, 
                        value: item.text
                    }))}
                    onChange={(e) => {setPurposeToRemove(e.value)}}
                    placeholder='Select am analysis to remove...'
                    filter={true}
                />
            </div>
            <div className='buttonGroup'>
                <CustomButton 
                    className='p-button-sm p-button-danger'
                    label='Remove Downstream Analysis' 
                    onClick={removeAnalysis} 
                    disabled={!purposeToRemove || purposeToRemove.length === 0}
                />
            </div>
        </StyledBuilderForm>
    );
}

export default RemoveAnalysis;