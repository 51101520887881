import styled from 'styled-components';
import colors from "../../../styles/colors";


export const StyledLink = styled.a`
  color: ${colors.hyperlink};
  text-decoration: none;
  target: "_blank";
  overflow-wrap: break-word;
  font-size: 12px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  jusitfy-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const Container = styled.div`
  width: 90%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Section = styled.div`
  width: 50%;
  min-width: 400px;
  display: flex;
  margin-righ: 40px;
  flex-direction: column;
  padding: 0px 20px;
  overflow-y: auto;
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const Description = styled.div`
  padding: 10px;
  font-size: 14px;
  margin-bottom: 10px;
  color: ${colors.gray_text};
  border: 1px solid ${colors.border_gray};
  border-radius: 5px;
  .tool-purpose {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .purpose-text {
      margin-right: 20px;
    }
  }
`;

export const Title = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: ${colors.gray_text};
  align-items: baseline;
  margin-top: 10px;
  padding-bottom: 15px;
  line-height: 25px;
`;

export const Text = styled.span`
  font-size: 12px;
  color: ${colors.gray_text};
`;
