import React from 'react';
import { SubContainer } from '../TutorialStyles';
import YoutubeEmbed from './YoutubeEmbed';

const UserInterfaceGuide = () => {
    return(
        <SubContainer>
            <h1>Introduction to CoBE's Web Interface</h1>
            <h3>Learn what you can do with CoBE's web interface.</h3>
            <div className='section video'>
                <YoutubeEmbed embedId='wE9l-t0JUgk' />
            </div>
        </SubContainer>
    );
}

export default UserInterfaceGuide;