import React from 'react';
import PropTypes from 'prop-types';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import styled from 'styled-components';

const Main = styled.main`
    display: flex;
    // max-width: 1500px;
    min-height: 100vh;
    margin-bottom: 50px;
    flex-direction: column;
    align-items: center;
`;

/**
 * wrapper for every page
 */
const Layout = (props) => {
    const {children} = props;

    return(
        <React.Fragment>
            <Navigation />
            <Main>
                {children}
            </Main>
            <Footer />
        </React.Fragment>
    );
};

Layout.propTypes = {
    /**
     * Layout's children (components on the page)
     */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};
  
Layout.defaultProps = {
    page: '',
    children: null,
};

export default Layout;