import styled from 'styled-components';
import colors from '../../../styles/colors';

const StyledTutorial = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    margin-top: 20px;
`;

const Navigation = styled.div`
    width: 20%;
    min-width: 250px;
    ul {
        list-style-type: none;
        li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 70px;
            border-left: 3px solid ${colors.border_gray};
            font-weight: bold;
            padding-left: 0px;
        }
        li.selected {
            border-left: 3px solid ${colors.hyperlink};
            button {
                color: ${colors.gray_text};
            }   
        }
    }
    button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        text-align: left;
        sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: ${colors.light_gray};
    }
`;

const SubContainer = styled.div`
    width: 80%;
    margin-left: 20px;
    margin-right: 20px;
    .section {
        width: 100%;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.5;
        .embed-img {
            width: 100%;
        }
        .img-small {
            width: 60%;
            min-width: 500px;
        }
        a{
            text-decoration: none;
            color: ${colors.hyperlink};
        }
    }
    .video {
        max-width: 800px;
        max-height: 450px;
    }
`;

export {
    StyledTutorial,
    Navigation,
    SubContainer
}