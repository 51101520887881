import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import styled from 'styled-components';
import colors from '../../styles/colors';

const StyledDropdown = styled(Dropdown)`
    .pi {
        font-size: 0.7rem;
    }
    .p-dropdown-items .p-dropdown-item {;
        color: ${colors.gray_text};
    }
    .p-dropdown-label, .p-dropdown-item {
        font-family: 'Noto Sans', sans-serif;
        font-size: 12px;
    }
`;

const CustomDropdown = (props) => {
    const {
        className, 
        value, 
        options, 
        onChange, 
        placeholder, 
        filter, 
        disabled, 
        editable,
        optionLabel,
        valueTemplate,
        tooltip
    } = props;

    return(
        <StyledDropdown 
            className={className}
            value={value}
            options={options} 
            onChange={onChange} 
            placeholder={placeholder}
            filter={filter}
            disabled={disabled}
            editable={editable}
            optionLabel={optionLabel}
            valueTemplate={valueTemplate}
            tooltip={tooltip}
            resetFilterOnHide={true}
        />
    );
}

export default CustomDropdown;