import React, {useState, useEffect} from 'react';
import axios from "axios";
import CustomTable from "../../../../UtilComponents/CustomTable";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import styled from "styled-components";
import CustomButton from "../../../../UtilComponents/CustomButton";
import {Dialog} from "primereact/dialog";
import {StyledLink} from "../../../../../styles/utils";
import Loading from "../../../../UtilComponents/Loading";
import AchievementBadges from "./AchievementBadges";

const StyledDialog = styled.div`
  width: 350px;
  overflow-wrap: break-word;
  padding: 10px;
`;

const ContributionTable = (props) => {
    const { userPipelines, userTools, user} = props;
    const [ userExpert, setUserExpert] = useState({tools: [], pipelines: [], ready: false});
    const [ userLikes, setUserLikes] = useState({tools: 0, pipelines: 0, ready: false});
    const [ infoDialog, setInfoDialog] = useState({show: false, activity: '', list: []});

    const hideInfoDialog = () => {
        setInfoDialog({...infoDialog, show: false});
    }

    const Columns = [
        {
            Header: '',
            accessor: 'type',
            Cell : (item) => (<div style={{fontWeight: "bold"}}>{item.value}</div>)
        },
        {
            Header: 'Submitted',
            accessor: 'submissions',
            Cell: (item) => {
                return(
                    item.value.length?
                        <CustomButton
                            className="p-button-info p-button-square p-button-text"
                            tooltip='View'
                            label={item.value.length}
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    setInfoDialog({show: true, type: item.cell.row.values.type ,activity: 'submission', list: item.value});
                                }}
                        /> : <div style={{paddingLeft: '20px'}}>0</div>
                )
            }
        },
        {
            Header: 'liked by',
            accessor: 'likes'
        },
        {
            Header: 'Areas of Expertise',
            accessor: 'expertise',
            Cell: (item) => {
                return (
                    item.value.length?
                        <CustomButton
                            className="p-button-info p-button-square p-button-text"
                            tooltip='View'
                            label = {item.value.length}
                            onClick={(e) => {
                                e.preventDefault();
                                setInfoDialog({show: true, type: item.cell.row.values.type, activity: 'experience', list: item.value});
                            }}
                        /> : <div style={{paddingLeft: '20px'}}>0</div>
                )
            }
        }
    ];

    useEffect(() => {
        const initialize = async () => {
            const res = await axios.get('/api/data/expert/user-stats', { params: { email: user.email }});
            // const likes = await axios.get('/api/data/like/user-likes', {params: {email: user.email}});
            const likes = await axios.get('/api/data/like/user-submission-likes',{params: {email: user.email}});
            if (res) setUserExpert({tools: res.data.tools || [], pipelines: res.data.pipelines|| [], ready: true});
            if (likes) setUserLikes({tools: likes.data.tools|| 0, pipelines: likes.data.pipelines|| 0, ready: true});
        }
        initialize();
    }, [user.email, infoDialog.show]);

    const statsData = [
        {
            "type": "Pipeline(s)",
            "submissions": userPipelines,
            "likes": userLikes.pipelines,
            "expertise": userExpert.pipelines
        },
        {
            "type": "Tool(s)",
            "submissions": userTools,
            "likes": userLikes.tools,
            "expertise": userExpert.tools
        }
    ]

    return(
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div className='table-container'>
                <div className='table left'>
                    <div className='sub-title'>User's contributions</div>
                    {
                        userLikes.ready && userExpert.ready?
                            <CustomTable
                                columns={Columns}
                                data={statsData}
                                datatype='pipeline(s)'
                                showSearch={false}
                                disablePagination = {true}
                            />
                            : <Loading/>
                    }
                </div>
                <Dialog
                    visible={infoDialog.show}
                    header={
                        infoDialog.activity === "submission"? `Submitted ${infoDialog.type}:` :
                            infoDialog.activity === "experience"? `Areas of Expertise - ${infoDialog.type}:` : ""
                    }
                    onHide={hideInfoDialog}
                >
                    {
                        infoDialog.list.map((item, index)=>
                            {
                                return(
                                    <StyledDialog key={index}>
                                        <StyledLink
                                            to={{pathname:`/${infoDialog.type.toLowerCase().includes("pipeline")? "pipeline":"tools"}/${item._id}`}}
                                            target="_blank"
                                        >
                                            {item.name}
                                        </StyledLink>
                                    </StyledDialog>
                                )
                            }
                        )
                    }
                </Dialog>
            </div>
            {
                userLikes.ready && userExpert.ready &&
                    <AchievementBadges userLikes={userLikes} userExpert={userExpert} userPipelines={userPipelines} userTools={userTools}/>
            }
        </div>

    );
}

export default ContributionTable;
