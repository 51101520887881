import React from 'react';
import styled from 'styled-components';
import colors from '../../styles/colors';

const StyledFooter = styled.div`
    position: relative;
    bottom: 0%;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
`

const FooterContent = styled.div`
    width: 90%;
    height: 100%;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid ${colors.light_gray};
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const FooterItem = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.light_gray};
    font-size: 10px;
    a {
        color: ${colors.light_gray};
        text-decoration: none;
    }
    .divider {
        margin: 0px 10px;
    }
    .logo-sm {
        img {
            width: 100%;
        }
        margin-right: 30px;
    }
`

const Footer = () => {
    return (
        <StyledFooter>
            <FooterContent>
                <FooterItem>
                    <span className='link'><a href='/'>Home</a></span>
                    <span className='divider'>|</span>
                    <span className='link'><a href='/tutorial'>Tutorial</a></span>
                    <span className='divider'>|</span>
                    <span className='link'><a href='/contact'>Contact</a></span>
                    <span className='divider'>|</span>
                    <span className='link'><a href='/news'>News</a></span>
                </FooterItem>
                <FooterItem>
                    <div className='logo-sm' style={{width: '80px'}}>
                        <a href='https://www.uhn.ca/' target='_blank' rel='noreferrer'>
                            <img alt='UHN' src='/images/logos/uhn-logo.png' />
                        </a>
                    </div>
                    <div className='logo-sm' style={{width: '110px'}}>
                        <a href='http://www.uhnresearch.ca/institutes/pm' target='_blank' rel='noreferrer'>
                            <img alt='Princess Margaret Cancer Centre' src='/images/logos/pm-logo.png' />
                        </a>
                    </div>
                    <span>COBE Initiative &#169; 2020-2022</span>
                </FooterItem>
            </FooterContent>
        </StyledFooter>
    );
}

export default Footer;
